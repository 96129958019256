var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","scrollable":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form"},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.headline))]),(_vm.item.po_number)?_c('span',{staticStyle:{"font-weight":"bold","font-size":"24px"}},[_vm._v("#"+_vm._s(_vm.item.po_number))]):_vm._e()])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"disabled":_vm.goodEntrance || (_vm.glassItems.length > 0),"is-required":true,"is-add-all":false,"is-inst":false,"is-vwd-glass":false},model:{value:(_vm.item.inventory_class_id),callback:function ($$v) {_vm.$set(_vm.item, "inventory_class_id", $$v)},expression:"item.inventory_class_id"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.goodEntrance,"label":"Supplier","items":_vm.suppliers,"rules":[function (v) { return !!v || 'Supplier is required'; }],"item-text":function (item) { return ((item.code) + " " + (item.name)); },"item-value":"id","clearable":"","single-select":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.code))]),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.code))]),_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.item.supplier_id),callback:function ($$v) {_vm.$set(_vm.item, "supplier_id", $$v)},expression:"item.supplier_id"}},[_c('template',{slot:"label"},[_vm._v(" Supplier"),_c('RedAsterisk')],1)],2)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.goodEntrance || !_vm.item.supplier_id,"items":_vm.supplierContacts,"auto-select-first":"","item-text":"name","item-value":"id","loading":_vm.supplierContactLoading},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","outlined":"","small":"","disabled":!_vm.item.supplier_id},on:{"click":function($event){return _vm.openContactForm()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]},proxy:true}]),model:{value:(_vm.item.supplier_contact_id),callback:function ($$v) {_vm.$set(_vm.item, "supplier_contact_id", $$v)},expression:"item.supplier_contact_id"}},[_c('template',{slot:"label"},[_vm._v(" Contact ")])],2)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.goodEntrance,"label":"Job","items":_vm.jobs,"item-text":function (item) { return ((item.code) + " " + (item.name)); },"item-value":"id","clearable":"","single-select":false,"loading":_vm.jobsLoading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976d2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')),_c('span',{staticStyle:{"color":"#1976d2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openJobForm()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]},proxy:true}]),model:{value:(_vm.item.job_id),callback:function ($$v) {_vm.$set(_vm.item, "job_id", $$v)},expression:"item.job_id"}})],1),(!_vm.isPatioDoorClass)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.colors,"item-text":"code","item-value":"id","disabled":_vm.isColorDisabled || _vm.goodEntrance,"clearable":true,"loading":_vm.loadingColors,"rules":[
                  function (v) { return !_vm.isColorRequired || !!v || 'Color is required'; } ]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,false,3024101884),model:{value:(_vm.item.color_id),callback:function ($$v) {_vm.$set(_vm.item, "color_id", $$v)},expression:"item.color_id"}},[_c('template',{slot:"label"},[_vm._v(" Color"),(_vm.isColorRequired)?_c('RedAsterisk'):_vm._e()],1)],2)],1):_vm._e()],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.goodEntrance,"items":_vm.locations,"auto-select-first":"","item-text":"name","item-value":"id","loading":_vm.loadingLocations,"rules":[function (v) { return !!v || 'Ship To is required'; }]},model:{value:(_vm.item.location_id),callback:function ($$v) {_vm.$set(_vm.item, "location_id", $$v)},expression:"item.location_id"}},[_c('template',{slot:"label"},[_vm._v(" Ship To"),_c('RedAsterisk')],1)],2)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"1"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.goodEntrance,"items":_vm.shippingOptions,"item-text":"name","item-value":"id"},model:{value:(_vm.item.shipping_option_id),callback:function ($$v) {_vm.$set(_vm.item, "shipping_option_id", $$v)},expression:"item.shipping_option_id"}},[_c('template',{slot:"label"},[_vm._v(" Ship Via ")])],2)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"1"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.goodEntrance,"items":_vm.fobOptions,"item-text":"name","item-value":"id"},model:{value:(_vm.item.fob_option_id),callback:function ($$v) {_vm.$set(_vm.item, "fob_option_id", $$v)},expression:"item.fob_option_id"}},[_c('template',{slot:"label"},[_vm._v(" Free on Load ")])],2)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2"}},[_c('v-menu',{ref:"menuRequiredBy",attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.goodEntrance,"label":"Required By Date","readonly":"","append-icon":"mdi-calendar"},on:{"change":function($event){return _vm.$refs.menuRequiredBy.save(_vm.item.required_by)}},model:{value:(_vm.item.required_by),callback:function ($$v) {_vm.$set(_vm.item, "required_by", $$v)},expression:"item.required_by"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuRequiredBy),callback:function ($$v) {_vm.dateMenuRequiredBy=$$v},expression:"dateMenuRequiredBy"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.item.required_by),callback:function ($$v) {_vm.$set(_vm.item, "required_by", $$v)},expression:"item.required_by"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.item.required_by = null}}},[_vm._v(" Clear ")])],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.goodEntrance,"items":_vm.currencies,"item-text":"name","item-value":"id","auto-select-first":""},model:{value:(_vm.item.currency_id),callback:function ($$v) {_vm.$set(_vm.item, "currency_id", $$v)},expression:"item.currency_id"}},[_c('template',{slot:"label"},[_vm._v(" Currency ")])],2)],1),(!_vm.isPatioDoorClass)?_c('v-col',{staticClass:"text-end pt-0"},[(!_vm.goodEntrance)?_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":!_vm.item.supplier_id ||
                  !_vm.item.inventory_class_id ||
                  (_vm.isColorRequired && !_vm.item.color_id)},on:{"click":_vm.openItem}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Item ")],1):_vm._e()],1):_vm._e()],1),(_vm.item && _vm.item.inventory_class_id === _vm.inventoryClassIdPatioDoor)?_c('inventory-po-glass-package-table-form',{ref:"inventoryPoGlassPackageTableForm",attrs:{"glass-items":_vm.glassItems,"po-items":_vm.poItems},on:{"update-glass-items":_vm.updateGlassItems,"edit-glass-item":_vm.editGlassItem}}):_vm._e(),_c('v-row',[_c('v-spacer'),(_vm.isPatioDoorClass)?_c('v-col',{staticClass:"text-end pt-3",attrs:{"cols":"auto"}},[(!_vm.goodEntrance)?_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":!_vm.item.supplier_id ||
                  !_vm.item.inventory_class_id ||
                  (_vm.isColorRequired && !_vm.item.color_id) ||
                  !_vm.glassItems || _vm.glassItems.length == 0},on:{"click":_vm.openItem}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Item ")],1):_vm._e()],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.poItems,"loading":_vm.loadingPoItems,"hide-default-footer":"","items-per-page":-1,"dense":"","item-key":"temp_id","show-expand":"","expanded":_vm.expandedItems,"item-class":_vm.poItemClass},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',[(
                            item.goodEntrances &&
                            item.goodEntrances.length > 0
                          )?_c('v-icon',{staticStyle:{"font-size":"22px !important"},on:{"click":function($event){return _vm.toggleExpand(item)}}},[_vm._v(" "+_vm._s(_vm.expandedItems.includes(item) ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")]):_vm._e()],1)])]}},{key:"item.status",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",attrs:{"small":"","color":_vm.poItemColorStatus(item)}},[_vm._v(" "+_vm._s(_vm.poItemStatus(item))+" ")])]}},{key:"item.width",fn:function(ref){
                          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.width ? _vm.convertToFraction(item.width) : '-'))])]}},{key:"item.height",fn:function(ref){
                          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.height ? _vm.convertToFraction(item.height) : '-'))])]}},{key:"item.part",fn:function(ref){
                          var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.item_code)+" - "+_vm._s(item.item_name)+" "+_vm._s(item.clone_index ? ("(" + (item.clone_index) + ")") : ''))])]}},{key:"item.fritted",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_fritted !== null && item.is_fritted === 1 ? 'Fritted' : 'Non-Fritted')+" ")]}},{key:"item.weight",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.weight)+" ")]}},{key:"item.quantity",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" "+_vm._s(item.uom_name)+" ")]}},{key:"item.price",fn:function(ref){
                          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol_short : '')+" "+_vm._s(parseFloat(item.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])]}},{key:"item.subtotal",fn:function(ref){
                          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol_short : '')+" "+_vm._s(parseFloat(item.price * item.quantity).toLocaleString( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, } )))])]}},{key:"item.tax",fn:function(ref){
                          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol_short : '')+" "+_vm._s(parseFloat(_vm.getItemTax(item)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])]}},{key:"item.total",fn:function(ref){
                          var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol_short : '')+" "+_vm._s(parseFloat(_vm.getItemTotal(item)).toLocaleString( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, } )))])]}},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [(_vm.isClone)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"#00897B","outlined":"","small":""},on:{"click":function($event){return _vm.clone(item)}}},[_vm._v(" Clone ")]):_vm._e(),(!_vm.goodEntrance)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":"","disabled":item.supply_to_paint_status_id &&
                        item.supply_to_paint_status_id !=
                          _vm.supplyToPaintStatusIdNew},on:{"click":function($event){return _vm.openEdit(item)}}},[_vm._v(" EDIT ")]):_vm._e(),(!_vm.goodEntrance)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":"","disabled":item.supply_to_paint_status_id &&
                        item.supply_to_paint_status_id !=
                          _vm.supplyToPaintStatusIdNew},on:{"click":function($event){return _vm.softDelete(item)}}},[_vm._v(" DELETE ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                          var headers = ref.headers;
                          var item = ref.item;
return [_c('td',{staticClass:"px-0 py-2",attrs:{"colspan":headers.length}},[(
                          item.goodEntrances &&
                          item.goodEntrances.length > 0 &&
                          item.goodEntrances
                        )?_c('v-data-table',{attrs:{"id":"sub-table","colspan":headers.length,"headers":_vm.subHeaders(item),"items":item.goodEntrances,"hide-default-footer":"","items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"item.item_full_name",fn:function(){return [_vm._v(" "+_vm._s(item.item_full_name)+" ")]},proxy:true},{key:"item.fritted",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_fritted !== null && item.is_fritted === 1 ? 'Fritted' : 'Non-Fritted')+" ")]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteReceivedItem(item)}}},[_vm._v(" Remove ")])]}}],null,true)}):_vm._e()],1)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th'),(_vm.goodEntrance)?_c('th',[_c('v-chip',{attrs:{"small":"","color":_vm.totalReceivedColorStatus()}},[_vm._v(" "+_vm._s(_vm.totalReceivedStatus())+" ")])],1):_vm._e(),_c('th'),(
                          _vm.item.inventory_class_id ===
                          _vm.inventoryClassIdPatioDoor
                        )?_vm._l((7),function(index){return _c('th',{key:index})}):_vm._e(),_c('th'),_c('th',[_c('span',{style:({
                            color: _vm.isDarkMode ? '' : '#000000',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          })},[_vm._v("Totals: ")])]),_c('th',[_c('span',{style:({
                            color: _vm.isDarkMode ? '' : '#000000',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                          })},[_vm._v(_vm._s(_vm.getTotalQuantityItems()))])]),(
                          _vm.item.inventory_class_id !==
                            _vm.inventoryClassIdPatioDoor &&
                          _vm.item.inventory_class_id !== _vm.inventoryClassIdPaint
                        )?_c('th',[_c('span',{style:({
                            color: _vm.isDarkMode ? '' : '#000000',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                          })},[_vm._v(_vm._s(_vm.getTotalLength()))])]):_vm._e(),(_vm.isWeightHeading)?_c('th',[_c('span',{style:({
                            color: _vm.isDarkMode ? '' : '#000000',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                          })},[_vm._v(_vm._s(_vm.getTotalWeight())+" kg")])]):_vm._e(),_c('th',{staticClass:"text-end pr-1"},[_c('span',{style:({
                            color: _vm.isDarkMode ? '' : '#000000',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                          })},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol_short : '')+" "+_vm._s(parseFloat(_vm.getTotalUnitPrice()).toLocaleString( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, } )))])]),_c('th',{staticClass:"text-end pr-1"},[_c('span',{style:({
                            color: _vm.isDarkMode ? '' : '#000000',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                          })},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol_short : '')+" "+_vm._s(parseFloat(_vm.getSubtotal()).toLocaleString( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, } )))])]),_c('th',{staticClass:"text-end pr-1"},[_c('span',{style:({
                            color: _vm.isDarkMode ? '' : '#000000',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                          })},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol_short : '')+" "+_vm._s(parseFloat(_vm.getTotalTax(false)).toLocaleString( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, } )))])]),_c('th',{staticClass:"text-end pr-1"},[_c('span',{style:({
                            color: _vm.isDarkMode ? '' : '#000000',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                          })},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol_short : '')+" "+_vm._s(parseFloat(_vm.getTotal(false)).toLocaleString( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, } )))])]),_c('th')],2)])],2)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[(_vm.goodEntrance)?_c('inventory-po-good-entrance-table-form',{ref:"inventoryPoGoodEntranceTableForm",attrs:{"po-items":_vm.poItems,"inventory-class-id":_vm.inventoryClassId,"is-repaint":_vm.isRepaint,"inventory-po-id":_vm.poId},on:{"goodsReceivedSubmitted":_vm.goodsReceivedSubmitted,"updateSubmitButtonState":_vm.updateSubmitButtonState}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('inventory-po-other-form',{ref:"inventoryPoOtherForm",attrs:{"disabled":_vm.goodEntrance,"is-tax":_vm.item.is_tax,"currency":_vm.selectedCurrency},on:{"success":_vm.success}})],1)],1),_c('v-row',[_c('v-col',[_c('inventory-po-discount-form',{ref:"inventoryPoDiscountForm",attrs:{"disabled":_vm.goodEntrance,"is-tax":_vm.item.is_tax,"currency":_vm.selectedCurrency,"sub-total":_vm.getSubtotal(),"other-sub-total":_vm.getOtherSubtotal()},on:{"success":_vm.success}})],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0"},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontSize: '18px',
                    })},[_vm._v("Notes")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-textarea',{attrs:{"outlined":"","rows":"3"},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1)],1)],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mr-10",attrs:{"cols":"auto"}},[(_vm.isMinimumSetupCharge)?_c('v-row',[_c('v-col',{staticClass:"pt-2"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"disabled":_vm.goodEntrance,"true-value":1,"false-value":0,"persistent-hint":"","hint":"This limitation is imposed by repaint supplier."},model:{value:(_vm.item.is_minimum_setup_charge_applicable),callback:function ($$v) {_vm.$set(_vm.item, "is_minimum_setup_charge_applicable", $$v)},expression:"item.is_minimum_setup_charge_applicable"}},[_c('template',{slot:"label"},[_c('span',{style:({
                          color: _vm.isDarkMode ? '' : '#000000',
                          fontSize: '16px',
                        })},[_vm._v("Minimum Setup Charge Applicable?")])])],2)],1)],1):_vm._e(),(
                  _vm.isMinimumSetupCharge &&
                  _vm.item.is_minimum_setup_charge_applicable == 1
                )?_c('v-row',[_c('v-col',{staticClass:"pt-2"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"disabled":_vm.goodEntrance,"true-value":1,"false-value":0,"persistent-hint":"","hint":"If paint isn't available, the minimum order charge will be applied."},model:{value:(_vm.item.is_paint_available),callback:function ($$v) {_vm.$set(_vm.item, "is_paint_available", $$v)},expression:"item.is_paint_available"}},[_c('template',{slot:"label"},[_c('span',{style:({
                          color: _vm.isDarkMode ? '' : '#000000',
                          fontSize: '16px',
                        })},[_vm._v("Supplier confirmed that Paint is available?")])])],2)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pt-2"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"disabled":_vm.goodEntrance,"true-value":1,"false-value":0,"persistent-hint":"","hint":"If checked, tax will be applied to the total."},model:{value:(_vm.item.is_tax),callback:function ($$v) {_vm.$set(_vm.item, "is_tax", $$v)},expression:"item.is_tax"}},[_c('template',{slot:"label"},[_c('span',{style:({
                          color: _vm.isDarkMode ? '' : '#000000',
                          fontSize: '16px',
                        })},[_vm._v("Is tax included?")])])],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.isMinimumSetupCharge)?_c('v-row',[_c('v-col',{staticClass:"text-end pr-0"},[_c('span',{style:(("font-weight: bold; color: " + (_vm.minSetupCharge != 0 && _vm.getAllSubtotal() < _vm.minSetupCharge
                        ? _vm.isDarkMode
                          ? ''
                          : '#000000'
                        : 'rgba(0,0,0,0.4)') + "; font-size: 16px;"))},[_vm._v("Min Setup Charge:")])]),_c('v-col',{staticClass:"text-end pl-0",staticStyle:{"min-width":"250px !important"}},[_c('span',{style:(("font-weight: bold; color: " + (_vm.minSetupCharge != 0 && _vm.getAllSubtotal() < _vm.minSetupCharge
                        ? _vm.isDarkMode
                          ? ''
                          : '#000000'
                        : 'rgba(0,0,0,0.4)') + "; font-size: 16px;"))},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol : '')+" "+_vm._s(parseFloat(_vm.minSetupCharge).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-end pr-0"},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    })},[_vm._v("Subtotal:")])]),_c('v-col',{staticClass:"text-end pl-0",staticStyle:{"min-width":"250px !important"}},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    })},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol : '')+" "+_vm._s(parseFloat(_vm.getAllSubtotal()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])])],1),(
                  _vm.minSetupCharge != 0 && _vm.getAllSubtotal() < _vm.minSetupCharge
                )?_c('v-row',[_c('v-col',{staticClass:"text-end pr-0 pb-0 ml-1",attrs:{"cols":"auto"}},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    })},[_vm._v("Chargeable Amount:")]),_c('br')]),_c('v-col',{staticClass:"text-end pl-0 pb-0",staticStyle:{"min-width":"250px !important"}},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    })},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol : '')+" "+_vm._s(parseFloat(_vm.getAllSubtotal(true)).toLocaleString( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 } )))])])],1):_vm._e(),(
                  _vm.minSetupCharge != 0 && _vm.getAllSubtotal() < _vm.minSetupCharge
                )?_c('v-row',[_c('v-col',{staticClass:"pl-4 pt-0"},[_c('span',{staticStyle:{"font-size":"12px","color":"rgba(0, 0, 0, 0.6)"}},[_vm._v("Will apply if Subtotal below Min Setup Charge")])])],1):_vm._e(),(_vm.getDiscountItemQty() > 0)?_c('v-row',[_c('v-col',{staticClass:"text-end pr-0"},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    })},[_vm._v("Discount:")])]),_c('v-col',{staticClass:"text-end pl-0",staticStyle:{"min-width":"250px !important"}},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    })},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol : '')+" -"+_vm._s(parseFloat(_vm.getDiscount()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])])],1):_vm._e(),(_vm.item.is_tax == 1)?_c('v-row',[_c('v-col',{staticClass:"text-end pr-0"},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    })},[_vm._v("Tax:")])]),_c('v-col',{staticClass:"text-end pl-0",staticStyle:{"min-width":"250px !important"}},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                    })},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol : '')+" "+_vm._s(parseFloat(_vm.getAllTotalTax()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-end pr-0"},[_c('span',{style:({
                      color: _vm.isDarkMode ? '' : '#000000',
                      fontWeight: 'bold',
                      fontSize: '20px',
                    })},[_vm._v("Total:")])]),_c('v-col',{staticClass:"text-end pl-0",staticStyle:{"min-width":"250px !important"}},[_c('span',{style:(("font-weight: bold; font-size: 20px; color: " + (_vm.isDarkMode ? '' : '#000000') + ";"))},[_vm._v(_vm._s(_vm.selectedCurrency ? _vm.selectedCurrency.symbol : '')+" "+_vm._s(parseFloat(_vm.getAllTotal()).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 ml-10",attrs:{"color":"normal"},on:{"click":_vm.closeDialog}},[_vm._v(" CLOSE ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.goodEntrance ? _vm.submitButtionIsDisabled : false},on:{"click":_vm.handleSubmit}},[_vm._v(" SUBMIT ")])],1)],1)],1)],1),_c('job-form',{ref:"jobForm",attrs:{"is-supervisor":false,"is-client":false,"is-work-order":false,"is-address":false,"is-job-name":false,"is-reference":true},on:{"success":_vm.jobSuccess}}),_c('inventory-po-item-form',{ref:"inventoryPOItemForm",attrs:{"is-tax":_vm.item.is_tax,"currency":_vm.selectedCurrency,"glassItems":_vm.glassItems},on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }