<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :full-screen="true"
    :submit-btn-text="submitBtnText()"
    :is-submit-btn="isSubmitBtn()"
    :persistent="true"
  >
    <template slot="header">
      <span class="mr-2"
        >Scheduled Delivery{{
          this.item && this.item.title ? `: ${this.item.title}` : ''
        }}</span
      >
      <v-chip
        small
        :color="this.item && this.item.status ? this.item.status.color : ''"
        text-color="white"
      >
        {{ this.item && this.item.status ? this.item.status.name : '' }}
      </v-chip>
    </template>
    <template slot="body">
      <v-row>
        <v-col class="pb-0">
          <v-row>
            <v-col cols="12">
              <v-card elevation="0" outlined>
                <v-data-table
                  :headers="headers"
                  :items="item.requests"
                  hide-default-footer
                  :items-per-page="-1"
                  fixed-header
                  dense
                >
                  <template
                    v-for="h in headers"
                    #[`header.${h.value}`]="{ header }"
                  >
                    <v-tooltip v-if="header.fullText" :key="h.value" bottom>
                      <template #activator="{ on }">
                        <span v-on="on">{{ header.text }}</span>
                      </template>
                      <span>{{ header.fullText }}</span>
                    </v-tooltip>
                    <span v-else :key="h.value">{{ h.text }}</span>
                  </template>
                  <template #[`item.class`]="{ item }">
                    <v-chip
                      small
                      :color="item && item.class ? item.class.color : ''"
                      text-color="white"
                    >
                      {{ item && item.class ? item.class.name : '' }}
                    </v-chip>
                  </template>
                  <template #[`item.truck_types`]="{ item }">
                    <v-row
                      v-if="item.truck_types && item.truck_types.length > 1"
                    >
                      <v-col class="pl-1">
                        <v-tooltip top color="black">
                          <template #activator="{ on }">
                            <span style="color: #1976d2" v-on="on"
                              >TRUCK TYPES</span
                            >
                          </template>
                          <v-row>
                            <v-col class="text-center pt-6 pb-6">
                              <v-row
                                v-for="truck_type in item.truck_types"
                                :key="truck_type.id"
                              >
                                <v-col cols="auto" class="pr-1 pb-1 pt-1">
                                  <v-chip
                                    class="mt-1 mb-1"
                                    small
                                    text-color="white"
                                    style="cursor: pointer"
                                    :color="
                                      truck_type ? truck_type.color : 'white'
                                    "
                                  >
                                    {{ truck_type ? truck_type.name : '' }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-chip
                      v-else
                      class="mt-1 mb-1"
                      small
                      text-color="white"
                      style="cursor: pointer"
                      :color="
                        item.truck_types[0]
                          ? item.truck_types[0].color
                          : 'white'
                      "
                    >
                      {{ item.truck_types[0] ? item.truck_types[0].name : '' }}
                    </v-chip>
                  </template>
                  <template #[`item.job.full_name`]="{ item }">
                    <span style="font-weight: bold">{{
                      item.job.code ? `${item.job.code}` : ''
                    }}</span>
                    -
                    {{
                      item.job.name
                        ? item.job.name
                        : item.project.name
                        ? item.project.name
                        : ''
                    }}
                    <span style="color: #1976d2">{{
                      item.job.reference ? `; ${item.job.reference}` : ''
                    }}</span>
                  </template>
                  <template #[`item.status.name`]="{ item }">
                    <v-chip
                      small
                      :color="item && item.status ? item.status.color : ''"
                      text-color="white"
                    >
                      {{ item && item.status ? item.status.name : '' }}
                    </v-chip>
                  </template>
                  <template #[`item.windows`]="{ item }">
                    {{
                      `${item.current_finished_goods_count}/${item.total_finished_goods_count}`
                    }}
                  </template>
                  <template #[`item.created_by_user`]="{ item }">
                    {{
                      item.created_by_user &&
                      item.created_by_user.first_name &&
                      item.created_by_user.last_name
                        ? `${item.created_by_user.first_name} ${item.created_by_user.last_name}`
                        : ''
                    }}
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-btn
                      v-if="item.bill_of_ladings.length > 0"
                      class="ml-1 mt-1 mb-1"
                      color="success"
                      outlined
                      small
                      @click="openBillOfLadingList(item)"
                    >
                      BOL
                    </v-btn>
                    <v-btn
                      v-if="
                        (isDeliveriesManager || isAdmin) &&
                        !truckRequestHasBillOfLadings(item)
                      "
                      class="ml-1 mt-1 mb-1 mr-1"
                      color="error"
                      outlined
                      small
                      @click="remove(item)"
                    >
                      REMOVE
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-spacer />
          <v-row>
            <v-col>
              <v-row align="center">
                <v-col class="pb-0">
                  <span :style="{ color: '#000000', fontSize: '18px' }"
                    >Notes</span
                  >
                </v-col>
                <v-col v-if="item.id" class="text-end">
                  <v-btn color="orange" outlined @click="openNoteForm">
                    SEE ALL NOTES
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-textarea
                    v-model="item.note"
                    :disabled="item.notes && item.notes.length > 0"
                    outlined
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <truck-schedule-bill-of-lading-list-form ref="billOfLadingList" />
      <note-form v-show="false" ref="noteForm" />
      <confirm-form ref="confirmForm" />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TruckScheduleBillOfLadingListForm from '@/components/forms/truckSchedules/TruckScheduleBillOfLadingListForm';
import NoteForm from '@/components/forms/notes/NoteForm';

export default {
  components: {
    TruckScheduleBillOfLadingListForm,
    NoteForm,
  },
  props: {},
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      date: null,
      requests: [],
      note: null,
    },
    roles: [],
    headers: [
      { text: 'Class', value: 'class', width: '3%', align: 'center' },
      { text: 'ID', value: 'id', width: '5%' },
      { text: 'Project', value: 'project.name', width: '10%' },
      { text: 'WO#', value: 'job.full_name', width: '15%' },
      {
        text: 'Truck Types',
        value: 'truck_types',
        width: '10%',
        align: 'center',
      },
      { text: 'Requested Delivery Date', value: 'date', width: '10%' },
      { text: 'Requested By', value: 'created_by_user', width: '5%' },
      { text: 'Status', value: 'status.name', width: '10%', align: 'center' },
      {
        fullText: "Shipped or Received Windows Assigned to This Delivery Request / Total Windows for This Job",
        text: 'Window Count / Total Window Count',
        value: 'windows',
        width: '10%',
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        width: '10%',
        sortable: false,
      },
    ],

    userRoleIdAdmin: 3,
    userRoleIdDeliveriesManager: 31,
    noteTypeIdTruckScheduleScheduledDeliveries: 5,
  }),
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([]),
    isAdmin() {
      return this.roles.includes(this.userRoleIdAdmin);
    },
    isDeliveriesManager() {
      return this.roles.includes(this.userRoleIdDeliveriesManager);
    },
  },
  watch: {},
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.item = JSON.parse(JSON.stringify(this.itemDto));
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async init() {},

    async open(id) {
      this.$bus.$emit('loading');
      this.$refs.dialogBox.open();
      if (id) {
        const res = await this.get(id);
        if (!res) {
          this.$refs.dialogBox.close();
          this.$bus.$emit('loading-stop');
          this.$bus.$emit('showError', 'Scheduled delivery not found');
          return;
        }
      } else {
        this.item = JSON.parse(JSON.stringify(this.itemDto));
      }
      this.$bus.$emit('loading-stop');
    },

    async get(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleSlot.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.$bus.$emit('showError', 'Unable to retrieve delivery requests');
        return false;
      }

      const firstIndex = 0;
      this.item = JSON.parse(JSON.stringify(res[firstIndex]));
      return true;
    },

    async submit() {
      this.$bus.$emit('loading');
      let res = false;

      if (this.item.id) {
        console.log('submit: ', this.item);
        res = await this.$api.truckScheduleSlot.update(this.item);
      } else {
        //there won't be a case where this.item.id is null because we can only open scheduled deliveries that are created already
        //   res = await this.$api.truckScheduleSlot.store(this.item);
      }
      if (!res) {
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Error submitting scheduled delivery');
        return false;
      }

      if (this.item.note && this.item.note.trim().length > 0) {
        const note = {
          text: this.item.note,
          record_id: res,
          type_id: this.noteTypeIdTruckScheduleScheduledDeliveries,
        };
        const res2 = await this.$api.note.store(note);
        if (!res2) {
          this.$bus.$emit(
            'showError',
            'Error adding note to scheduled delivery'
          );
          this.$bus.$emit('loading');
          return false;
        }
      }

      this.$bus.$emit('loading-stop');
      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return true;
    },

    async remove(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record?',
      });
      if (!confirm) return;
      //disassociate truck request from scheudled delivery and change the status back to 'NOT ASSIGNED'
      console.log('removed item: ', item);

      const itemIndex = this.item.requests.findIndex((x) => {
        return x.id === item.id;
      });

      if (itemIndex >= 0) {
        this.item.requests.splice(itemIndex, 1);
      }
    },

    openBillOfLadingList(item) {
      this.$refs.billOfLadingList.open(item);
    },

    openNoteForm() {
      this.$refs.noteForm.open(
        this.item.id,
        this.noteTypeIdTruckScheduleScheduledDeliveries
      );
    },

    submitBtnText() {
      if (this.item.id) {
        return 'Update';
      }
      return 'Submit';
    },

    isSubmitBtn() {
      return !!this.item.id;
    },

    truckRequestHasBillOfLadings(truckRequest) {
      return truckRequest.bill_of_ladings.length > 0;
    },
  },
};
</script>