import { render, staticRenderFns } from "./TruckScheduleRequestForm.vue?vue&type=template&id=5a4a4311&scoped=true&"
import script from "./TruckScheduleRequestForm.vue?vue&type=script&lang=js&"
export * from "./TruckScheduleRequestForm.vue?vue&type=script&lang=js&"
import style0 from "./TruckScheduleRequestForm.vue?vue&type=style&index=0&id=5a4a4311&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a4a4311",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VCardText,VChip,VCol,VDatePicker,VDivider,VList,VListItem,VListItemContent,VListItemSubtitle,VRow,VTextarea})
