var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openGlassPoItemForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Glass Item ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.glassItems,"hide-default-footer":"","items-per-page":-1,"dense":"","expanded":_vm.expandedItems},on:{"update:expanded":function($event){_vm.expandedItems=$event}},scopedSlots:_vm._u([{key:"item.glass_thickness",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.glass_thickness))])]}},{key:"item.glass_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.glass_type))])]}},{key:"item.g_code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.g_code))])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}},{key:"item.width",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.width ? _vm.uomSwitch ? _vm.convertToFraction(item.width) : item.width : '-'))])]}},{key:"item.height",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.height ? _vm.uomSwitch ? _vm.convertToFraction(item.height) : item.height : '-'))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.glass_id)?_c('v-chip',{attrs:{"small":"","color":"orange","text-color":"white"}},[_vm._v(" ACTIVE ")]):_c('v-chip',{attrs:{"small":"","color":"success","text-color":"white"}},[_vm._v(" READY FOR IMPORT ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.editGlassItem(item)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteGlassItem(item)}}},[_vm._v(" DELETE ")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(item.glass)?_c('td',{staticClass:"pl-0 pr-0",attrs:{"colspan":12}},[_c('v-container',{style:({
                  backgroundColor: _vm.isDarkMode
                    ? '#556B2F !important'
                    : '#E8F5E9 !important',
                }),attrs:{"fluid":""}},[_c('v-row',[(item.glass.part_number)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Part Number:")]),_c('span',[_vm._v(_vm._s(item.glass.part_number))])]):_vm._e(),(item.glass.awd_gas)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("AWD Gas:")]),_c('span',[_vm._v(_vm._s(item.glass.awd_gas)+" - "+_vm._s(_vm.getGlassDescription(item.glass.awd_gas)))])]):_vm._e(),(item.glass.glass_air_space_id)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Air Space:")]),_c('span',[_vm._v(_vm._s(_vm.getGlassAirSpaceName(item.glass.glass_air_space_id))+" - ")])]):_vm._e(),(item.glass.awd_spacer)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("AWD Spacer:")]),_c('span',[_vm._v(_vm._s(item.glass.awd_spacer)+" - "+_vm._s(_vm.getGlassDescription(item.glass.awd_spacer)))])]):_vm._e(),(item.glass.cen_heat_treatment)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Cen Heat Treatment:")]),_c('span',[_vm._v(_vm._s(item.glass.cen_heat_treatment)+" - "+_vm._s(_vm.getGlassDescription(item.glass.cen_heat_treatment)))])]):_vm._e(),(item.glass.center_glass)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Center Glass:")]),_c('span',[_vm._v(_vm._s(item.glass.center_glass)+" - "+_vm._s(_vm.getGlassDescription(item.glass.center_glass)))])]):_vm._e(),(item.glass.double_triple)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Double/Triple:")]),_c('span',[_vm._v(_vm._s(item.glass.double_triple)+" - "+_vm._s(_vm.getGlassDescription(item.glass.double_triple)))])]):_vm._e(),(item.glass.ext_heat_treatment)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Ext Heat Treatment:")]),_c('span',[_vm._v(_vm._s(item.glass.ext_heat_treatment)+" - "+_vm._s(_vm.getGlassDescription(item.glass.ext_heat_treatment)))])]):_vm._e(),(item.glass.exterior_glass)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Exterior Glass:")]),_c('span',[_vm._v(_vm._s(item.glass.exterior_glass)+" - "+_vm._s(_vm.getGlassDescription(item.glass.exterior_glass)))])]):_vm._e(),(item.glass.fritted)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Fritted:")]),_c('span',[_vm._v(_vm._s(item.glass.fritted)+" - "+_vm._s(_vm.getGlassDescription(item.glass.fritted)))])]):_vm._e(),(item.glass.int_heat_treatment)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Int Heat Treatment:")]),_c('span',[_vm._v(_vm._s(item.glass.int_heat_treatment)+" - "+_vm._s(_vm.getGlassDescription(item.glass.int_heat_treatment)))])]):_vm._e(),(item.glass.interior_glass)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Interior Glass:")]),_c('span',[_vm._v(_vm._s(item.glass.interior_glass)+" - "+_vm._s(_vm.getGlassDescription(item.glass.interior_glass)))])]):_vm._e(),(item.glass.le_surface)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("LE Surface:")]),_c('span',[_vm._v(_vm._s(item.glass.le_surface)+" - "+_vm._s(_vm.getGlassDescription(item.glass.le_surface)))])]):_vm._e(),(item.glass.middle_glass)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Middle Glass:")]),_c('span',[_vm._v(_vm._s(item.glass.middle_glass)+" - "+_vm._s(_vm.getGlassDescription(item.glass.middle_glass)))])]):_vm._e(),(item.glass.sgl_heat_treatment)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Sgl Heat Treatment:")]),_c('span',[_vm._v(_vm._s(item.glass.sgl_heat_treatment)+" - "+_vm._s(_vm.getGlassDescription(item.glass.sgl_heat_treatment)))])]):_vm._e(),(item.glass.single_glass)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Single Glass:")]),_c('span',[_vm._v(_vm._s(item.glass.single_glass)+" - "+_vm._s(_vm.getGlassDescription(item.glass.single_glass)))])]):_vm._e(),(item.glass.spandrel_paint)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("Spandrel Paint:")]),_c('span',[_vm._v(_vm._s(item.glass.spandrel_paint)+" - "+_vm._s(_vm.getGlassDescription(item.glass.spandrel_paint)))])]):_vm._e(),(item.glass.stc)?_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"auto"}},[_c('span',{style:({
                        color: _vm.isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                      })},[_vm._v("STC:")]),_c('span',[_vm._v(_vm._s(item.glass.stc)+" - "+_vm._s(_vm.getGlassDescription(item.glass.stc)))])]):_vm._e()],1)],1)],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('glass-p-o-item-form',{ref:"glassPoItemForm",on:{"success-po":_vm.glassPoItemSuccess}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }