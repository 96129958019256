<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :is-submit-btn="!isView"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
    :max-width="1000"
  >
    <template slot="header">
      <span
        >{{ item.id ? (isView ? '' : 'Update') : 'Create' }} Delivery Request
        {{ item.id ? `#${item.id}` : '' }}</span
      >
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <inventory-class-select-element
                v-model="item.inventory_class_id"
                :is-add-all="false"
                :is-required="!isView"
                :disabled="isView"
                @on-change="inventoryClassChange"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-autocomplete
                v-model="item.job_id"
                :items="jobs"
                :item-text="(item) => `${item.code} - ${item.name}`"
                item-value="id"
                clearable
                :no-data-text="
                  !item.inventory_class_id
                    ? 'Select an Inventory Class first'
                    : 'No data available'
                "
                :loading="loadingJobs"
                :disabled="(loadingJobs && jobs && jobs.length === 0) || isView"
                label="Job"
                :rules="[(v) => !!v || 'Job is required']"
                @change="updateInventoryClass"
              >
                <template slot="label">
                  Job<RedAsterisk v-if="!isView" />
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    v-if="item.sub_class"
                    class="mt-1 mb-1 mr-3"
                    small
                    text-color="white"
                    style="cursor: pointer"
                    :color="
                      item && item.sub_class ? item.sub_class.color : 'white'
                    "
                  >
                    {{ item && item.sub_class ? item.sub_class.name : '' }}
                  </v-chip>
                  <span>
                    <span style="font-weight: bold">{{
                      item.code ? `${item.code}` : ''
                    }}</span>
                    - {{ item.name ? item.name : '' }}
                    <span style="color: #1976d2">{{
                      item.reference ? `; ${item.reference}` : ''
                    }}</span>
                  </span>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #item="{ item }">
                  <v-chip
                    v-if="item.sub_class"
                    class="mt-1 mb-1 mr-3"
                    small
                    text-color="white"
                    style="cursor: pointer"
                    :color="
                      item && item.sub_class ? item.sub_class.color : 'white'
                    "
                  >
                    {{ item && item.sub_class ? item.sub_class.name : '' }}
                  </v-chip>
                  <span>
                    <span style="font-weight: bold">{{
                      item.code ? `${item.code}` : ''
                    }}</span>
                    - {{ item.name ? item.name : '' }}
                    <span style="color: #1976d2">{{
                      item.reference ? `; ${item.reference}` : ''
                    }}</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-autocomplete
                v-model="item.project_id"
                :items="projects"
                item-text="name"
                item-value="id"
                clearable
                :loading="loadingProjects"
                :disabled="
                  (loadingProjects && projects && projects.length === 0) ||
                  isView
                "
                :rules="[(v) => !!v || 'Project is required']"
                @change="getJobs"
              >
                <template slot="label">
                  Project<RedAsterisk v-if="!isView" />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-o">
              <v-autocomplete
                v-model="item.truck_types"
                :items="truckTypes"
                item-text="name"
                item-value="id"
                label="Truck Types"
                clearable
                multiple
                return-object
                :rules="[(v) => !!v || 'Truck Type is required']"
                :disabled="isView"
              >
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer"
                    :color="item ? item.color : 'white'"
                  >
                    {{ item ? item.name : '' }}
                  </v-chip>
                </template>
                <template slot="label">
                  Truck Type<RedAsterisk v-if="!isView" />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="!item.id">
            <v-col class="pt-4">
              <v-textarea
                v-model="item.comment"
                outlined
                rows="3"
                label="Note"
                :disabled="isView"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="pt-6">
              <v-row>
                <v-col class="pt-4">
                  <span
                    :style="{
                      color: isDarkMode ? '' : '#000000',
                      fontSize: '16px',
                    }"
                    >Notes</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-card-text
                    style="
                      max-height: 300px;
                      overflow-y: auto;
                      border: 1px solid #d3d3d3;
                      border-radius: 3px;
                    "
                  >
                    <v-list two-line>
                      <template v-for="(note, index) in item.notes">
                        <v-list-item :key="note.id">
                          <!-- Display each note -->
                          <v-list-item-content>
                            {{ note.text }}
                            <v-list-item-subtitle style="padding-top: 10px">{{
                              note.created_at
                            }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{
                              `${
                                note.user.first_name ? note.user.first_name : ''
                              } ${
                                note.user.last_name ? note.user.last_name : ''
                              }`
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <!-- <v-divider v-if="index !== schedule.notes.length - 1"/> -->
                        </v-list-item>
                        <v-divider
                          v-if="index < item.notes.length - 1"
                          :key="index"
                        />
                      </template>
                    </v-list>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <span style="font-size: 18px"
                >Requested Delivery Date<RedAsterisk v-if="!isView"
              /></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-date-picker
                v-model="item.date"
                full-width
                :min="minDeliveryDate"
                :disabled="isView"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      date: new Date().toISOString().slice(0, 10),
      truck_types: [],
      job_id: null,
      project_id: null,
      inventory_class_id: null,
      comment: null,
    },
    jobs: [],
    projects: [],
    truckTypes: [],
    trucks: [],
    isView: false,
    inventoryClassId4500: 1,
    inventoryClassId4800: 2,
    filterTruckTypeId: null,
    loadingJobs: false,
    loadingProjects: false,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),

    minDeliveryDate() {
      return moment(new Date().toLocaleDateString('en-US'))
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    // 'item.inventory_class_id': function (value, oldValue) {
    //   if (!value || oldValue === undefined || (this.item.id && !oldValue) || value === oldValue) return;
    //   this.item.job_name = null;
    //   this.item.job_id = null;
    //   if (value === this.inventoryClassId4500 || value === this.inventoryClassId4800) {
    //     this.getJobs();
    //   }
    // },
  },
  created() {
    this.item = { ...this.itemDto };
    this.getJobs();
    this.getProjects();
    this.getTruckTypes();
  },
  methods: {
    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleRequest.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || !res.data[firstIndex]) return;
      this.item = res.data[firstIndex];
      this.filterTruckTypeId = this.item.truck_id;
    },

    async getJobs(projectId = null) {
      this.loadingJobs = true;
      this.item.job_id = null;
      this.jobs = [];
      const readModel = {
        filters: {
          inventory_class_id: this.item.inventory_class_id,
          project_id: projectId,
          is_truck_schedule: 1,
          is_sub_class: 1,
          // is_manual: 0,
        },
        sort: {
          order_by_first: 'id',
          direction_sort: 0,
        },
      };
      const res = await this.$api.job.readDataSecondary(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        this.loadingJobs = false;
        return;
      }
      this.jobs = res;
      this.loadingJobs = false;
    },

    async getProjects() {
      this.loadingProjects = true;
      const submitModel = {
        filters: {},
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (!res || !res.data) {
        this.projects = [];
        this.loadingProjects = false;
        return;
      }
      this.projects = res.data;
      this.loadingProjects = false;
    },

    async getTruckTypes() {
      const submitModel = {
        filters: {},
      };
      this.truckTypes = await this.$api.truckScheduleTruckType.read(
        submitModel
      );
    },

    async submit() {
      if (!this.item) return false;
      const date = new Date();
      const currentDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1
      );
      if (new Date(this.item.date) < currentDate) {
        this.$bus.$emit('showError', 'Past dates are not allowed!');
        return false;
      }
      let res;
      if (this.item.id) {
        res = await this.$api.truckScheduleRequest.update(this.item);
      } else {
        res = await this.$api.truckScheduleRequest.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id, isView = false) {
      if (id) {
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.isView = isView;
      // this.getJobs();
      this.$refs.dialogBox.open();
    },

    getJobTotalWindows() {
      if (!this.item.job_id) return 0;
      const job = this.jobs.find((x) => x.id === this.item.job_id);
      if (!job || !job.total_windows) return 0;
      return job.total_windows;
    },

    inventoryClassChange(inventoryClassId) {
      if (inventoryClassId == null) return;
      this.item.inventory_class_id = inventoryClassId;
      this.item.job_id = null;
      this.item.project_id = null;
      if (
        inventoryClassId === this.inventoryClassId4500 ||
        inventoryClassId === this.inventoryClassId4800
      ) {
        this.getJobs();
      }
    },

    updateInventoryClass(jobId) {
      if (!jobId) return;
      const job = this.jobs.find((x) => x.id === jobId);
      this.item.inventory_class_id = job.inventory_class_id;
      this.item.project_id = job.project_id;
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
::v-deep .hover-on-disabled {
  pointer-events: auto;
}
</style>
