<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="pt-1"
        cols="2"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :hide-details="true"
          :is-inst="false"
          :is-glass="false"
          :is-vwd-glass="false"
          :is-panels="false"
          :is-state="true"
          :is-parts="false"
          :is-sid="false"
          :is-rail-part="false"
          :is-equipment="false"
          :is-railings="false"
          :is-commercial="false"
          :is-paint="false"
          :is-patio-door="false"
          :is-euro-door="false"
        />
      </v-col>
      <v-col
        v-if="windowWidth > 1500"
        class="pt-1 pr-0"
        cols="3"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobs"
          hide-details="true"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          item-value="id"
          clearable
          :single-select="false"
          :loading="loadingJobs"
        >
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              v-if="item && item.sub_class"
              small
              :color="item.sub_class.color"
              text-color="white"
              class="mr-2"
            >
              {{ item.sub_class.name }}
            </v-chip>
            <span><span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span></span>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #item="{ item }">
            <v-chip
              v-if="item && item.sub_class"
              small
              :color="item.sub_class.color"
              text-color="white"
              class="mr-2"
            >
              {{ item.sub_class.name }}
            </v-chip>
            <span><span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="pb-0 pl-0"
      >
        <v-btn
          color="teal"
          outlined
          @click="openFinishedGoods()"
        >
          FINISHED GOODS
        </v-btn>
      </v-col>
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin))"
        cols="auto"
        class="pb-0 pl-0"
      >
        <v-btn
          color="purple"
          outlined
          :disabled="!selectedItems || selectedItems.length === 0"
          @click="archiveAll()"
        >
          {{ status == 1 ? 'ARCHIVE' : 'RESTORE' }}
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0 pl-0"
      >
        <v-menu
          v-model="filterMenu"
          transition="scale-transition"
          offset-y
          min-width="350"
          nudge-left="50"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="success"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="filterMenu = !filterMenu"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-filter
              </v-icon>
              FILTERS
            </v-btn>
          </template>
          <v-card
            class="pa-5"
            style="border-radius: 7px;"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <span style="font-size: 22px !important; font-weight: bold;">Filters</span>
                  </v-col>
                  <v-col class="text-end pr-1">
                    <v-btn
                      icon
                      :style="{color: isDarkMode ? '' : '#000000'}"
                      @click="filterMenu = !filterMenu"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="windowWidth < 1500">
                  <v-col>
                    <v-autocomplete
                      v-model="filterJob"
                      label="Job"
                      :items="jobs"
                      hide-details="true"
                      :item-text="item => `${item.code} - ${item.name}`"
                      item-value="id"
                      clearable
                      :single-select="false"
                    >
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <v-chip
                          v-if="item.sub_class"
                          class="mt-1 mb-1 mr-3"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="item && item.sub_class ? item.sub_class.color : 'white'"
                        >
                          {{ item && item.sub_class ? item.sub_class.name : '' }}
                        </v-chip>
                        <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #item="{ item }">
                        <v-chip
                          v-if="item.sub_class"
                          class="mt-1 mb-1 mr-3"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="item && item.sub_class ? item.sub_class.color : 'white'"
                        >
                          {{ item && item.sub_class ? item.sub_class.name : '' }}
                        </v-chip>
                        <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="filterRecordType"
                      label="Records Type"
                      :items="recordTypes"
                      hide-details="true"
                      item-text="name"
                      item-value="id"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="filterStage"
                      label="Production Stage"
                      :items="stages"
                      hide-details="true"
                      item-text="name"
                      item-value="id"
                      clearable
                    >
                      <template #selection="{ item }">
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >{{ item.code }}</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 14px !important;">{{ item.name }}</span>
                          </v-col>
                        </v-row>
                      </template>
                      <template #item="{ item }">
                        <v-row align="center">
                          <v-col
                            cols="auto"
                            class="pr-0"
                          >
                            <v-btn
                              elevation="0"
                              fab
                              outlined
                              tile
                              readonly
                              color="#FFD84D"
                              style="border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D;"
                            >
                              <span
                                style="color: #000000;"
                              >
                                {{ item.code }}</span>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <span style="font-size: 14px !important;">{{ item.name }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="filterPo"
                      label="SP+S/U PO#"
                      :items="poNumbers"
                      hide-details
                      clearable
                      :loading="loadingPo"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menuRequiredBy"
                      v-model="dateMenuRequiredBy"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterGlassReqBy"
                          label="Glass Required By"
                          readonly
                          v-bind="attrs"
                          append-icon="mdi-calendar"
                          hide-details
                          v-on="on"
                          @change="$refs.menuRequiredBy.save(filterGlassReqBy)"
                        />
                      </template>
                      <v-date-picker
                        v-model="filterGlassReqBy"
                        no-title
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="filterGlassReqBy = null"
                        >
                          Clear
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menuShipDate"
                      v-model="dateMenuShipDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterPlannedShipDate"
                          readonly
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          hide-details
                          v-on="on"
                          @change="$refs.menuShipDate.save(filterPlannedShipDate)"
                        >
                          <template slot="label">
                            Plan Ship Date Start
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterPlannedShipDate"
                        no-title
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="filterPlannedShipDate = null"
                        >
                          Clear
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menuShipDateEnd"
                      v-model="dateMenuShipDateEnd"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterPlannedShipDateEnd"
                          readonly
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          hide-details
                          v-on="on"
                          @change="$refs.menuShipDateEnd.save(filterPlannedShipDateEnd)"
                        >
                          <template slot="label">
                            Plan Ship Date End
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterPlannedShipDateEnd"
                        no-title
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="filterPlannedShipDateEnd = null"
                        >
                          Clear
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    class="pt-8"
                    cols="auto"
                  >
                    <span :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}">Hide</span>
                  </v-col>
                  <v-col
                    class="pl-0"
                  >
                    <v-switch
                      v-model="isGlassRelated"
                      hide-details
                      label="Show Glass Related Only"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col />
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      @click="filterMenu = !filterMenu"
                    >
                      <span style="font-size: 14px !important;">Close</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      @click="clearAllFilters"
                    >
                      Clear
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0 pl-0"
      >
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              color="orange"
              outlined
              v-bind="attrs"
              class="pl-2"
              v-on="on"
            >
              <v-icon>mdi-chevron-down</v-icon> ACTIONS
            </v-btn>
          </template>
          <v-list
            class="pa-3"
          >
            <v-list-item
              @click="runWebhook('pdf')"
            >
              <v-list-item-title style="color: #1976d2; font-weight: 600;">
                GENERATE PDF
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="runWebhook('xls')"
            >
              <v-list-item-title style="color: #4caf50; font-weight: 600;">
                GENERATE XLS
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate)"
              @click="sendEmailPdf()"
            >
              <v-list-item-title style="color: #ff9800; font-weight: 600;">
                SEND EMAIL PDF
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="selectedItems.length === 0"
              @click="openGlassRequiredByDateFormMultiple()"
            >
              <v-list-item-title :style="`color: ${selectedItems.length === 0 ? 'rgba(0,0,0,0.4)' : '#673AB7'}; font-weight: 600;`">
                CHANGE GLASS REQUIRED BY DATE
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="selectedItems.length === 0"
              @click="openPlannedShipDateMultipleForm()"
            >
              <v-list-item-title :style="`color: ${selectedItems.length === 0 ? 'rgba(0,0,0,0.4)' : '#009688'}; font-weight: 600;`">
                CHANGE PLAN SHIP DATE
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin))"
        cols="auto"
        class="pb-0 pl-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="open()"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Record
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col
        v-for="tab, idx in tabs"
        :key="idx"
        cols="auto"
        :style="`cursor: pointer; border-bottom: ${JSON.stringify(selectedTab) == JSON.stringify(tab) ? '2px solid #1976d2' : 'none'};`"
        class="ml-2 mr-2"
        @click="tabChange(tab)"
      >
        <span :style="`font-size: 16px !important; color: ${JSON.stringify(selectedTab) == JSON.stringify(tab) ? '#1976d2' : isDarkMode ? '' : 'rgba(0,0,0,0.8)'}; font-weight: ${JSON.stringify(selectedTab) == JSON.stringify(tab) ? 'bold' : 'normal'};`">{{ tab.name }}</span>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="pr-6"
      >
        <v-row align="center">
          <v-col
            cols="auto"
            class="pr-0 pt-4"
          >
            <span :style="{ fontSize: '16px !important', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0,0,0,0.6)'}">Show Main</span>
          </v-col>
          <v-col cols="auto">
            <v-switch
              v-model="isShowBreakdown"
              hide-details
              class="mt-0"
              label="Breakdown View"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="auto"
      >
        <v-row align="center">
          <v-col
            cols="auto"
            class="pr-0 pt-4"
          >
            <span :style="{ fontSize: '16px !important', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0,0,0,0.6)'}">Hide</span>
          </v-col>
          <v-col cols="auto">
            <v-switch
              v-model="isShowStages"
              hide-details
              class="mt-0"
              label="Show Stages"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!isShowBreakdown">
      <v-col
        class="pa-1"
      >
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            fixed-header
            :loading="loading"
            dense
            :height="windowHeight"
            :server-items-length="totalItems"
            @update:options="updateOptions"
          >
            <template
              v-for="h in headers"
              #[`header.${h.value}`]="{ header }"
            >
              <v-tooltip
                :key="h.value"
                top
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>{{ header.fullText }}</span>
              </v-tooltip>
            </template>
            <template #item="{ item }">
              <tr>
                <td
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate))"
                  class="pl-1 pr-1"
                >
                  <v-checkbox
                    v-model="selectedItems"
                    :value="item"
                    class="mt-0 mb-0 pt-0"
                    hide-details
                  />
                </td>
                <td class="pl-1 pr-1">
                  <v-chip
                    small
                    :color="item && item.class ? item.class.color : 'grey'"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : '' }}
                  </v-chip>
                </td>
                <td class="pl-1 pr-1">
                  <span><text-highlight :queries="search">{{ item.location ? item.location.name : '' }}</text-highlight></span>
                </td>
                <td class="pl-1 pr-1">
                  <v-tooltip
                    top
                    color="transparent"
                    style="opacity: 1;"
                  >
                    <template #activator="{ on }">
                      <span
                        v-if="item.is_shipping_complete == 1"
                        :style="{fontWeight: 500, color: (isDarkMode ? 'black' : '#0000000'), borderRadius: '4px', width: 'auto', height: '25px', border: 0, backgroundColor: '#C8E6C9'}"
                        class="pa-1"
                        v-on="on"
                      ><text-highlight :queries="search">{{ item.project }}</text-highlight></span>
                      <span
                        v-else-if="item.is_ready_for_shipping == 1"
                        :style="{fontWeight: 500, color: (isDarkMode ? 'black' : '#0000000'), borderRadius: '4px', width: 'auto', height: '25px', border: 0, backgroundColor: '#F8BBD0'}"
                        class="pa-1"
                        v-on="on"
                      ><text-highlight :queries="search">{{ item.project }}</text-highlight></span>

                      <span
                        v-else
                        style="font-weight: 500;"
                        v-on="on"
                      ><text-highlight :queries="search">{{ item.project }}</text-highlight></span>
                    </template>
                    <v-chip
                      v-if="item && item.sub_class"
                      small
                      :color="item.sub_class.color"
                      text-color="white"
                    >
                      {{ item.sub_class.name }}
                    </v-chip>
                  </v-tooltip>
                </td>
                <td class="pl-1 pr-1">
                  <span><text-highlight :queries="search">{{ item.project_floor }}</text-highlight></span>
                </td>
                <td class="pl-1 pr-1">
                  <span><text-highlight :queries="search">{{ item.wo_number }}</text-highlight></span>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <span><text-highlight :queries="search">{{ item.window_verticals }}</text-highlight></span>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <span><text-highlight :queries="search">{{ item.patio_doors }}</text-highlight></span>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <span><text-highlight :queries="search">{{ item.euro_doors }}</text-highlight></span>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <span>{{ item.total_release_qty }}</span>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    tile
                    color="#FFD84D"
                    :class="`box ${item.is_pp_finished ? 'green-box' : item.is_pp_started ? 'yellow-box' : 'empty-box'}`"
                    @click="triggerStageChange(item, ppStartedId)"
                  >
                    <span
                      v-if="item.is_pp_finished"
                      :style="`color: #FFFFFF`"
                    >{{ item.total_project_panels ? item.total_project_panels : '0' }}</span>
                    <span
                      v-else-if="item.is_pp_started"
                      :style="`color: #000000`"
                    >{{ item.total_project_panels ? item.total_project_panels : '0' }}</span>
                    <span
                      v-else
                      :style="`color: #000000;`"
                    >{{ item.total_project_panels ? item.total_project_panels : '0' }}</span>
                  </v-btn>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    tile
                    color="#FFD84D"
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D; background-color: ${item.is_splander_paint ? '#FFD84D' : ''}`"
                    @click="triggerStageChange(item, spandrelId)"
                  >
                    <span
                      v-if="item.is_splander_paint"
                      style="color: #000000;"
                    >S</span>
                  </v-btn>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    tile
                    color="#FFD84D"
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D; background-color: ${item.is_panels ? '#FFD84D' : ''}`"
                    @click="triggerStageChange(item, panelsId)"
                  >
                    <span
                      v-if="item.is_panels"
                      style="color: #000000;"
                    >P</span>
                  </v-btn>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    tile
                    readonly
                    :ripple="false"
                    color="#FFD84D"
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D; background-color: ${item.is_material_available ? '#FFD84D' : ''}`"
                    @click="triggerStageChange(item, materialAvailableId)"
                  >
                    <span
                      v-if="item.is_material_available"
                      style="color: #000000;"
                    >M</span>
                  </v-btn>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    tile
                    readonly
                    :ripple="false"
                    color="#FFD84D"
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_cut > 0 ? '#FFD84D' : item.is_assemble == 1 ? '#4CAF50' : ''}; background-color: ${item.total_cut > 0 ? '#FFD84D' : item.is_assemble == 1 ? '#4CAF50' : ''}`"
                    @click="openFinishedGoodForm(item, 2)"
                  >
                    <span
                      v-if="item.total_cut > 0"
                      style="color: #000000;"
                    >{{ item.total_cut }}</span>
                    <span
                      v-else-if="item.is_assemble == 1"
                      style="color: #FFFFFF;"
                    >C</span>
                    <span
                      v-else
                      style="color: #000000;"
                    />
                  </v-btn>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    tile
                    readonly
                    color="#FFD84D"
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_assemble > 0 ? '#FFD84D' : item.is_t_and_b == 1 ? '#4CAF50' : ''}; background-color: ${item.total_assemble > 0 ? '#FFD84D' : item.is_t_and_b == 1 ? '#4CAF50' : ''}`"
                    @click="openFinishedGoodForm(item, 4)"
                  >
                    <span
                      v-if="item.total_assemble > 0"
                      style="color: #000000;"
                    >{{ item.total_assemble }}</span>
                    <span
                      v-else-if="item.is_t_and_b == 1"
                      style="color: #FFFFFF;"
                    >A</span>
                    <span
                      v-else
                      style="color: #000000;"
                    />
                  </v-btn>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    tile
                    readonly
                    color="#FFD84D"
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_t_and_b > 0 ? '#FFD84D' : item.is_glaze == 1 ? '#4CAF50' : ''}; background-color: ${item.total_t_and_b > 0 ? '#FFD84D' : item.is_glaze == 1 ? '#4CAF50' : ''}`"
                    @click="openFinishedGoodForm(item, 5)"
                  >
                    <span
                      v-if="item.total_t_and_b > 0"
                      style="color: #000000;"
                    >{{ item.total_t_and_b }}</span>
                    <span
                      v-else-if="item.is_glaze == 1"
                      style="color: #FFFFFF;"
                    >T</span>
                    <span
                      v-else
                      style="color: #000000;"
                    />
                  </v-btn>
                </td>
                <td
                  v-if="isShowStages"
                  class="pl-1 pr-1"
                >
                  <v-btn
                    elevation="0"
                    fab
                    outlined
                    tile
                    color="#FFD84D"
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_glaze > 0 ? '#FFD84D' : item.is_glaze == 1 ? '#4CAF50' : ''}; background-color: ${item.total_glaze > 0 ? '#FFD84D' : item.is_glaze == 1 ? '#4CAF50' : ''}`"
                    @click="openFinishedGoodForm(item, 6)"
                  >
                    <span
                      v-if="item.total_glaze > 0"
                      style="color: #000000;"
                    >{{ item.total_glaze }}</span>
                    <span
                      v-else-if="item.is_glaze == 1"
                      style="color: #FFFFFF;"
                    >G</span>
                    <span
                      v-else
                      style="color: #000000;"
                    />
                  </v-btn>
                </td>
                <td class="pl-1 pr-1 text-center">
                  <v-btn
                    v-if="item.is_prod_backlog && item.total_prod_backlog >= 0"
                    elevation="0"
                    fab
                    outlined
                    tile
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_prod_backlog != 0 ? '#EF5350' : '#4CAF50'}; background-color: ${item.total_prod_backlog != 0 ? '#EF5350' : '#4CAF50'} ;`"
                    @click="openFinishedGoodForm(item, 8)"
                  >
                    <span
                      style="color: #FFFFFF;"
                    >{{ item.total_prod_backlog || item.total_prod_backlog == 0 ? item.total_prod_backlog : '' }}</span>
                  </v-btn>
                  <a
                    v-else
                  >-</a>
                </td>
                <td class="pl-1 pr-1">
                  <v-btn
                    v-if="item.is_shipping && item.total_shipping >= 0"
                    elevation="0"
                    fab
                    outlined
                    tile
                    readonly
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_shipping != 0 ? '#EF5350' : '#4CAF50'}; background-color: ${item.total_shipping != 0 ? '#EF5350' : '#4CAF50'};`"
                    @click="openFinishedGoodForm(item, 9)"
                  >
                    <span
                      style="color: #FFFFFF;"
                    >{{ item.total_shipping || item.total_shipping == 0 ? item.total_shipping : '' }}</span>
                  </v-btn>
                  <a
                    v-else
                  >-</a>
                </td>
                <td class="pl-1 pr-1">
                  <v-btn
                    v-if="item.is_received && item.total_shipped >= 0"
                    elevation="0"
                    fab
                    outlined
                    tile
                    readonly
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_shipped != 0 ? '#EF5350' : '#4CAF50'}; background-color: ${item.total_shipped != 0 ? '#EF5350' : '#4CAF50'};`"
                    @click="openFinishedGoodForm(item, 10)"
                  >
                    <span
                      style="color: #FFFFFF;"
                    >{{ item.total_shipped || item.total_shipped == 0 ? item.total_shipped : '' }}</span>
                  </v-btn>
                  <a
                    v-else
                  >-</a>
                </td>
                <td class="pl-1 pr-1">
                  <v-btn
                    v-if="item.total_returned > 0"
                    elevation="0"
                    fab
                    outlined
                    tile
                    readonly
                    :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_returned != 0 ? '#EF5350' : '#4CAF50'}; background-color: ${item.total_returned != 0 ? '#EF5350' : '#4CAF50'};`"
                    @click="openFinishedGoodForm(item, 12)"
                  >
                    <span
                      style="color: #FFFFFF;"
                    >{{ item.total_returned || item.total_returned == 0 ? item.total_returned : '' }}</span>
                  </v-btn>
                  <a
                    v-else
                  >-</a>
                </td>
                <td class="pl-1 pr-1 text-center">
                  <span
                    v-if="item.sp_po_number"
                    style="cursor: pointer;"
                    @click="openWindowBreakdown(item, typeIdSPItems)"
                  ><text-highlight :queries="search">{{ item.sp_po_number }}</text-highlight></span>
                  <span
                    v-else
                  >N/A</span>
                </td>
                <td
                  class="pl-1 pr-1"
                  @click="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdGlassHandler) ? openMenuSPGlassNote(item.id) : ''"
                >
                  <v-btn
                    v-if="item.sp_note_status"
                    elevation="0"
                    fab
                    outlined
                    tile
                    :color="item.sp_note_status.color"
                    :style="{
                      borderRadius: '4px',
                      width: item.sp_note_status.name === 'N/A' || item.sp_note_status.name === 'PEN' || (item.sp_note_status.id === noteStatusIdShort && item.sp_note && item.sp_note.length > 2) ? 'auto' : '25px',
                      height: '25px',
                      padding: '0 7px',
                      border: `1.5px solid ${item.sp_note_status.color}`,
                      backgroundColor: `${item.sp_note_status.color}`,
                    }"
                  >
                    <span
                      v-if="item.sp_note_status.id === noteStatusIdShort"
                      style="color: #000000;"
                    >-{{ item.sp_note }}</span>
                    <span
                      v-else
                      :style="`color: ${item.sp_note_status_id === noteStatusIdZero ? '#FFF' : '#000'}`"
                    >{{ item.sp_note_status.name }}</span>
                  </v-btn>
                  <v-btn
                    v-else
                    elevation="0"
                    fab
                    outlined
                    tile
                    :style="{
                      borderRadius: '4px',
                      width: 'auto',
                      height: '25px',
                      padding: '0 7px',
                      border: `1.5px solid #E0E0E0`,
                      backgroundColor: `#E0E0E0`,
                      color: '#000000'
                    }"
                  >
                    <span>N/A</span>
                  </v-btn>
                </td>
                <td
                  v-if="!isShowStages"
                  class="text-center"
                >
                  <v-chip
                    v-if="item.sp_po_status"
                    small
                    :color="item && item.sp_po_status ? item.sp_po_status.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.sp_po_status ? item.sp_po_status.name : '' }}
                  </v-chip>
                </td>
                <td class="pl-1 pr-1 text-center">
                  <span
                    v-if="item.su_po_number"
                    style="cursor: pointer;"
                    @click="openWindowBreakdown(item, typeIdSUItems)"
                  ><text-highlight :queries="search">{{ item.su_po_number }}</text-highlight></span>
                  <span
                    v-else
                  >N/A</span>
                </td>
                <td
                  class="pl-1 pr-1"
                  style="cursor: pointer;"
                  @click="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdGlassHandler) ? openMenuSUGlassNote(item.id) : ''"
                >
                  <v-btn
                    v-if="item.su_note_status"
                    elevation="0"
                    fab
                    outlined
                    tile
                    :color="item.su_note_status.color"
                    :style="{
                      borderRadius: '4px',
                      width: item.su_note_status.name === 'N/A' || item.su_note_status.name === 'PEN' || (item.su_note_status.id === noteStatusIdShort && item.su_note && item.su_note.length > 2) ? 'auto' : '25px',
                      height: '25px',
                      padding: '0 7px',
                      border: `1.5px solid ${item.su_note_status.color}`,
                      backgroundColor: `${item.su_note_status.color}`
                    }"
                  >
                    <span
                      v-if="item.su_note_status.id === noteStatusIdShort"
                      :style="`color: #000;`"
                    >-{{ item.su_note }}</span>
                    <span
                      v-else
                      :style="`color: ${item.su_note_status_id === noteStatusIdZero ? '#FFF' : '#000'}`"
                    >{{ item.su_note_status.name }}</span>
                  </v-btn>
                  <v-btn
                    v-else
                    elevation="0"
                    fab
                    outlined
                    tile
                    :style="{
                      borderRadius: '4px',
                      width: 'auto',
                      height: '25px',
                      padding: '0 7px',
                      border: `1.5px solid #E0E0E0`,
                      backgroundColor: `#E0E0E0`,
                      color: '#000000'
                    }"
                  >
                    <span>N/A</span>
                  </v-btn>
                </td>
                <td
                  v-if="!isShowStages"
                  class="text-center"
                >
                  <v-chip
                    v-if="item.su_po_status"
                    small
                    :color="item && item.su_po_status ? item.su_po_status.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.su_po_status ? item.su_po_status.name : '' }}
                  </v-chip>
                </td>
                <td class="pl-1 pr-1">
                  <a
                    style="white-space: nowrap;"
                    @click="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) ? openGlassRequiredByDateForm(item) : ''"
                  ><text-highlight :queries="search">{{ item.glass_required_by_short }}</text-highlight></a>
                </td>
                <td class="pl-0 pr-0">
                  <v-icon
                    v-if="item.required_by_is_up == 1"
                    color="#388E3C"
                    style="font-size: 20px;"
                  >
                    mdi-arrow-up
                  </v-icon>
                  <v-icon
                    v-else-if="item.required_by_is_up == 0"
                    color="#D32F2F"
                    style="font-size: 20px;"
                  >
                    mdi-arrow-down
                  </v-icon>
                </td>
                <td class="pl-1 pr-1">
                  <a
                    style="white-space: nowrap;"
                    @click="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) ? openPlannedShipDateForm(item) : ''"
                  ><text-highlight :queries="search">{{ item.planned_ship_date_short }}</text-highlight></a>
                </td>
                <td class="pl-0 pr-0">
                  <v-icon
                    v-if="item.ship_date_is_up == 1"
                    color="#388E3C"
                    style="font-size: 20px;"
                  >
                    mdi-arrow-up
                  </v-icon>
                  <v-icon
                    v-else-if="item.ship_date_is_up == 0"
                    color="#D32F2F"
                    style="font-size: 20px;"
                  >
                    mdi-arrow-down
                  </v-icon>
                </td>
                <td class="pl-1 pr-1">
                  <v-tooltip
                    v-if="item.last_note"
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span
                        style="white-space: nowrap; color: rgb(255, 152, 0);"
                        v-on="on"
                      >
                        <v-btn
                          v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdProductionSupervisor) || roles.includes(userRoleIdGlassHandler)"
                          icon
                          small
                          @click="openNotes(item.id)"
                        >
                          <v-icon
                            small
                          >
                            mdi-eye
                          </v-icon>
                        </v-btn><text-highlight
                          :queries="search"
                        >{{ item.last_note_short }}</text-highlight></span>
                    </template>
                    <span>{{ item.last_note }}</span>
                  </v-tooltip>
                </td>
                <td
                  id="cellOverflow"
                  :class="(isDarkMode ? 'theme--dark ' : '') + 'pl-0 pr-0 text-end'"
                >
                  <v-btn
                    v-if="item.status_id == statusIdOnHand"
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openEdit(item)"
                  >
                    EDIT
                  </v-btn>
                  <v-btn
                    v-if="item.status_id == statusIdOnHand"
                    class="ml-1 mt-1 mb-1 mr-1"
                    color="error"
                    outlined
                    small
                    @click="softDelete(item.id)"
                  >
                    DELETE
                  </v-btn>
                  <v-btn
                    v-if="!item.is_material_available && item.status_id != statusIdOnHand && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdProductionSupervisor) || roles.includes(userRoleIdGlassHandler))"
                    class="ml-1 mr-3 mt-1 mb-1 elevation-0"
                    color="#F7A51C"
                    small
                    @click="finishStage(item.id, materialAvailableId)"
                  >
                    <span style="color: #000;">FINISH MATERIAL</span>
                  </v-btn>
                  <v-btn
                    v-if="item.is_material_available && item.status_id != statusIdOnHand && ((roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate)) && item.is_glaze == 1)"
                    class="ml-1 mr-3 mt-1 mb-1 elevation-0"
                    color="#0BDE79"
                    small
                    @click="toggle(item.id, item.status_id)"
                  >
                    <span style="color: white;">{{ item.status_id === statusIdInProgress ? 'COMPLETE' : 'RESTORE' }}</span>
                  </v-btn>
                  <v-menu
                    v-if="item.status_id != statusIdOnHand"
                    offset-y
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mb-1 mr-1"
                        elevation="0"
                        fab
                        outlined
                        x-small
                        v-bind="attrs"
                        tile
                        color="primary"
                        style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      class="pa-3"
                    >
                      <v-list-item
                        v-if="(item.status_id == statusIdInProgress || item.status_id == statusIdOnHand) && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin))"
                        @click="openEdit(item)"
                      >
                        <v-list-item-title style="color: #1976d2; font-weight: 600;">
                          EDIT
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin)) && (item.status_id == statusIdInProgress || item.status_id == statusIdOnHand)"
                        @click="softDelete(item.id)"
                      >
                        <v-list-item-title style="color: #ff5252; font-weight: 600;">
                          DELETE
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdProductionSupervisor) || roles.includes(userRoleIdGlassHandler)"
                        @click="openNotes(item.id)"
                      >
                        <v-list-item-title style="color: #ff9800; font-weight: 600;">
                          NOTES {{ item.notes ? `(${item.notes.length})` : '' }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin)) && (item.status_id == statusIdInProgress || item.status_id == statusIdOnHand)"
                        @click="toggle(item.id, item.status_id, true)"
                      >
                        <v-list-item-title style="color: purple; font-weight: 600;">
                          ARCHIVE
                        </v-list-item-title>
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate))"
                        @click="openTruckForm(item)"
                      >
                        <v-list-item-title
                          style="color: gray; font-weight: 600;"
                        >
                          <v-icon
                            small
                            class="mr-1 mb-1"
                            color="gray"
                          >
                            fas fa-plus
                          </v-icon> ADD TRUCK
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="((roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate))) && item.truck_schedule_total > 0"
                        @click="redirectTrucks(item.job_id)"
                      >
                        <v-list-item-title
                          style="color: gray; font-weight: 600;"
                        >
                          VIEW TRUCKS {{ `(${item.truck_schedule_total})` }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        v-if="!item.is_splander_paint && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor))"
                        @click="finishStage(item.id, spandrelId)"
                      >
                        <v-list-item-title style="color: #F7014C; font-weight: bold;">
                          FINISH SPANDREL
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-else-if="item.is_splander_paint && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor))"
                        @click="cancelStage(item.id, spandrelId)"
                      >
                        <v-list-item-title style="color: #F7014C; font-weight: bold;">
                          CANCEL SPANDREL
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="!item.is_panels && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor))"
                        @click="finishStage(item.id, panelsId)"
                      >
                        <v-list-item-title style="color: #1874A7; font-weight: bold;">
                          FINISH PANELS
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-else-if="item.is_panels && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor))"
                        @click="cancelStage(item.id, panelsId)"
                      >
                        <v-list-item-title style="color: #1874A7; font-weight: bold;">
                          CANCEL PANELS
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <window-breakdown-view
          :filter-location-id="filterLocationId"
          :filter-job-sub-class="filterJobSubClass"
          :filter-job="filterJob"
          :inventory-class-id="inventoryClassId"
          :filter-planned-ship-date="filterPlannedShipDate"
          :filter-planned-ship-date-end="filterPlannedShipDateEnd"
          :status="status"
          @open-notes="openNotes"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogProdBacklogSchedule"
      max-width="300px"
    >
      <v-card v-if="selectedProdBacklogItem">
        <v-card-text class="pt-6">
          <v-text-field
            v-model="selectedProdBacklogItem.production_backlog"
            label="Production Backlog"
            hide-details
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="normal"
            class="mr-2"
            @click="dialogProdBacklogSchedule = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="updateProductionBacklog"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogShipBacklogSchedule"
      max-width="300px"
    >
      <v-card v-if="selectedShipBacklogItem">
        <v-card-text class="pt-6">
          <v-text-field
            v-model="selectedShipBacklogItem.shipping_backlog"
            label="Shipping Backlog"
            hide-details
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="normal"
            class="mr-2"
            @click="dialogShipBacklogSchedule = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="updateShipBacklog"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSPGlassNote"
      max-width="300px"
    >
      <v-card v-if="selectedItem">
        <v-card-text class="pt-6">
          <v-select
            v-model="selectedItem.sp_note_status_id"
            label="SP PO# Glass Note"
            item-value="id"
            item-text="name"
            :items="noteStatuses"
            clearable
          >
            <template slot="label">
              SU PO# Glass Note
            </template>
          </v-select>
          <v-text-field
            v-if="selectedItem.sp_note_status_id === noteStatusIdShort"
            v-model="selectedItem.sp_note"
            label="SP PO# Missing"
            type="number"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="normal"
            class="mr-2"
            @click="dialogSPGlassNote = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="updateSPGlassNote"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSUGlassNote"
      max-width="300px"
    >
      <v-card v-if="selectedItem">
        <v-card-text class="pt-6">
          <v-select
            v-model="selectedItem.su_note_status_id"
            label="SU PO# Glass Note"
            item-value="id"
            item-text="name"
            :items="noteStatuses"
            clearable
          >
            <template slot="label">
              SU PO# Glass Note
            </template>
          </v-select>
          <v-text-field
            v-if="selectedItem.su_note_status_id === noteStatusIdShort"
            v-model="selectedItem.su_note"
            label="SU PO# Missing"
            type="number"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="normal"
            class="mr-2"
            @click="dialogSUGlassNote = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="updateSUGlassNote"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogGlassRequiredByDateMultiple"
      max-width="350px"
    >
      <v-card>
        <v-card-text class="pt-6">
          <v-date-picker
            v-model="glassRequiredByDateMultiple"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dialogGlassRequiredByDateMultiple = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="updateGlassRequiredByDateMultiple(); dialogGlassRequiredByDateMultiple = false"
            >
              Apply
            </v-btn>
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogShipDate"
      max-width="350px"
    >
      <v-card v-if="selectedItem">
        <v-card-text class="pt-6">
          <v-date-picker
            v-model="selectedItem.glass_required_by"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dialogShipDate = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="updateGlassRequiredByDate(selectedItem.id, selectedItem.glass_required_by); dialogShipDate = false"
            >
              Apply
            </v-btn>
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPlannedDate"
      max-width="350px"
    >
      <v-card v-if="selectedItem">
        <v-card-text class="pt-6">
          <v-row>
            <v-col>
              <v-date-picker
                v-model="selectedItem.planned_ship_date"
                no-title
                scrollable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="selectedItem.planned_ship_time"
                type="time"
                :rules="[(v) => !!v || 'Ship Time is required']"
              >
                <template slot="label">
                  Ship Time<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="dialogPlannedDate = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="updatePlannedShipDate(selectedItem.id, selectedItem.planned_ship_date, selectedItem.planned_ship_time); dialogPlannedDate = false"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPlannedShipDateMultiple"
      max-width="350px"
    >
      <v-card>
        <v-card-text class="pt-6">
          <v-date-picker
            v-model="plannedShipDateMultiple"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="dialogPlannedShipDateMultiple = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="updatePlannedShipDateMultiple(); dialogPlannedShipDateMultiple = false"
            >
              Apply
            </v-btn>
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <production-schedule-form
      ref="productionForm"
      @success="get()"
    />
    <production-schedule-note-form
      ref="noteForm"
      @success="get()"
    />
    <production-schedule-history-form
      ref="historyForm"
      @success="get()"
    />

    <truck-schedule-approve-form
      ref="approveForm"
      @success="get()"
    />

    <production-schedule-finished-good-form
      ref="finishedGoodForm"
    />

    <window-breakdown-items-form
      ref="windowBreakdownItemsForm"
    />

    <production-schedule-finished-good-grouped-form
      ref="finishedGoodGroupedForm"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProductionScheduleForm from '@/components/forms/productionSchedules/ProductionScheduleForm';
import ProductionScheduleNoteForm from '@/components/forms/productionSchedules/ProductionScheduleNoteForm';
import ProductionScheduleHistoryForm from '@/components/forms/productionSchedules/ProductionScheduleHistoryForm';
import TruckScheduleApproveForm from '@/components/forms/truckSchedules/TruckScheduleApproveForm';
import WindowBreakdownView from '@/views/ProductionSchedules/WindowBreakdownView';
import ProductionScheduleFinishedGoodForm from '@/components/forms/productionSchedules/ProductionScheduleFinishedGoodForm';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';
import WindowBreakdownItemsForm from '@/components/forms/productionSchedules/WindowBreakdownItemsForm';
import ProductionScheduleFinishedGoodGroupedForm from '@/components/forms/productionSchedules/ProductionScheduleFinishedGoodGroupedForm';

export default {
  components: {
    ProductionScheduleForm,
    ProductionScheduleNoteForm,
    ProductionScheduleHistoryForm,
    TruckScheduleApproveForm,
    WindowBreakdownView,
    ProductionScheduleFinishedGoodForm,
    WindowBreakdownItemsForm,
    ProductionScheduleFinishedGoodGroupedForm,
  },
  props: {

  },
  data: () => ({
    items: [],
    totalItems: 0,
    allItems: [],
    loading: false,
    filterJob: null,
    filterPo: null,
    filterStage: null,
    filterGlassReqBy: null,
    filterPlannedShipDate: null,
    filterPlannedShipDateEnd: null,
    filterLocationId: null,
    filterJobSubClass: null,
    filterRecordType: null,
    dateMenu: false,
    inventoryClassId: null,
    jobs: [],
    poNumbers: [],
    stages: [{ name: 'Material', id: 1, code: 'M' }, { name: 'Cut', id: 2, code: 'C' }, { name: 'Assembled', id: 3, code: 'A' }, { name: 'Spandrel', id: 4, code: 'S' }, { name: 'Panels', id: 5, code: 'P' }, { name: 'T&B', id: 6, code: 'T' }, { name: 'Glazed', id: 7, code: 'G' }],
    footer: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    statusIdInProgress: 1,
    statusIdComleted: 2,
    statusIdOnHand: 3,
    noteStatusIdShort: 2,
    noteStatusIdZero: 3,
    materialAvailableId: 1,
    cutId: 2,
    assembleId: 3,
    spandrelId: 4,
    panelsId: 5,
    tAndBId: 6,
    glazedId: 7,
    ppStartedId: 8,
    ppFinishedId: 9,
    topSpaceHeight: 250,
    windowHeight: 1000,
    windowWidth: 1000,
    status: null,
    pagination: {
      itemsPerPage: 500,
      page: 1,
      sortBy: 'planned_ship_date',
      sortDesc: true,
    },
    filterMenu: false,
    dateMenuRequiredBy: false,
    dateMenuShipDate: false,
    dateMenuShipDateEnd: false,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    userRoleIdProductionSupervisor: 18,
    userRoleIdSplanderPaintSupervisor: 21,
    userRoleIdProductionAdmin: 22,
    recordTypes: [{ id: 1, name: 'Default Records' }, { id: 2, name: 'Manual Records' }],
    selectedItems: [],
    tabs: [],
    locationTabFilter: null,
    selectedTab: null,
    selectedProdBacklogItem: null,
    dialogProdBacklogSchedule: false,
    dialogShipBacklogSchedule: false,
    selectedShipBacklogItem: null,
    dialogSPGlassNote: false,
    dialogSUGlassNote: false,
    dialogShipDate: false,
    dialogPlannedDate: false,
    selectedItem: null,
    noteStatuses: [],
    isShowStages: true,
    isShowBreakdown: false,
    typeIdSUItems: 1,
    typeIdSPItems: 2,
    loadingJobs: false,
    loadingPo: false,
    dialogGlassRequiredByDateMultiple: false,
    dialogPlannedShipDateMultiple: false,
    glassRequiredByDateMultiple: null,
    plannedShipDateMultiple: null,
    isGlassRelated: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
      productionScheduleState: (state) => state.productionScheduleState,
    }),
    ...mapGetters([

    ]),
    headers() {
      const headers = [];
      if (this.roles.includes(this.userRoleIdAdmin) || this.roles.includes(this.userRoleIdProductionAdmin) || this.roles.includes(this.userRoleIdAssociate)) {
        headers.push({
          text: '', fullText: '', value: 'checkbox', class: 'pl-1 pr-1', width: '2%', sortable: false,
        });
      }
      headers.push({
        text: 'Class', fullText: 'Class', value: 'class', class: 'pl-1 pr-1', width: '2%', sortable: false,
      });
      headers.push({
        text: 'Loc.', fullText: 'Location', value: 'location', class: 'pl-1 pr-1', width: '6%',
      });
      headers.push({
        text: 'Project', fullText: 'Project', value: 'project', class: 'pl-1 pr-1',
      });
      headers.push({
        text: 'Floor', fullText: 'Floor', value: 'project_floor', class: 'pl-1 pr-1',
      });
      headers.push({
        text: 'WO#', fullText: 'WO#', value: 'wo_number', class: 'pl-1 pr-1', width: '2%',
      });
      if (this.isShowStages) {
        headers.push({
          text: 'Win. Vert.', fullText: 'Window Verticals', value: 'window_verticals', class: 'pl-1 pr-1', width: '2%',
        });
        headers.push({
          text: 'Pat. D.', fullText: 'Patio Doors', value: 'patio_doors', class: 'pl-1 pr-1', width: '2%',
        });
        headers.push({
          text: 'Eu. D.', fullText: 'Euro Doors', value: 'euro_doors', class: 'pl-1 pr-1', width: '2%',
        });
        headers.push({
          text: 'Total Rel. Qty', fullText: 'Total Release Quantity', value: 'total_release_qty', class: 'pl-1 pr-1', width: '2%',
        });
        headers.push({
          text: 'PP', fullText: 'Projected Panels', value: 'projected-panels', class: 'pl-1 pr-1', width: '2%',
        });
        headers.push({
          text: 'Spa.', fullText: 'Spandrel Paint?', value: 'is_splander_paint', class: 'pl-1 pr-1', width: '2%', sortable: false,
        });
        headers.push({
          text: 'Pan.', fullText: 'Panels?', value: 'is_panels', class: 'pl-1 pr-1', width: '2%', sortable: false,
        });
        headers.push({
          text: 'Mat. Av.', fullText: 'Material Available?', value: 'is_material_available', class: 'pl-1 pr-1', width: '2%', sortable: false,
        });
        headers.push({
          text: 'Cut', fullText: 'Cut?', value: 'is_cut', class: 'pl-1 pr-1', width: '2%', sortable: false,
        });
        headers.push({
          text: 'As.', fullText: 'Assembled?', value: 'is_assemble', class: 'pl-1 pr-1', width: '2%', sortable: false,
        });
        headers.push({
          text: 'T&B', fullText: 'Tape and Bracket?', value: 'is_t_and_b', class: 'pl-1 pr-1', width: '2%', sortable: false,
        });
        headers.push({
          text: 'Glaz.', fullText: 'Glazed?', value: 'is_glaze', class: 'pl-1 pr-1', width: '2%', sortable: false,
        });
      }

      headers.push({
        text: 'Prod. Back', fullText: 'Production Backlog', value: 'production_backlog', class: 'pl-1 pr-1', width: '3%',
      });
      headers.push({
        text: 'Ship. Back', fullText: 'Shipping Backlog', value: 'shipping_backlog', class: 'pl-1 pr-1', width: '2%',
      });
      headers.push({
        text: 'Rec. Back', fullText: 'Received Backlog', value: 'received_backlog', class: 'pl-1 pr-1', width: '2%',
      });
      headers.push({
        text: 'Ret. Back', fullText: 'Returned Backlog', value: 'returned_backlog', class: 'pl-1 pr-1', width: '2%',
      });
      headers.push({
        text: 'SP PO#', fullText: 'SP PO#', value: 'sp_po_number', class: 'pl-1 pr-1', width: '2%', sortable: false,
      });
      headers.push({
        text: 'Gl. Note', fullText: 'Glass Notes', value: 'sp_note', class: 'pl-1 pr-1', width: '3%', sortable: false,
      });
      if (!this.isShowStages) {
        headers.push({
          text: 'Status', fullText: 'Status', value: 'sp_po_status', class: 'pl-1 pr-1', width: '5%', sortable: false, align: 'center',
        });
      }
      headers.push({
        text: 'S/U PO#', fullText: 'S/U PO#', value: 'su_po_number', class: 'pl-1 pr-1', width: '2%', sortable: false,
      });
      headers.push({
        text: 'Gl. Note', fullText: 'Glass Notes', value: 'su_note', class: 'pl-1 pr-1', width: '3%', sortable: false,
      });
      if (!this.isShowStages) {
        headers.push({
          text: 'Status', fullText: 'Status', value: 'su_po_status', class: 'pl-1 pr-1', width: '5%', sortable: false, align: 'center',
        });
      }
      headers.push({
        text: 'Glass Req. By', fullText: 'Glass Required By', value: 'glass_required_by', class: 'pl-1 pr-1', width: '2%',
      });
      headers.push({
        text: '', fullText: '', value: 'arrow_required_by', class: 'pl-0 pr-0', width: '1%', sortable: false,
      });
      headers.push({
        text: 'Plan. Ship Date', fullText: 'Planned Shi Date', value: 'planned_ship_date', class: 'pl-1 pr-0', width: '2%',
      });
      headers.push({
        text: '', fullText: '', value: 'arrow', class: 'pl-0 pr-0', width: '1%', sortable: false,
      });
      headers.push({
        text: 'Note', fullText: 'Note', value: 'last_note', class: 'pl-1 pr-1', width: '5%', sortable: false,
      });
      headers.push({
        text: '', value: 'actions', align: 'end', class: 'pl-0 pr-0', sortable: false,
      });
      return headers;
    },
  },
  watch: {
    inventoryClassId() {
      this.get();
    },
    filterJob(value) {
      this.storeState('job_id', value);
      this.get();
    },
    filterPo(value) {
      this.storeState('po_number', value);
      this.get();
    },
    filterStage(value) {
      this.storeState('stage', value);
      this.get();
    },
    filterGlassReqBy(value) {
      this.storeState('glass_required_by', value);
      this.get();
    },
    filterPlannedShipDate(value) {
      this.storeState('planned_ship_date', value);
      this.get();
    },
    filterPlannedShipDateEnd(value) {
      this.storeState('planned_ship_date_end', value);
      this.get();
    },
    filterLocationId() {
      this.get();
    },
    filterRecordType(value) {
      this.storeState('record_type', value);
      this.get();
    },
    filterJobSubClass() {
      this.get();
    },
    status() {
      this.get();
    },
    pagination() {
      this.get();
    },
    $route(to) {
      this.status = to.params.status;
      if (this.status === this.statusIdOnHand) {
        this.pagination.sortBy = ['po_number'];
      } else {
        this.pagination.sortBy = ['planned_ship_date'];
      }
    },
    search() {
      this.get();
    },
    locationTabFilter() {
      if (this.locationTabFilter === 0) {
        this.filterLocationId = null;
        this.get();
        return;
      }
      const location = this.tabLocations[this.locationTabFilter];
      if (!location) return;
      this.filterLocationId = location.id;
      this.get();
    },
    isShowBreakdown(value) {
      this.storeState('isShowBreakdown', value);
    },
    isShowStages(value) {
      this.storeState('isShowStages', value);
    },
    isGlassRelated(value) {
      this.storeState('isGlassRelated', value);
      this.get();
    },
  },
  created() {
    this.status = this.$route.params.status;
    if (!this.status) return;
    if (this.productionScheduleState) {
      if (this.productionScheduleState.pagination) { this.pagination = this.productionScheduleState.pagination; }
      if (this.productionScheduleState.job_id) { this.filterJob = this.productionScheduleState.job_id; }
      if (this.productionScheduleState.po_number) { this.filterPo = this.productionScheduleState.po_number; }
      if (this.productionScheduleState.stage) { this.filterStage = this.productionScheduleState.stage; }
      if (this.productionScheduleState.glass_required_by) { this.filterGlassReqBy = this.productionScheduleState.glass_required_by; }
      if (this.productionScheduleState.planned_ship_date) { this.filterPlannedShipDate = this.productionScheduleState.planned_ship_date; }
      if (this.productionScheduleState.planned_ship_date_end) { this.filterPlannedShipDateEnd = this.productionScheduleState.planned_ship_date_end; }
      if (this.productionScheduleState.record_type) { this.filterRecordType = this.productionScheduleState.record_type; }
      if (this.productionScheduleState.selectedTab) { this.tabChange(this.productionScheduleState.selectedTab); }
      if (this.productionScheduleState.isShowBreakdown !== null) { this.isShowBreakdown = this.productionScheduleState.isShowBreakdown; }
      if (this.productionScheduleState.isShowStages !== null) { this.isShowStages = this.productionScheduleState.isShowStages; }
      if (this.productionScheduleState.isGlassRelated !== null) { this.isGlassRelated = this.productionScheduleState.isGlassRelated; }
    }
    this.roles = this.user.roles.map((x) => x.id);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.windowWidth = window.innerWidth;
    this.getLocations();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    const tableWrapper = document.querySelector('.v-data-table__wrapper');
    tableWrapper.addEventListener('scroll', this.checkScroll);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    const tableWrapper = document.querySelector('.v-data-table__wrapper');
    tableWrapper.removeEventListener('scroll', this.checkScroll);
  },
  methods: {
    init() {
      this.getJobs();
      this.getGlassPos();
      this.getNoteStatuses();
    },

    storeState(property, value) {
      const state = this.productionScheduleState;
      state[property] = value;
      this.$store.commit('storeProductionScheduleState', state);
    },

    async get() {
      if (this.inventoryClassId === null) return;
      this.loading = true;
      this.items = [];
      this.totalItems = 0;
      const submitModel = {
        filters: {
          status_id: this.status,
          inventory_class_id: this.inventoryClassId,
          stage: this.filterStage,
          job_id: this.filterJob,
          date: this.filterDate,
          po_number: this.filterPo,
          glass_required_by: this.filterGlassReqBy,
          // planned_ship_date: this.filterPlannedShipDate,
          start_date: this.filterPlannedShipDate,
          end_date: this.filterPlannedShipDateEnd,
          location_id: this.filterLocationId,
          record_type: this.filterRecordType,
          job_sub_class_id: this.filterJobSubClass,
          is_glass_related: this.isGlassRelated === true ? 1 : 0,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.productionSchedule.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.init();
      if (!res || !res.data || res.data.length === 0) {
        this.items = [];
        this.allItems = [];
        this.totalItems = 0;
        return;
      }
      this.selectedItems = [];
      this.allItems = res.data;
      this.items = res.data.slice(0, 100);
      this.totalItems = res.total;
    },

    async getJobs() {
      this.loadingJobs = true;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId, is_manual: 0 },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        this.loadingJobs = false;
        return;
      }
      this.jobs = res;
      this.loadingJobs = false;
    },
    async getGlassPos() {
      this.loadingPo = true;
      const res = await this.$api.glass.get(`po-numbers/${this.inventoryClassId}/${null}`);
      if (!res) {
        this.poNumbers = [];
        this.loadingPo = false;
        return;
      }
      this.poNumbers.push('N/A');
      this.poNumbers.push(...res);
      this.loadingPo = false;
    },

    async getLocations() {
      this.locations = await this.$api.location.getList();

      const locationId99Vinyl = 3;
      const locationId141Adesso = 8;
      const locationId300Chrislea = 2;
      this.tabs.push(...this.locations.filter((x) => x.id === locationId99Vinyl || x.id === locationId141Adesso || x.id === locationId300Chrislea));
      this.tabs.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
        x.type = 'location';
      });
      this.tabs.push({
        id: 2, name: 'CORNER POST', color: '#E53935', type: 'sub-class',
      }, {
        id: 3, name: 'EURO DOOR', color: '#FDD835', type: 'sub-class',
      });
    },

    async getNoteStatuses() {
      this.noteStatuses = await this.$api.productionScheduleNoteStatus.get();
    },

    openFinishedGoodForm(item, statusId) {
      if (!this.roles.includes(this.userRoleIdAdmin) && !this.roles.includes(this.userRoleIdProductionAdmin) && !this.roles.includes(this.userRoleIdAssociate) && !this.roles.includes(this.userRoleIdSplanderPaintSupervisor) && !this.roles.includes(this.userRoleIdProductionSupervisor)) return;
      this.$refs.finishedGoodForm.open(item, statusId);
    },

    triggerStageChange(item, status) {
      if (!this.roles.includes(this.userRoleIdAdmin) && !this.roles.includes(this.userRoleIdProductionAdmin) && !this.roles.includes(this.userRoleIdAssociate) && !this.roles.includes(this.userRoleIdSplanderPaintSupervisor) && !this.roles.includes(this.userRoleIdProductionSupervisor)) return;

      if (status === this.materialAvailableId) {
        if (item.is_material_available) {
          this.cancelStage(item.id, this.materialAvailableId);
        } else {
          this.finishStage(item.id, this.materialAvailableId);
        }
      } else if (status === this.cutId) {
        if (item.is_cut) {
          this.cancelStage(item.id, this.cutId);
        } else {
          this.finishStage(item.id, this.cutId);
        }
      } else if (status === this.assembleId) {
        if (item.is_assemble) {
          this.cancelStage(item.id, this.assembleId);
        } else {
          this.finishStage(item.id, this.assembleId);
        }
      } else if (status === this.spandrelId) {
        if (item.is_splander_paint) {
          this.cancelStage(item.id, this.spandrelId);
        } else {
          this.finishStage(item.id, this.spandrelId);
        }
      } else if (status === this.panelsId) {
        if (item.is_panels) {
          this.cancelStage(item.id, this.panelsId);
        } else {
          this.finishStage(item.id, this.panelsId);
        }
      } else if (status === this.tAndBId) {
        if (item.is_t_and_b) {
          this.cancelStage(item.id, this.tAndBId);
        } else {
          this.finishStage(item.id, this.tAndBId);
        }
      } else if (status === this.ppStartedId) {
        if (item.is_pp_started) {
          this.finishStage(item.id, this.ppFinishedId);
        } else {
          this.finishStage(item.id, this.ppStartedId);
        }
      }
    },

    async finishStage(itemId, status) {
      let title = '';

      switch (status) {
        case this.materialAvailableId:
          title = 'Are you sure that you want to FINISH MATERIAL AVAILABLE?';
          break;
        case this.cutId:
          title = 'Are you sure that you want to FINISH CUTTING?';
          break;
        case this.assembleId:
          title = 'Are you sure that you want to FINISH ASSEMBLING?';
          break;
        case this.spandrelId:
          title = 'Are you sure that you want to FINISH SPANDREL PAINT?';
          break;
        case this.panelsId:
          title = 'Are you sure that you want to FINISH PANELS?';
          break;
        case this.tAndBId:
          title = 'Are you sure that you want to FINISH T & B?';
          break;
        case this.ppStartedId:
          title = 'Are you sure that you want to START PROJECTED PANELS production?';
          break;
        case this.ppFinishedId:
          title = 'Are you sure that you want to FINISH PROJECTED PANELS production?';
          break;
        default:
          break;
      }
      const confirm = await this.$refs.confirmForm.open({
        title,
      });
      if (!confirm) return;
      const submitModel = {
        id: itemId,
        status,
      };
      const res = await this.$api.productionSchedule.store(submitModel, 'stage');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.toggleStageUpdate(itemId, status);
    },

    async cancelStage(itemId, status) {
      let title = '';

      switch (status) {
        case this.materialAvailableId:
          title = 'Are you sure that you want to CANCEL MATERIAL AVAILABLE?';
          break;
        case this.cutId:
          title = 'Are you sure that you want to CANCEL CUTTING?';
          break;
        case this.assembleId:
          title = 'Are you sure that you want to CANCEL ASSEMBLING?';
          break;
        case this.spandrelId:
          title = 'Are you sure that you want to CANCEL SPANDREL PAINT?';
          break;
        case this.panelsId:
          title = 'Are you sure that you want to CANCEL PANELS?';
          break;
        case this.tAndBId:
          title = 'Are you sure that you want to CANCEL T & B?';
          break;
        case this.glazedId:
          title = 'Are you sure that you want to CANCEL GLAZE?';
          break;
        case this.ppStartedId:
          title = 'Are you sure that you want to CANCEL PROJECTED PANELS production?';
          break;
        case this.ppFinishedId:
          title = 'Are you sure that you want to CANCEL PROJECTED PANELS production?';
          break;
        default:
          break;
      }
      const confirm = await this.$refs.confirmForm.open({
        title,
      });
      if (!confirm) return;
      const submitModel = {
        id: itemId,
        status,
      };
      const res = await this.$api.productionSchedule.store(submitModel, 'cancel-stage');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.toggleStageUpdate(itemId, status);
    },

    async toggleStageUpdate(itemId, status) {
      const index = this.items.findIndex((x) => x.id === itemId);
      if (index === -1) return;
      switch (status) {
        case this.materialAvailableId:
          this.items[index].is_material_available = !this.items[index].is_material_available;
          break;
        case this.cutId:
          this.items[index].is_cut = !this.items[index].is_cut;
          break;
        case this.assembleId:
          this.items[index].is_assemble = !this.items[index].is_assemble;
          break;
        case this.spandrelId:
          this.items[index].is_splander_paint = !this.items[index].is_splander_paint;
          break;
        case this.panelsId:
          this.items[index].is_panels = !this.items[index].is_panels;
          break;
        case this.tAndBId:
          this.items[index].is_t_and_b = !this.items[index].is_t_and_b;
          break;
        case this.glazedId:
          this.items[index].is_glaze = !this.items[index].is_glaze;
          break;
        case this.ppStartedId:
          this.items[index].is_pp_started = !this.items[index].is_pp_started;
          break;
        case this.ppFinishedId:
          this.items[index].is_pp_finished = !this.items[index].is_pp_finished;
          break;
        default:
          break;
      }
      this.$set(this.items, index, this.items[index]);
    },

    async toggle(id, statusId, isAchive = false) {
      let title = '';
      if (isAchive) {
        title = 'ARCHIVE';
      } else if (statusId === this.statusIdInProgress) {
        title = 'COMPLETE';
      } else {
        title = 'RESTORE';
      }
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${title} this record?`,
      });
      if (!confirm) return;
      const res = await this.$api.productionSchedule.get(`toggle/${id}/${statusId}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.productionForm.open();
    },

    openManual() {
      this.$refs.manualForm.open();
    },

    openEdit(item) {
      this.$refs.productionForm.open(item.id);
    },

    openNotes(id) {
      this.$refs.noteForm.open(id);
    },

    openHistory(id) {
      this.$refs.historyForm.open(id);
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.productionSchedule.delete(id);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
      this.windowWidth = window.innerWidth;
    },

    updateOptions(newOptions) {
      this.pagination = newOptions;
      this.storeState('pagination', newOptions);
    },

    clearAllFilters() {
      this.filterJob = null;
      this.filterPo = null;
      this.filterStage = null;
      this.filterGlassReqBy = null;
      this.filterPlannedShipDate = null;
      this.filterLocationId = null;
      this.filterRecordType = null;
    },

    async runWebhook(documentType) {
      if (this.inventoryClassId === null) {
        this.$bus.$emit('showError', 'Please select an inventory class');
        return;
      }
      this.$bus.$emit('loading');
      const MODULE_PRODUCTION_SCHEDULE_ID = 20;
      let status = 'Current';
      if (this.status === 2) {
        status = 'Archived';
      }
      const submitModel = {
        status,
        inventory_class_id: this.inventoryClassId,
        module_id: MODULE_PRODUCTION_SCHEDULE_ID,
        location_id: this.filterLocationId ? this.filterLocationId : 0,
        sub_class: this.filterJobSubClass ? this.filterJobSubClass : 0,
        is_show_stages: this.isShowStages === true ? 'Yes' : 'No',
        is_show_glass_related: this.isGlassRelated === true ? 'Yes' : 'No',
      };
      const res = await this.$api.webhook.store(submitModel, 'run');
      if (!res) return;
      const pdf = await this.$api.report.download(res.id, documentType);
      if (!pdf) {
        this.$bus.$emit('showError', 'Report Failed');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${res.report_title}_${now}`;
      const file = new Blob([pdf], {
        type: documentType === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async updateGlassRequiredByDate(id, glassRequiredByDate) {
      const res = await this.$api.productionSchedule.update({ id, glass_required_by: glassRequiredByDate }, 'glass-required-by-date');
      if (!res) return;
      this.get();
    },

    openGlassRequiredByDateForm(item) {
      this.selectedItem = item;
      this.dialogShipDate = true;
    },

    openPlannedShipDateForm(item) {
      this.selectedItem = item;
      this.dialogPlannedDate = true;
    },

    openGlassRequiredByDateFormMultiple() {
      this.glassRequiredByDateMultiple = null;
      this.dialogGlassRequiredByDateMultiple = true;
    },

    async updateGlassRequiredByDateMultiple() {
      this.$bus.$emit('loading');
      const items = [];
      this.selectedItems.forEach((x) => {
        items.push(this.$api.productionSchedule.update({ id: x.id, glass_required_by: this.glassRequiredByDateMultiple }, 'glass-required-by-date'));
      });
      const itemsRes = await Promise.all(items);
      this.$bus.$emit('loading');
      if (itemsRes.includes(false)) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
    },

    async updatePlannedShipDate(id, plannedShipDate, plannedShipTime) {
      const res = await this.$api.productionSchedule.update({ id, planned_ship_date: plannedShipDate, planned_ship_time: plannedShipTime }, 'planned-ship-date');
      if (!res) return;
      const date = new Date(plannedShipDate);
      date.setDate(date.getDate() - 5);
      this.updateGlassRequiredByDate(id, date.toISOString().slice(0, 10));
    },

    openPlannedShipDateMultipleForm() {
      this.plannedShipDateMultiple = null;
      this.dialogPlannedShipDateMultiple = true;
    },

    async updatePlannedShipDateMultiple() {
      this.$bus.$emit('loading');
      const items = [];
      this.selectedItems.forEach((x) => {
        items.push(this.$api.productionSchedule.update({ id: x.id, planned_ship_date: this.plannedShipDateMultiple }, 'planned-ship-date'));
      });
      const itemsRes = await Promise.all(items);
      this.$bus.$emit('loading');
      if (itemsRes.includes(false)) {
        this.$bus.$emit('showError');
        return;
      }

      const date = new Date(this.plannedShipDateMultiple);
      date.setDate(date.getDate() - 5);

      const glassItems = [];
      this.selectedItems.forEach((x) => {
        glassItems.push(this.$api.productionSchedule.update({ id: x.id, glass_required_by: date.toISOString().slice(0, 10) }, 'glass-required-by-date'));
      });

      const glassItemsRes = await Promise.all(glassItems);

      if (glassItemsRes.includes(false)) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
    },

    async updateProductionBacklog() {
      if (!this.selectedProdBacklogItem) return;
      this.selectedProdBacklogItem.production_backlog = this.selectedProdBacklogItem.production_backlog || this.selectedProdBacklogItem.production_backlog === 0 ? this.selectedProdBacklogItem.production_backlog : null;
      const res = await this.$api.productionSchedule.update({ id: this.selectedProdBacklogItem.id, production_backlog: this.selectedProdBacklogItem.production_backlog }, 'production-backlog');
      if (!res) return;
      this.get();
      this.dialogProdBacklogSchedule = false;
    },

    async updateShipBacklog() {
      if (!this.selectedShipBacklogItem) return;
      this.selectedShipBacklogItem.shipping_backlog = this.selectedShipBacklogItem.shipping_backlog || this.selectedShipBacklogItem.shipping_backlog === 0 ? this.selectedShipBacklogItem.shipping_backlog : null;
      const res = await this.$api.productionSchedule.update({ id: this.selectedShipBacklogItem.id, shipping_backlog: this.selectedShipBacklogItem.shipping_backlog }, 'shipping-backlog');
      if (!res) return;
      this.get();
      this.dialogShipBacklogSchedule = false;
    },

    async updateSPGlassNote() {
      if (!this.selectedItem) return;
      if (this.selectedItem.sp_note_status_id !== this.noteStatusIdShort) {
        this.selectedItem.sp_note = null;
      }
      const res = await this.$api.productionSchedule.update({ id: this.selectedItem.id, sp_note_status_id: this.selectedItem.sp_note_status_id, sp_note: this.selectedItem.sp_note }, 'sp-glass-note');
      if (!res) return;
      const index = this.items.findIndex((x) => x.id === this.selectedItem.id);
      if (index === -1) return;
      this.items[index].sp_note = this.selectedItem.sp_note;
      this.items[index].sp_note_status_id = this.selectedItem.sp_note_status_id;
      const spNote = this.noteStatuses.find((x) => x.id === this.selectedItem.sp_note_status_id);
      this.items[index].sp_note_status = spNote;
      this.$set(this.items, index, this.items[index]);
      this.dialogSPGlassNote = false;
    },

    async updateSUGlassNote() {
      if (!this.selectedItem) return;
      if (this.selectedItem.su_note_status_id !== this.noteStatusIdShort) {
        this.selectedItem.su_note = null;
      }
      const res = await this.$api.productionSchedule.update({ id: this.selectedItem.id, su_note_status_id: this.selectedItem.su_note_status_id, su_note: this.selectedItem.su_note }, 'su-glass-note');
      if (!res) return;
      const index = this.items.findIndex((x) => x.id === this.selectedItem.id);
      if (index === -1) return;
      this.items[index].su_note = this.selectedItem.su_note;
      this.items[index].su_note_status_id = this.selectedItem.su_note_status_id;
      const suNote = this.noteStatuses.find((x) => x.id === this.selectedItem.su_note_status_id);
      this.items[index].su_note_status = suNote;
      this.$set(this.items, index, this.items[index]);
      this.dialogSUGlassNote = false;
    },

    openTruckForm(item) {
      this.$refs.approveForm.openProductionSchedule(item);
    },

    async archiveAll() {
      const confirm = await this.$refs.confirmForm.open({
        // eslint-disable-next-line eqeqeq
        title: `Are you sure that you want to ${this.status == 1 ? 'ARCHIVE' : 'RESTORE'} all selected records?`,
      });
      if (!confirm) return;
      const requests = [];
      this.selectedItems.forEach((x) => {
        requests.push(this.$api.productionSchedule.get(`toggle/${x.id}/${x.status_id}`));
      });

      const res = await Promise.all(requests);
      if (res.includes(false)) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    tabChange(item) {
      if (this.selectedTab === item) { this.selectedTab = null; this.filterJobSubClass = null; this.filterLocationId = null; this.get(); this.storeState('selectedTab', null); return; }
      this.selectedTab = item;
      if (item.type === 'sub-class') {
        this.filterJobSubClass = item.id;
        this.filterLocationId = null;
      } else if (item.type === 'location') {
        this.filterLocationId = item.id;
        const jobSubClass4800Windows = 1;
        const tab300Chrislea = 2;
        if (item.id === tab300Chrislea) {
          this.filterJobSubClass = jobSubClass4800Windows;
        } else { this.filterJobSubClass = null; }
      }
      this.storeState('selectedTab', item);
      this.get();
    },

    openMenuProdBacklog(id) {
      const index = this.items.findIndex((item) => item.id === id);
      if (index === -1) return;
      this.selectedProdBacklogItem = this.items[index];
      this.dialogProdBacklogSchedule = true;
    },

    openMenuSPGlassNote(id) {
      const index = this.items.findIndex((item) => item.id === id);
      if (index === -1) return;
      this.selectedItem = this.items[index];
      this.dialogSPGlassNote = true;
    },
    openMenuSUGlassNote(id) {
      const index = this.items.findIndex((item) => item.id === id);
      if (index === -1) return;
      this.selectedItem = this.items[index];
      this.dialogSUGlassNote = true;
    },

    openMenuShipBacklog(id) {
      const index = this.items.findIndex((item) => item.id === id);
      if (index === -1) return;
      this.selectedShipBacklogItem = this.items[index];
      this.dialogShipBacklogSchedule = true;
    },

    redirectTrucks(jobId) {
      this.$router.push({ name: 'TruckScheduleRequestView', params: { jobIdProp: jobId } });
    },

    openFinishedGoods() {
      this.$refs.finishedGoodGroupedForm.open();
    },

    async sendEmailPdf() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to send an email containing the latest Production Schedule reports?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.productionSchedule.get('send-email-pdf');
      this.$bus.$emit('loading');
      if (res) {
        this.$bus.$emit('showSuccess');
      } else {
        this.$bus.$emit('showError');
      }
    },

    openWindowBreakdown(item, typeId) {
      const data = {
        work_order: item.wo_number,
        project_name: item.project,
        reference: item.project_floor,
      };
      this.$refs.windowBreakdownItemsForm.open(data, typeId);
    },

    checkScroll(event) {
      const bottomOfTable = (event.target.scrollHeight < event.target.scrollTop + event.target.clientHeight + 5 && event.target.scrollHeight > event.target.scrollTop + event.target.clientHeight - 5) || event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
      if (bottomOfTable) {
        this.items = this.allItems.slice(0, this.items.length + 100);
      }
    },
  },
};
</script>

<style scoped>

#cellOverflow {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  min-width: 200px  !important;
  border-left: 1px solid #E0E0E0;
  background-color: #FAFAFA;

}

#cellOverflow.theme--dark {
  background-color: #323232;
}

span
{
  font-size: 12px !important;
}
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #323232;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th > span {
  color: #FFFFFF;
}

::v-deep .theme--light.v-divider {
    border-color: rgba(0,0,0,0.4) !important;
    border: 1px solid rgba(0,0,0,0.4) !important;
}

:v-deep .v-btn__content
{
  color: white !important;
}

.box
{
  border-radius: 4px;
   width: 25px;
    height: 25px;
}

.empty-box
{
  border: 1.5px solid #FFD84D;
  background-color: #FFFFFF;
}

.red-box
{
  border: 1.5px solid #F44336;
  background-color: #F44336;
}

.yellow-box
{
  border: 1.5px solid #FFD84D;
  background-color: #FFD84D;
}

.green-box
{
  border: 1.5px solid #4CAF50;
  background-color: #4CAF50;
}
</style>
