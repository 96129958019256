<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :max-width="1000"
    :is-submit-btn="!disabled"
    :submit-btn-text="'Submit'"
    :btn-loading="loading"
    persistent
  >
    <template slot="header">
      <span>{{disabled ? '' : 'Add'}} Glass {{isPatioDoor ? 'Specifications' : 'Items'}}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-row v-if="!isPatioDoor">
            <v-col
              class="pa-5 ma-3"
              :style="{ color: isDarkMode ? '' : '#000000', backgroundColor: isDarkMode ? '#676767' : '#E8F5E9', border: '2px solid rgba(0,0,0,0.2)', borderRadius: '4px'}"   
            >
              <v-row>
                <v-col class="pb-0 pt-0">
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">Glass Item Properties</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="glassItem.glass_thickness"
                    :items="glassThicknesses"
                    item-text="name"
                    item-value="name"
                    :disabled="item.glass_code_type_id === glassTypeIdSignle || item.glass_code_type_id === glassTypeIdSpandler"
                    :rules="[(v) => item.glass_code_type_id === glassTypeIdSignle || item.glass_code_type_id === glassTypeIdSpandler || (!!v || 'Glass Thickness is required')]"
                  >
                    <template slot="label">
                      Glass Thickness<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-text-field
                    v-model="glassItem.tag_number"
                    :rules="[(v) => !!v || 'Tag# is required']"
                  >
                    <template slot="label">
                      Tag#<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-text-field
                    v-model="glassItem.price"
                    :rules="[(v) => supplierId === supplierIdAWD || !!v || 'Price/SQFT is required']"
                    :disabled="supplierId === supplierIdAWD"
                    type="number"
                    @keydown.space.prevent
                  >
                    <template slot="label">
                      Price/SQFT<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  class="pb-0 pt-0"
                >
                  <v-text-field
                    v-model="glassItem.quantity"
                    type="number"
                    :rules="[(v) => !!v || 'Quantity is required']"
                    suffix="pcs"
                    @keydown.space.prevent
                  >
                    <template slot="label">
                      Quantity<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0 pt-0"
                >
                  <v-text-field
                    v-model="glassItem.width"
                    type="number"
                    :rules="[(v) => !!v || 'Width is required']"
                    suffix="inches"
                    @keydown.space.prevent
                  >
                    <template slot="label">
                      Width<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0 pt-0"
                >
                  <v-text-field
                    v-model="glassItem.height"
                    type="number"
                    :rules="[(v) => !!v || 'Height is required']"
                    suffix="inches"
                    @keydown.space.prevent
                  >
                    <template slot="label">
                      Height<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-text-field
                    v-model="glassItem.holes"
                  >
                    <template slot="label">
                      Note
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="mt-3"
            >
              <v-row>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="selectedGlassCodeTypeId"
                    :items="glassCodeTypes"
                    item-text="name"
                    item-value="id"
                    :disabled="disabled"
                    :rules="[(v) => !!v || 'Glass Code Type is required']"
                  >
                    <template slot="label">
                      Glass Code Type<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.glass_code_type_id"
                    :items="glassTypes"
                    item-text="name"
                    label="Glass Type"
                    item-value="id"
                    :disabled="isPatioDoor || disabled"
                    :rules="[(v) => !!v || 'Glass Type is required']"
                  >
                    <template slot="label">
                      Glass Type<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="isPatioDoor"
                  cols="auto"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="glassItem.glass_thickness"
                    :items="glassThicknesses"
                    item-text="name"
                    item-value="name"
                    :disabled="true"
                    :rules="[(v) => item.glass_code_type_id === glassTypeIdSignle || item.glass_code_type_id === glassTypeIdSpandler || (!!v || 'Glass Thickness is required')]"
                  >
                    <template slot="label">
                      Glass Thickness<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="item.glass_code_type_id && selectedGlassCodeTypeId !== glassCodeTypeIdManual"
                  cols="8"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="glassItem.g_code"
                    :items="glasses"
                    item-text="row"
                    item-value="row"
                    :loading="glassLoading"
                    :disabled="disabled || !selectedGlassCodeTypeId"
                    :rules="[(v) => !!v || 'G-Code is required']"
                    no-data-text="Select Glass Type first"
                    :filter="customFilter"
                  >
                    <template slot="label">
                      G-Code<RedAsterisk />
                    </template>
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #item="{ item }">
                      <v-row>
                        <v-col
                          cols="auto"
                          class="pr-1"
                        >
                          <span style="font-weight: bold">{{ item.row }}</span>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="pl-0 pr-0"
                        >
                          <span>-</span>
                        </v-col>
                        <v-col class="pl-1">
                          <span v-if="item.exterior_glass"><span style="font-weight: bold"> E: </span>
                            {{ item.exterior_glass }} {{ item.ext_heat_treatment }}
                          </span>
                          <span v-if="item.center_glass"><span style="font-weight: bold"> C: </span>
                            {{ item.center_glass }}
                          </span>
                          <span v-if="item.interior_glass"><span style="font-weight: bold"> I: </span>
                            {{ item.interior_glass }}
                          </span>
                          <span v-if="item.single_glass"><span style="font-weight: bold"> S: </span>

                            {{ item.single_glass }}
                          </span>
                          <span v-if="item.spandrel_paint"><span style="font-weight: bold"> SP: </span>
                            {{ item.spandrel_paint }}
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #selection="{ item }">
                      <v-row>
                        <v-col
                          cols="auto"
                          class="pr-1"
                        >
                          <span style="font-weight: bold">{{ item.row }}</span>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="pl-0 pr-0"
                        >
                          <span>-</span>
                        </v-col>
                        <v-col class="pl-1">
                          <span v-if="item.exterior_glass"><span style="font-weight: bold"> E: </span>
                            {{ item.exterior_glass }} {{ item.ext_heat_treatment }}
                          </span>
                          <span v-if="item.center_glass"><span style="font-weight: bold"> C: </span>
                            {{ item.center_glass }}
                          </span>
                          <span v-if="item.interior_glass"><span style="font-weight: bold"> I: </span>
                            {{ item.interior_glass }}
                          </span>
                          <span v-if="item.single_glass"><span style="font-weight: bold"> S: </span>

                            {{ item.single_glass }}
                          </span>
                          <span v-if="item.spandrel_paint"><span style="font-weight: bold"> SP: </span>
                            {{ item.spandrel_paint }}
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="item.glass_code_type_id">
            <v-col>
              <v-row v-if="item.glass_code_type_id === glassTypeIdSealed">
                <v-col class="pb-0 pt-0">
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">Exterior Glass</span><RedAsterisk />
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id === glassTypeIdSealed">
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.exterior_glass"
                    :items="exteriorGlasses"
                    item-text="name"
                    item-value="code"
                    hide-details
                    class="mt-0 pt-0"
                    placeholder="Exterior Glass"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                    :rules="[(v) => !!v || 'Exterior Glass is required']"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.ext_heat_treatment"
                    :items="extHeatTreatments"
                    item-text="name"
                    item-value="code"
                    hide-details
                    class="mt-0 pt-0"
                    label="Ext Heat Treatment"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id === glassTypeIdSealed">
                <v-col class="pb-0 pt-1">
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">Central Glass</span>
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id === glassTypeIdSealed">
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.center_glass"
                    :items="centerGlasses"
                    item-text="name"
                    item-value="code"
                    hide-details
                    class="mt-0 pt-0"
                    placeholder="Center Glass"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.cen_heat_treatment"
                    :items="cenHeatTreatments"
                    item-text="name"
                    item-value="code"
                    hide-details
                    class="mt-0 pt-0"
                    label="Cen Heat Treatment"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id === glassTypeIdSealed">
                <v-col class="pb-0 pt-1">
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">Interior Glass</span><RedAsterisk />
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id === glassTypeIdSealed">
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.interior_glass"
                    :items="interiorGlasses"
                    item-text="name"
                    item-value="code"
                    hide-details
                    class="mt-0 pt-0"
                    placeholder="Interior Glass"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                    :rules="[(v) => !!v || 'Interior Glass is required']"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.int_heat_treatment"
                    :items="intHeatTreatments"
                    item-text="name"
                    item-value="code"
                    hide-details
                    class="mt-0 pt-0"
                    label="Int Heat Treatment"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id === glassTypeIdSignle || item.glass_code_type_id === glassTypeIdSpandler">
                <v-col class="pb-0 pt-1">
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">Single Glass</span><RedAsterisk />
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id === glassTypeIdSignle || item.glass_code_type_id === glassTypeIdSpandler">
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.single_glass"
                    :items="singleGlasses"
                    item-text="name"
                    item-value="code"
                    hide-details
                    class="mt-0 pt-0"
                    placeholder="Single Glass"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                    :rules="[(v) => !!v || 'Single Glass is required']"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.sgl_heat_treatment"
                    :items="sglHeatTreatments"
                    item-text="name"
                    item-value="code"
                    hide-details
                    class="mt-0 pt-0"
                    label="Sgl Heat Treatment"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id != null">
                <v-col class="pb-0 pt-1">
                  <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '16px' }">Glass Properties</span>
                </v-col>
              </v-row>
              <v-row v-if="item.glass_code_type_id != null">
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.part_number"
                    :items="partNumbers"
                    label="Part Number"
                    hide-details
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
                <v-col
                  v-if="item.glass_code_type_id === glassTypeIdSealed"
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.awd_gas"
                    :items="awdGlasses"
                    item-text="name"
                    item-value="code"
                    hide-details
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                    :rules="isPatioDoor ? [(v) => !!v || 'AWD Gas is required'] : []"
                  >
                    <template slot="label">
                      AWD Gas<RedAsterisk v-if="isPatioDoor" />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="item.glass_code_type_id === glassTypeIdSealed"
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.awd_spacer"
                    :items="awdSpacers"
                    item-text="name"
                    item-value="code"
                    hide-details
                    label="AWD Spacer"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
                <v-col
                  v-if="item.glass_code_type_id === glassTypeIdSealed"
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.le_surface"
                    :items="leSurfaces"
                    item-text="name"
                    item-value="code"
                    hide-details
                    label="LE Surface"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
                <v-col
                  v-if="item.glass_code_type_id === glassTypeIdSealed"
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.stc"
                    :items="stcs"
                    label="STC"
                    item-text="name"
                    item-value="code"
                    hide-details
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.double_triple"
                    :items="doubleTriples"
                    item-text="name"
                    item-value="code"
                    hide-details
                    label="Double/Triple"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                    :rules="[(v) => !!v || 'Double/Triple is required']"
                  >
                    <template slot="label">
                      Double/Triple<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="item.glass_code_type_id === glassTypeIdSealed"
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.fritted"
                    :items="fritteds"
                    item-text="name"
                    item-value="code"
                    hide-details
                    label="Fritted"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                  />
                </v-col>
                <v-col
                  v-if="item.glass_code_type_id === glassTypeIdSealed"
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.glass_air_space_id"
                    :items="glassAirSpaces"
                    item-text="name"
                    item-value="id"
                    hide-details
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                    :rules="isPatioDoor ? [(v) => !!v || 'Air Space is required'] : []"
                  >
                    <template slot="label">
                      Air Space<RedAsterisk v-if="isPatioDoor" />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="item.glass_code_type_id === glassTypeIdSpandler"
                  cols="4"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="item.spandrel_paint"
                    :items="spandlerPaints"
                    item-text="name"
                    item-value="code"
                    hide-details
                    label="Spandrel Paint"
                    selected-glass-code-type-id
                    :disabled="selectedGlassCodeTypeId !== glassCodeTypeIdManual || disabled"
                    :rules="[(v) => !!v || 'Spandrel Paint is required']"
                  >
                    <template slot="label">
                      Spandrel Paint <RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col />
          </v-row>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: null,
    oldItem: null,
    itemDto: {
      id: 0,
      row: '',
      col: null,
      part_number: '',
      part_number_suffix: '0000',
      awd_gas: '',
      awd_spacer: '',
      cen_heat_treatment: '',
      center_glass: '',
      double_triple: '',
      ext_heat_treatment: '',
      exterior_glass: '',
      fritted: '',
      glass_code: '',
      int_heat_treatment: '',
      interior_glass: '',
      le_surface: '',
      middle_glass: '',
      sgl_heat_treatment: '',
      single_glass: '',
      spandrel_paint: '',
      stc: '',
      vwd_code: '',
      is_activated: 1,
      glass_code_type_id: null,
      glass_air_space_id: null,
    },
    glassItem: null,
    glassItemDto: {
      glass_thickness: null,
      tag_number: null,
      quantity: null,
      g_code: null,
      width: null,
      height: null,
      temp_id: null,
      glassItem: null,
      price: null,
      holes: null,
    },
    glasses: [],
    glassLoading: false,
    awdGlasses: [],
    awdSpacers: [],
    cenHeatTreatments: [],
    centerGlasses: [],
    doubleTriples: [],
    extHeatTreatments: [],
    exteriorGlasses: [],
    fritteds: [],
    glassCodes: [],
    intHeatTreatments: [],
    interiorGlasses: [],
    leSurfaces: [],
    middleGlasses: [],
    sglHeatTreatments: [],
    singleGlasses: [],
    spandlerPaints: [],
    stcs: [],
    glassTypes: [],
    glassThicknesses: [],
    glassAirSpaces: [],
    glassCodeTypes: [
      { id: 1, name: 'G-MAIN - Manual Entry' },
      { id: 2, name: 'G-CODE - Glass Code' },
    ],
    glassCodeTypeIdManual: 1,
    glassCodeTypeIdAuto: 2,
    selectedGlassCodeTypeId: 2,
    glassTypeIdSpandler: 1,
    glassTypeIdSealed: 2,
    glassTypeIdSignle: 3,
    glassThicknessIdOneInchOverall: 1,
    glassSpecificationIdAWDArgon: 57,
    partNumbers: ['903-AWD', '900-AWD'],
    fractions: {
      0.0625: '1/16',
      0.125: '1/8',
      0.1875: '3/16',
      0.25: '1/4',
      0.3125: '5/16',
      0.375: '3/8',
      0.4375: '7/16',
      0.5: '1/2',
      0.5625: '9/16',
      0.625: '5/8',
      0.6875: '11/16',
      0.75: '3/4',
      0.8125: '13/16',
      0.875: '7/8',
      0.9375: '15/16',
    },
    supplierId: null,
    inventoryClassId: null,
    supplierIdAWD: 1,
    glassPaintCodes: [],
    loading: false,
    isInit: false,
    isPatioDoor: false,

  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    })
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.glass_code_type_id': function (val) {
      this.getAll();
      if (this.isInit) return;

      const glassCodeTypeId = this.item.glass_code_type_id;
      const { id } = this.item;
      const { row } = this.item;
      this.item = { ...this.itemDto };
      this.item.glass_code_type_id = glassCodeTypeId;

      if (this.selectedGlassCodeTypeId === this.glassCodeTypeIdManual) {
        this.item.id = id;
        this.item.row = row;
        const partNumberId900AWD = 1;
        this.item.part_number = this.partNumbers[partNumberId900AWD];
        if (val === this.glassTypeIdSignle) {
          const item = this.singleGlasses.find((x) => x.code === '6PP');
          this.item.single_glass = item ? item.code : '';
        } else if (val === this.glassTypeIdSealed) {
          console.log('here');
          this.item.awd_gas = this.awdGlasses ? this.awdGlasses[0].code : '';
          console.log('this.item.awd_gas', this.item.awd_gas);
          if (!this.item.awd_spacer) {
            const awdSpacer = this.awdSpacers.find((x) => x.code === 'SP-SSB');
            this.item.awd_spacer = awdSpacer ? awdSpacer.code : '';
          }
        }
      }

      if (val === this.glassTypeIdSignle) {
        const glassThickness = this.glassThicknesses.find((x) => x.name === 'SINGLE GLASS');
        this.glassItem.glass_thickness = glassThickness ? glassThickness.name : null;
      } else if (val === this.glassTypeIdSpandler) {
        const glassThickness = this.glassThicknesses.find((x) => x.name === 'SPG 6MM');
        this.glassItem.glass_thickness = glassThickness ? glassThickness.name : null;
      }
    },
    // eslint-disable-next-line func-names
    'glassItem.g_code': function (val) {
      if (!val || this.isInit) return;
      this.get(val);
    },
    selectedGlassCodeTypeId() {
      if (this.isInit) return;
      this.item = { ...this.itemDto };
      this.glassItem.g_code = null;
      if (this.isPatioDoor) {
        this.item.glass_code_type_id = this.glassTypeIdSealed;
      }
    },
  },
  created() {
    this.item = { ...this.itemDto };
    this.glassItem = { ...this.glassItemDto };
  },
  methods: {

    async init() {
      this.getSpecifications();
      this.getGlassTypes();
      this.getGlassThickness();
      this.getGlassAirSpaces();
    },

    async get(gCode) {
      const submitModel = {
        filters: {
          g_code: gCode,
        },
      };
      const res = await this.$api.glass.read(submitModel);
      if (!res || res.length < 1 || !res[0]) {
        this.$nextTick(async () => {
          this.isInit = false;
        });
        return;
      }
      const firstIndex = 0;
      this.item = res[firstIndex];
      if (this.isPatioDoor) {
        this.item.glass_code_type_id = this.glassTypeIdSealed;
      }
      this.oldItem = { ...res[firstIndex] };
      if (this.item.is_manual === 1) this.selectedGlassCodeTypeId = this.glassCodeTypeIdManual;
      else this.selectedGlassCodeTypeId = this.glassCodeTypeIdAuto;
      this.$nextTick(async () => {
        this.isInit = false;
      });
    },

    async getAll() {
      this.glassLoading = true;
      const submitModel = {
        filters: {
          glass_type_id: this.item.glass_code_type_id,
          is_manual: 0,
        },
      };
      const res = await this.$api.glass.readGlassPaintCode(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) return;
      this.glasses = res;
      this.glassLoading = false;
    },

    async getGlassTypes() {
      this.glassTypes = await this.$api.glass.get('glass-code-types');
    },

    async getGlassThickness() {
      this.glassThicknesses = await this.$api.glass.get('glass-thicknesses');
      if(this.isPatioDoor) {
        const glassThicknessOneInch = this.glassThicknesses.find((x) => x.id === this.glassThicknessIdOneInchOverall);
        this.glassItem.glass_thickness = glassThicknessOneInch.name;
      }
    },

    async getGlassAirSpaces() {
      this.glassAirSpaces = await this.$api.glass.get('glass-air-spaces');
    },

    async submit() {
      if (!this.isPatioDoor && (!this.isDecimalInFractions(this.glassItem.width) || !this.isDecimalInFractions(this.glassItem.height))) {
        this.$bus.$emit('showError', 'Incorrect Width or Height value');
        return false;
      }

      this.loading = true;

      if (this.selectedGlassCodeTypeId === this.glassCodeTypeIdManual) {
        let res;
        if (this.item.id && this.isPatioDoor) {
          res = await this.$api.glass.update(this.item);
        }
        else if (this.item.id && this.areFieldsEqual()) {
          res = await this.$api.glass.update(this.item);
        } else {
          const manualCode = await this.$api.glass.get('generate-manual-g-code');

          if (!manualCode) {
            this.$bus.$emit('showError', 'Error while generating manual code');
            this.loading = false;
            return false;
          }
          this.glassItem.g_code = manualCode;
          this.item.row = manualCode;
          this.item.is_manual = 1;
          res = await this.$api.glass.store(this.item);
        }

        if (!res) {
          this.$bus.$emit('showError');
          this.loading = false;
          return false;
        }
      }

      this.glassItem.glass = { ...this.item };

      const glassType = this.glassTypes.find((x) => x.id === this.item.glass_code_type_id);

      if (!glassType) {
        this.$bus.$emit('showError');
        this.loading = false;
        return false;
      }

      this.glassItem.glass_type = glassType.name;

      this.$emit('success-po', { ...this.glassItem });
      this.loading = false;
      return true;
    },

    areFieldsEqual() {
      // Get the keys (fields) of both objects
      const keys1 = Object.keys(this.item);
      const keys2 = Object.keys(this.oldItem);

      // Check if the number of fields is the same
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Iterate through the keys and compare values
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys1) {
        // Check if the current key exists in both objects
        if (!keys2.includes(key)) {
          return false;
        }

        // Compare the values of the corresponding keys
        if (this.item[key] !== this.oldItem[key]) {
          return false;
        }
      }

      // If all fields have the same values, return true
      return true;
    },

    async open(glassItem, supplierId = null, isPatioDoor = false) {
      this.isInit = true;
      this.$refs.dialogBox.open();
      this.supplierId = supplierId;
      this.isPatioDoor = isPatioDoor;
      if (glassItem && glassItem.g_code) {
        this.glassItem = { ...glassItem };
        this.get(glassItem.g_code);
      } else {
        this.item = { ...this.itemDto };
        this.glassItem = { ...this.glassItemDto };
        this.glassItem.temp_id = Date.now();
        this.$nextTick(async () => {
          this.isInit = false;
        });
      }
      await this.init();
      if (this.isPatioDoor) {
        this.item.glass_code_type_id = this.glassTypeIdSealed;
      }
    },

    isDecimalInFractions(decimalNumber) {
      const decimalPart = decimalNumber - Math.floor(decimalNumber);

      if (decimalPart === 0) return true;

      // Check if the decimalPart exists in the keys of the fractions object
      return Object.keys(this.fractions).includes(decimalPart.toString());
    },

    async getSpecifications() {
      const classIdAWDGlass = 1;
      const classIdAWDSpacer = 2;
      const classIdCenHeatTreatment = 3;
      const classIdCenterGlass = 4;
      const classIdDoubleTriple = 5;
      const classIdExtHeatTreatment = 6;
      const classIdExteriorGlass = 7;
      const classIdFritted = 8;
      const classIdGlassCode = 9;
      const classIdIntHeatTreatment = 10;
      const classIdInteriorGlass = 11;
      const classIdLESurface = 12;
      const classIdMiddleGlass = 13;
      const classIdSglHeatTreatment = 14;
      const classIdSingleGlass = 15;
      const classIdSpandlerPaint = 16;
      const classIdSTC = 17;

      this.awdGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdAWDGlass } });
      this.awdSpacers = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdAWDSpacer } });
      this.cenHeatTreatments = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdCenHeatTreatment } });
      this.centerGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdCenterGlass } });
      this.doubleTriples = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdDoubleTriple } });
      this.extHeatTreatments = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdExtHeatTreatment } });
      this.exteriorGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdExteriorGlass } });
      this.fritteds = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdFritted } });
      this.glassCodes = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdGlassCode } });
      this.intHeatTreatments = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdIntHeatTreatment } });
      this.interiorGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdInteriorGlass } });
      this.leSurfaces = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdLESurface } });
      this.middleGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdMiddleGlass } });
      this.sglHeatTreatments = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdSglHeatTreatment } });
      this.singleGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdSingleGlass } });
      this.spandlerPaints = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdSpandlerPaint } });
      this.stcs = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdSTC } });
    },

    customFilter(item, queryText) {
      let text = '';
      if (item.row) {
        text += item.row;
      }
      if (item.exterior_glass) {
        text += item.exterior_glass;
      }
      if (item.ext_heat_treatment) {
        text += item.ext_heat_treatment;
      }
      if (item.center_glass) {
        text += item.center_glass;
      }
      if (item.interior_glass) {
        text += item.interior_glass;
      }
      if (item.single_glass) {
        text += item.single_glass;
      }
      if (item.spandrel_paint) {
        text += item.spandrel_paint;
      }

      const searchText = queryText.toLowerCase();

      // Return true if the item's text contains the queryText
      return text.toLowerCase().includes(searchText);
    },
  },
};
</script>
