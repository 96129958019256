var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"full-screen":true,"submit-btn-text":_vm.submitBtnText(),"is-submit-btn":_vm.isSubmitBtn(),"persistent":true}},[_c('template',{slot:"header"},[_c('span',{staticClass:"mr-2"},[_vm._v("Scheduled Delivery"+_vm._s(this.item && this.item.title ? (": " + (this.item.title)) : ''))]),_c('v-chip',{attrs:{"small":"","color":this.item && this.item.status ? this.item.status.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(this.item && this.item.status ? this.item.status.name : '')+" ")])],1),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.item.requests,"hide-default-footer":"","items-per-page":-1,"fixed-header":"","dense":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [(header.fullText)?_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.fullText))])]):_c('span',{key:h.value},[_vm._v(_vm._s(h.text))])]}}}),{key:"item.class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])]}},{key:"item.truck_types",fn:function(ref){
var item = ref.item;
return [(item.truck_types && item.truck_types.length > 1)?_c('v-row',[_c('v-col',{staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("TRUCK TYPES")])]}}],null,true)},[_c('v-row',[_c('v-col',{staticClass:"text-center pt-6 pb-6"},_vm._l((item.truck_types),function(truck_type){return _c('v-row',{key:truck_type.id},[_c('v-col',{staticClass:"pr-1 pb-1 pt-1",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":truck_type ? truck_type.color : 'white'}},[_vm._v(" "+_vm._s(truck_type ? truck_type.name : '')+" ")])],1)],1)}),1)],1)],1)],1)],1):_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item.truck_types[0]
                        ? item.truck_types[0].color
                        : 'white'}},[_vm._v(" "+_vm._s(item.truck_types[0] ? item.truck_types[0].name : '')+" ")])]}},{key:"item.job.full_name",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.job.code ? ("" + (item.job.code)) : ''))]),_vm._v(" - "+_vm._s(item.job.name ? item.job.name : item.project.name ? item.project.name : '')+" "),_c('span',{staticStyle:{"color":"#1976d2"}},[_vm._v(_vm._s(item.job.reference ? ("; " + (item.job.reference)) : ''))])]}},{key:"item.status.name",fn:function(ref){
                        var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.status ? item.status.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.status ? item.status.name : '')+" ")])]}},{key:"item.windows",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.current_finished_goods_count) + "/" + (item.total_finished_goods_count)))+" ")]}},{key:"item.created_by_user",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_by_user && item.created_by_user.first_name && item.created_by_user.last_name ? ((item.created_by_user.first_name) + " " + (item.created_by_user.last_name)) : '')+" ")]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(item.bill_of_ladings.length > 0)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"success","outlined":"","small":""},on:{"click":function($event){return _vm.openBillOfLadingList(item)}}},[_vm._v(" BOL ")]):_vm._e(),(
                      (_vm.isDeliveriesManager || _vm.isAdmin) &&
                      !_vm.truckRequestHasBillOfLadings(item)
                    )?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1 mr-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" REMOVE ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-spacer'),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0"},[_c('span',{style:({ color: '#000000', fontSize: '18px' })},[_vm._v("Notes")])]),(_vm.item.id)?_c('v-col',{staticClass:"text-end"},[_c('v-btn',{attrs:{"color":"orange","outlined":""},on:{"click":_vm.openNoteForm}},[_vm._v(" SEE ALL NOTES ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-textarea',{attrs:{"disabled":_vm.item.notes && _vm.item.notes.length > 0,"outlined":"","rows":"3"},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1)],1)],1)],1)],1)],1),_c('truck-schedule-bill-of-lading-list-form',{ref:"billOfLadingList"}),_c('note-form',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"noteForm"}),_c('confirm-form',{ref:"confirmForm"})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }