<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="itemId ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ itemId ? 'Update' : 'Create' }} Shelf</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.location_id"
            :items="locations"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Location is required']"
          >
            <template slot="label">
              Location<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="item.name"
            :rules="[(v) => !!v || 'Shelf Name is required']"
          >
            <template slot="label">
              Shelf Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <v-checkbox
            v-model="item.is_apply_finished_goods"
            class="mt-0 mb-0"
            label="Apply Finished Goods (Display in Production QR App)"
            :true-value="1"
            :false-value="0"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    item: null,
    itemId: 0,
    itemDto: {
      id: 0,
      name: '',
      location_id: 0,
      is_apply_finished_goods: 0,
    },
    locations: [],
  }),
  created() {
    this.item = { ...this.itemDto };
    this.getLocations();
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.shelf.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getLocations() {
      this.locations = await this.$api.location.get();
    },

    async submit() {
      if (!this.item) return false;
      const userId = this.$store.getters.user.id;
      if (userId) this.item.created_by = userId;

      let res;
      if (this.itemId) {
        res = await this.$api.shelf.update(this.item);
      } else {
        res = await this.$api.shelf.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(id) {
      this.itemId = id;
      if (id) {
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },
  },
};
</script>
