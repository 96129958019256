var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"is-submit-btn":false,"max-width":480,"persistent":true}},[_c('template',{slot:"header"},[_c('span',[_vm._v("Schedule Truck Request "+_vm._s(_vm.item && _vm.item.id ? _vm.item.id : ''))])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-date-picker',{attrs:{"full-width":"","events":_vm.events,"min":_vm.minScheduledDeliveryDate},on:{"change":function($event){return _vm.getScheduledDeliveries(_vm.selectedDate)}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pb-0 pl-2 pr-2",attrs:{"cols":"11"}},[_c('v-autocomplete',{attrs:{"items":_vm.scheduledDeliveries,"item-text":"title","item-value":"id","label":"Add To Scheduled Delivery","clearable":"","disabled":!_vm.selectedDate,"loading":_vm.loadingScheduledDeliveries,"hide-details":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer","text-transform":"uppercase"},attrs:{"small":"","text-color":"white","color":item ? _vm.getInventoryClassColor(item) : 'white'}},[_vm._v(" "+_vm._s(item ? _vm.getInventoryClassName(item) : '')+" ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer","text-transform":"uppercase"},attrs:{"small":"","text-color":"white","color":item ? _vm.getInventoryClassColor(item) : 'white'}},[_vm._v(" "+_vm._s(item ? _vm.getInventoryClassName(item) : '')+" ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":"","small":"","disabled":!_vm.scheduledDeliveryId || !_vm.selectedDate},on:{"click":_vm.addToScheduledDelivery}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(" Click to Add to Scheduled Delivery ")])])]},proxy:true}]),model:{value:(_vm.scheduledDeliveryId),callback:function ($$v) {_vm.scheduledDeliveryId=$$v},expression:"scheduledDeliveryId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0 text-center"},[_vm._v(" OR ")])],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pl-2 pr-2",attrs:{"cols":"11"}},[_c('v-btn',{attrs:{"color":"primary","block":"","outlined":"","disabled":!_vm.selectedDate},on:{"click":_vm.submitNewScheduledDelivery}},[_vm._v(" + Schedule New Delivery ")])],1)],1)],1),_c('confirm-form',{ref:"confirmForm"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }