<template>
  <div>
    <v-row>
      <v-spacer />
      <v-col class="text-end">
        <v-btn color="primary" outlined @click="openGlassPoItemForm">
          <v-icon small class="mr-1"> fas fa-plus </v-icon>
          New Glass Item
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card elevation="0" outlined>
          <v-data-table
            :headers="headers"
            :items="glassItems"
            hide-default-footer
            :items-per-page="-1"
            dense
            :expanded.sync="expandedItems"
          >
            <template #[`item.glass_thickness`]="{ item }">
              <span style="font-weight: bold">{{ item.glass_thickness }}</span>
            </template>
            <template #[`item.glass_type`]="{ item }">
              <span style="font-weight: bold">{{ item.glass_type }}</span>
            </template>
            <template #[`item.g_code`]="{ item }">
              <span style="font-weight: bold">{{ item.g_code }}</span>
            </template>
            <template #[`item.quantity`]="{ item }">
              {{ item.quantity }}
            </template>
            <template #[`item.width`]="{ item }">
              <span>{{
                item.width
                  ? uomSwitch
                    ? convertToFraction(item.width)
                    : item.width
                  : '-'
              }}</span>
            </template>
            <template #[`item.height`]="{ item }">
              <span>{{
                item.height
                  ? uomSwitch
                    ? convertToFraction(item.height)
                    : item.height
                  : '-'
              }}</span>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                v-if="item.glass_id"
                small
                color="orange"
                text-color="white"
              >
                ACTIVE
              </v-chip>
              <v-chip v-else small color="success" text-color="white">
                READY FOR IMPORT
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="editGlassItem(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteGlassItem(item)"
              >
                DELETE
              </v-btn>
            </template>
            <template #expanded-item="{ item }">
              <td v-if="item.glass" :colspan="12" class="pl-0 pr-0">
                <v-container
                  fluid
                  :style="{
                    backgroundColor: isDarkMode
                      ? '#556B2F !important'
                      : '#E8F5E9 !important',
                  }"
                >
                  <v-row>
                    <v-col
                      v-if="item.glass.part_number"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Part Number:</span
                      >
                      <span>{{ item.glass.part_number }}</span>
                    </v-col>
                    <v-col
                      v-if="item.glass.awd_gas"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >AWD Gas:</span
                      >
                      <span
                        >{{ item.glass.awd_gas }} -
                        {{ getGlassDescription(item.glass.awd_gas) }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.glass_air_space_id"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Air Space:</span
                      >
                      <span
                        >{{
                          getGlassAirSpaceName(item.glass.glass_air_space_id)
                        }}
                        -
                      </span>
                    </v-col>
                    <v-col
                      v-if="item.glass.awd_spacer"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >AWD Spacer:</span
                      >
                      <span
                        >{{ item.glass.awd_spacer }} -
                        {{ getGlassDescription(item.glass.awd_spacer) }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.cen_heat_treatment"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Cen Heat Treatment:</span
                      >
                      <span
                        >{{ item.glass.cen_heat_treatment }} -
                        {{
                          getGlassDescription(item.glass.cen_heat_treatment)
                        }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.center_glass"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Center Glass:</span
                      >
                      <span
                        >{{ item.glass.center_glass }} -
                        {{ getGlassDescription(item.glass.center_glass) }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.double_triple"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Double/Triple:</span
                      >
                      <span
                        >{{ item.glass.double_triple }} -
                        {{
                          getGlassDescription(item.glass.double_triple)
                        }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.ext_heat_treatment"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Ext Heat Treatment:</span
                      >
                      <span
                        >{{ item.glass.ext_heat_treatment }} -
                        {{
                          getGlassDescription(item.glass.ext_heat_treatment)
                        }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.exterior_glass"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Exterior Glass:</span
                      >
                      <span
                        >{{ item.glass.exterior_glass }} -
                        {{
                          getGlassDescription(item.glass.exterior_glass)
                        }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.fritted"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Fritted:</span
                      >
                      <span
                        >{{ item.glass.fritted }} -
                        {{ getGlassDescription(item.glass.fritted) }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.int_heat_treatment"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Int Heat Treatment:</span
                      >
                      <span
                        >{{ item.glass.int_heat_treatment }} -
                        {{
                          getGlassDescription(item.glass.int_heat_treatment)
                        }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.interior_glass"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Interior Glass:</span
                      >
                      <span
                        >{{ item.glass.interior_glass }} -
                        {{
                          getGlassDescription(item.glass.interior_glass)
                        }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.le_surface"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >LE Surface:</span
                      >
                      <span
                        >{{ item.glass.le_surface }} -
                        {{ getGlassDescription(item.glass.le_surface) }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.middle_glass"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Middle Glass:</span
                      >
                      <span
                        >{{ item.glass.middle_glass }} -
                        {{ getGlassDescription(item.glass.middle_glass) }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.sgl_heat_treatment"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Sgl Heat Treatment:</span
                      >
                      <span
                        >{{ item.glass.sgl_heat_treatment }} -
                        {{
                          getGlassDescription(item.glass.sgl_heat_treatment)
                        }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.single_glass"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Single Glass:</span
                      >
                      <span
                        >{{ item.glass.single_glass }} -
                        {{ getGlassDescription(item.glass.single_glass) }}</span
                      >
                    </v-col>
                    <v-col
                      v-if="item.glass.spandrel_paint"
                      class="pr-0 pl-2"
                      cols="auto"
                    >
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >Spandrel Paint:</span
                      >
                      <span
                        >{{ item.glass.spandrel_paint }} -
                        {{
                          getGlassDescription(item.glass.spandrel_paint)
                        }}</span
                      >
                    </v-col>
                    <v-col v-if="item.glass.stc" class="pr-0 pl-2" cols="auto">
                      <span
                        :style="{
                          color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)',
                        }"
                        >STC:</span
                      >
                      <span
                        >{{ item.glass.stc }} -
                        {{ getGlassDescription(item.glass.stc) }}</span
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <glass-p-o-item-form
      ref="glassPoItemForm"
      @success-po="glassPoItemSuccess"
    />
    <confirm-form ref="confirmForm" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GlassPOItemForm from '@/components/forms/glasses/GlassPOItemForm';

export default {
  components: {
    GlassPOItemForm,
  },
  props: {
    glassItems: {
      type: Array,
      default: () => [],
    },
    poItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    // glassItems: [],
    headers: [
      { text: 'G Code', value: 'g_code' },
      { text: 'Glass Type', value: 'glass_type' },
      { text: 'Glass Thickness', value: 'glass_thickness' },
      { text: 'Actions', value: 'actions', align: 'end', sortable: false },
    ],
    expandedItems: [],
    glassThicknesses: [],
    glassTypes: [],
    glassSpecifications: [],
    glassAirSpaces: [],

    glassTypeIdSpandler: 1,
    glassTypeIdSealed: 2,
    glassTypeIdSingle: 3,

    glassPaintCodesLoading: false,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([]),
  },
  watch: {
    glassItems(glassItems) {
      this.expandedItems = [...glassItems];
    }
  },
  created() {
    this.getGlassAirSpaces();
  },
  methods: {
    async getGlassPaintCodes(item) {
      item.glassPaintCodesLoading = true;
      const submitModel = {
        filters: {
          glass_type_id: item.glass.glass_code_type_id,
          is_manual: 0,
        },
      };
      const res = await this.$api.glass.readGlassPaintCode(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) return;
      this.$set(item, 'g_codes', res);
      item.glassPaintCodesLoading = false;
    },

    async getGlasses() {
      this.glassSpecifications = await this.$api.glass.readSpecifications({
        filters: {},
      });
    },

    async getGlassAirSpaces() {
      this.glassAirSpaces = await this.$api.glass.get('glass-air-spaces');
    },

    getGlassDescription(code) {
      const glassSpecification = this.glassSpecifications.find(
        (x) => x.code === code
      );
      if (!glassSpecification) return '';
      return glassSpecification.description;
    },

    getGlassAirSpaceName(id) {
      const airSpace = this.glassAirSpaces.find((x) => x.id === id);
      if (!airSpace) return '';
      return airSpace.name;
    },

    editGlassItem(item) {
      this.$refs.classPOItemForm.open(item, this.supplierId);
    },

    async deleteGlassItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this Glass Item?',
      });
      if (!confirm) return;

      const poItemIndex = this.poItemIndexAssociatedToGlassItem(item)
      if (poItemIndex >= 0) {
        this.$bus.$emit('showError', `Glass Item is associated to a PO Item ${poItemIndex + 1} - Please remove the association first`);
        return;
      }

      let updatedGlassItems = this.glassItems.map(glassItem => ({ ...glassItem }));
      
      const index = updatedGlassItems.findIndex(
        (x) => x.temp_id === item.temp_id
      );
      if (index >= 0) {
        updatedGlassItems.splice(index, 1);
      }

      this.$emit('update-glass-items', updatedGlassItems);
    },

    openGlassPoItemForm() {
      this.$refs.glassPoItemForm.open(null, null, true);
    },

    editGlassItem(item) {
      this.$refs.glassPoItemForm.open(item, null, true);
      // after editing, need to reload glass items? - tested and don't think we need to reload
    },

    glassPoItemSuccess(item) {
      let updatedGlassItems = this.glassItems.map(glassItem => ({ ...glassItem }));

      const index = updatedGlassItems.findIndex(
        (x) => x.temp_id === item.temp_id
      );
      if (index >= 0) {
        updatedGlassItems.splice(index, 1, item);
        this.$emit('edit-glass-item', updatedGlassItems, item);
      } else {
        updatedGlassItems.push(item);
        this.$emit('update-glass-items', updatedGlassItems);
      }
      // const indexExpand = this.expandedItems.findIndex(
      //   (x) => x === item.temp_id
      // );
      // if (indexExpand < 0) {
      //   this.expandedItems.push(item.temp_id);
      // }
    },

    poItemIndexAssociatedToGlassItem(item) {
      const index = this.poItems.findIndex((poItem) => 
        String(poItem.glass.g_code) === String(item.g_code)
      );
      return index;
    },
  },
};
</script>