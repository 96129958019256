<template>
  <v-container fluid>
    <v-row class="align-center">
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
          :is-inst="false"
          :is-vwd-glass="false"
          :is-rail-part="false"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-autocomplete
          v-model="projectId"
          :items="projects"
          item-value="id"
          item-text="name"
          label="Project"
          clearable
          :loading="loading"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-5"
      >
        <v-menu
          ref="menuShipDate"
          v-model="dateMenuShipDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterPlannedShipDate"
              readonly
              append-icon="mdi-calendar"
              v-bind="attrs"
              hide-details
              v-on="on"
              @change="$refs.menuShipDate.save(filterPlannedShipDate)"
            >
              <template slot="label">
                Shipping Date Start
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filterPlannedShipDate"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterPlannedShipDate = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="2"
        class="pb-5"
      >
        <v-menu
          ref="menuShipDateEnd"
          v-model="dateMenuShipDateEnd"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterPlannedShipDateEnd"
              readonly
              append-icon="mdi-calendar"
              v-bind="attrs"
              hide-details
              v-on="on"
              @change="$refs.menuShipDateEnd.save(filterPlannedShipDateEnd)"
            >
              <template slot="label">
                Shipping Date End
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filterPlannedShipDateEnd"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterPlannedShipDateEnd = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn
          class="ml-4"
          outlined
          color="primary"
          dark
          @click="runWebhook()"
        >
          pdf
        </v-btn>
        <v-btn
          class="ml-2"
          outlined
          color="primary"
          dark
          @click="runWebhookXls()"
        >
          excel
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0 pb-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
            @update:options="updateOptions"
          >
            <template #item="{ item }">
              <tr>
                <td class="text-center pr-1 pl-1">
                  <v-chip
                    small
                    :color="item && item.class ? item.class.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : '' }}
                  </v-chip>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.code ? item.code : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.project_name ? item.project_name : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.reference ? item.reference : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.shipped_at ? item.shipped_at : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.total_sqft ? item.total_sqft : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight
                    :queries="search"
                    style="white-space: nowrap; font-weight: bold;;"
                  >
                    ({{ item.shipped_windows ? item.shipped_windows : '' }} / {{ item.total_windows ? item.total_windows : '' }})
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1">
                  <text-highlight :queries="search">
                    {{ item.invoice_id ? item.invoice_id : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1 text-end">
                  <text-highlight :queries="search">
                    C${{ item.sub_total ? item.sub_total : '' }}
                  </text-highlight>
                </td>
                <td class="pr-1 pl-1 text-end">
                  <text-highlight :queries="search">
                    C${{ item.total ? item.total : '' }}
                  </text-highlight>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <the-dialog-box
      ref="dialogBox"
      :submit="get"
      :submit-btn-text="'Submit'"
      :persistent="true"
      :btn-loading="loading"
    >
      <template slot="header">
        <span>Enter password</span>
      </template>
      <template slot="body">
        <v-row>
          <v-col
            class="pb-0"
          >
            <v-text-field
              v-model="passwordForm"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Password is required']"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
      </template>
    </the-dialog-box>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    items: [],
    jobs: [],
    clients: [],
    total: 0,
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    headers: [
      {
        text: 'Class', value: 'inventory_class', class: 'pl-1 pr-1',
      },
      {
        text: 'WO#', value: 'work_order', class: 'pl-1 pr-1',
      },
      {
        text: 'Project', value: 'project', class: 'pl-1 pr-1',
      },
      {
        text: 'Reference / Floor #', value: 'reference', class: 'pl-1 pr-1',
      },
      {
        text: 'Shipping Date', value: 'shipped_at', class: 'pl-1 pr-1',
      },
      {
        text: 'SQFT', value: 'total_sqft', class: 'pl-1 pr-1',
      },
      {
        text: 'Ship.Win. / Total Win.', value: 'total_windows', class: 'pl-1 pr-1',
      },
      {
        text: 'Invoice#', value: 'invoice_id', class: 'pl-1 pr-1',
      },
      {
        text: 'Sub Total $', value: 'sub_total', class: 'pl-1 pr-1', align: 'end',
      },
      {
        text: 'Total $', value: 'total', class: 'pl-1 pr-1', align: 'end',
      },
    ],
    footer: {
      'items-per-page-options': [100, 500, 1000, -1],
    },

    inventoryClassId: null,
    projectId: null,

    loading: false,
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    topSpaceHeight: 270,
    windowHeight: 1000,
    passwordForm: null,
    password: null,
    isInit: false,
    showPassword: false,
    sort: {
      order_by_first: 'created_at',
      direction_sort: true,
    },
    projects: [],
    dateMenuShipDate: false,
    dateMenuShipDateEnd: false,
    filterPlannedShipDate: null,
    filterPlannedShipDateEnd: null,

  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    inventoryClassId: {
      handler() {
        if (this.isInit) return;
        this.get();
      },
      immediate: false,
    },
    projectId() {
      this.get();
    },
    filterPlannedShipDate() {
      this.get();
    },
    filterPlannedShipDateEnd() {
      this.get();
    },
    search() {
      this.get();
    },
    pagination() {
      this.get();
    },
  },
  created() {
    this.isInit = true;
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    if (!this.passwordForm) {
      this.$refs.dialogBox.open();
    }

    this.$nextTick(async () => {
      this.isInit = false;
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
      this.getProjects();
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      if (!this.password) this.password = JSON.parse(JSON.stringify(this.passwordForm));
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
          project_id: this.projectId,
          password: this.password,
          start_date: this.filterPlannedShipDate,
          end_date: this.filterPlannedShipDateEnd,
        },
        search: this.search,
        pagination: this.pagination,
        sort: this.sort,
      };
      const res = await this.$api.shippingReport.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        this.password = null;
        this.$bus.$emit('showError', 'Password is incorrect. Please try again.');
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
      this.$refs.dialogBox.close();
    },

    async getProjects() {
      this.loading = true;
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.projects = [];
        return;
      }
      this.projects = res.data;
      this.loading = false;
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
        this.pagination = pagination;
      }
    },

    async runWebhook() {
      this.$bus.$emit('loading');

      const webhookModuleIdShippingReport = 54;
      const webhookData = {
        module_id: webhookModuleIdShippingReport,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Shipping_Reports_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async runWebhookXls() {
      this.$bus.$emit('loading');

      const webhookModuleIdShippingReport = 54;
      const webhookData = {
        module_id: webhookModuleIdShippingReport,
        type: 'xls',
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Shipping_Reports_${now}`;

      const file = new Blob([res], {
        type: 'application/vnd.ms-excel',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    updateOptions(newOptions) {
      this.pagination = newOptions;
      this.sort = {
        order_by_first: newOptions.sortBy[0],
        direction_sort: newOptions.sortDesc[0],
      };
    },
  },
};
</script>

  <style scoped>
  span {
    font-size: 12px;
  }
  </style>
