import axios from 'axios';
import Api from './Api';

const { CancelToken } = axios;

let cancelRead;
class ShippingReportApi extends Api {
  constructor() {
    super('shipping-reports', 'shipping-reports');
  }

  async readData(data) {
    if (cancelRead !== undefined) {
      cancelRead();
    }
    const res = await this.read(data, {
      cancelToken: new CancelToken((c) => {
        cancelRead = c;
      }),
    });
    return res;
  }
}
const shippingReportApi = new ShippingReportApi();

export default shippingReportApi;
