<template>
  <the-dialog-box
    ref="dialogBox"
    :is-submit-btn="false"
    :max-width="400"
    :scrollable="false"
    :persistent="true"
  >
    <template slot="header">
      <span>Bill Of Ladings</span>
    </template>
    <template slot="body">
      <v-list-item-group v-if="item && item.bill_of_ladings">
        <v-list-item
          :style="{ border: '1px solid #e0e0e0', borderRadius: '5px' }"
          class="mb-2"
          v-for="(bol, index) in item.bill_of_ladings"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-row justify="center" align="center">
                <v-col cols="8">
                  <v-icon class="mr-1">mdi mdi-file-document-outline</v-icon>
                  <span :style="{ position: 'absolute', top: '22px' }">{{
                    bol.code
                  }}</span>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                  <v-btn
                    color="primary"
                    @click.stop="openBillOfLadingForm(bol)"
                  >
                    VIEW
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <bill-of-lading-form ref="billOfLadingForm" />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BillOfLadingForm from '@/components/forms/billOfLadings/BillOfLadingForm.vue';

export default {
  components: {
    BillOfLadingForm,
  },
  props: {},
  data: () => ({
    item: null,
  }),
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([]),
  },
  watch: {},
  mounted() {},
  methods: {
    async open(item) {
      this.$bus.$emit('loading');
      this.item = null;
      this.$refs.dialogBox.open();
      if (!item) {
        this.$refs.dialogBox.close();
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Truck request not found');
        return;
      } else {
        this.item = JSON.parse(JSON.stringify(item));
      }
      this.$bus.$emit('loading-stop');
    },

    openBillOfLadingForm(bol) {
      this.$refs.billOfLadingForm.open(bol.id, null, null, true);
    },
  },
};
</script>