import Vue from 'vue';
import Api from './Api';
import regionsApi from './RegionsApi';
import UomsApi from './UomsApi';
import LengthsApi from './LengthsApi';
import ColorsApi from './ColorsApi';
import InventoryLocationsApi from './InventoryLocationsApi';
import LocationsApi from './LocationsApi';
import InventoryItemsApi from './InventoryItemsApi';
import InventoryTagApi from './InventoryTagsApi';
import ConsumptionsApi from './ConsumptionsApi';
import RecordsApi from './RecordsApi';
import PhysicalCountListApi from './PhysicalCountListApi';
import PhysicalCountApi from './PhysicalCountApi';
import InventoryClassesApi from './InventoryClassesApi';
import jobsApi from './JobsApi';
import clientsApi from './ClientsApi';
import ReportTemplateApi from './Reports/ReportTemplateApi';
import ReportScheduleApi from './Reports/ReportScheduleApi';
import ReportApi from './Reports/ReportApi';
import UserApi from './UsersApi';
import AlertApi from './AlertApi';
import DangerLevelsApi from './DangerLevelsApi';
import ManufacturersApi from './ManufacturersApi';
import FinishingsApi from './FinishingsApi';
import MaterialPropertiesApi from './MaterialPropertiesApi';
import GlassApi from './GlassApi';
import GlassBatchApi from './GlassBatchApi';
import SupplyToPaintApi from './SupplyToPaintApi';
import TruckScheduleRequestApi from './TruckScheduleRequestApi';
import ProductionScheduleApi from './ProductionScheduleApi';
import OrganizationsApi from './OrganizationsApi';
import FileParserApi from './FileParserApi';
import InventoryPoApi from './InventoryPoApi';
import ClientAddressApi from './ClientAddressApi';
import AddressApi from './AddressApi';
import ProjectApi from './ProjectApi';
import PartPickList from './PartPickList';
import TimeOffRequestsApi from './TimeOffRequestsApi';
import OrderAcknowledgmentApi from './OrderAcknowledgmentApi';
import InventoryMPQRsApi from './InventoryMPQRsApi';
import InvoiceApi from './InvoiceApi';
import GlassHistoryApi from './GlassHistoryApi';
import ShippingReportApi from './ShippingReportApi';

const factories = {

  user: UserApi,
  address: AddressApi,
  addressType: new Api('addresses/types', 'addresses/types'),
  country: new Api('countries', 'countries'),
  currency: new Api('currencies', 'currencies'),
  region: regionsApi,
  uom: UomsApi,
  length: LengthsApi,
  color: ColorsApi,
  job: jobsApi,
  jobSubClass: new Api('jobs/sub_classes', 'jobs/sub_classes'),
  jobStatus: new Api('jobs/statuses', 'jobs/statuses'),
  client: clientsApi,
  clientAddress: ClientAddressApi,
  alert: AlertApi,
  alertType: new Api('alert_types', 'alert_types'),
  alertProblem: new Api('alert_problems', 'alert_problems'),
  supplier: new Api('suppliers', 'suppliers'),
  supplierContact: new Api('suppliers/contacts', 'suppliers/contacts'),
  attachment: new Api('attachments', 'attachments'),

  location: LocationsApi,
  shelf: new Api('inventory_locations', 'inventory_locations'),

  supplyToLine: ConsumptionsApi,
  supplyToLineRecord: RecordsApi,
  supplyToLineScrapReason: new Api('supply_to_lines/scrap_reasons', 'supply_to_lines/scrap_reasons'),
  physicalCountList: PhysicalCountListApi,
  physicalCount: PhysicalCountApi,

  // inventory
  inventoryMovement: new Api('inventory_movements', 'inventory_movements'),
  inventoryMovementBatch: new Api('inventory_movement_batches', 'inventory_movement_batches'),
  tagToMovementbatch: new Api('tag_to_movement_batches', 'tag_to_movement_batches'),
  inventoryItem: InventoryItemsApi,
  inventoryTag: InventoryTagApi,
  inventoryLocation: InventoryLocationsApi,
  inventoryClass: InventoryClassesApi,
  dangerLevel: DangerLevelsApi,
  inventoryTagNote: new Api('inventory_tags/notes', 'inventory_tags/notes'),
  inventoryPo: InventoryPoApi,
  inventoryPoItem: new Api('inventory-pos/items', 'inventory-pos/items'),
  inventoryPoExpense: new Api('inventory-pos/expenses', 'inventory-pos/expenses'),
  inventoryPoDiscount: new Api('inventory-pos/discounts', 'inventory-pos/discounts'),
  inventoryPoStatus: new Api('inventory-pos/statuses', 'inventory-pos/statuses'),
  inventoryPoType: new Api('inventory-pos/types', 'inventory-pos/types'),
  inventoryPoColorPrice: new Api('inventory-pos/color-prices', 'inventory-pos/color-prices'),
  inventoryPoSupplierCalculationType: new Api('inventory-pos/supplier-calculation-types', 'inventory-pos/supplier-calculation-types'),
  inventoryMPQR: InventoryMPQRsApi,
  inventoryGoodEntrance: new Api('inventory-pos/good-entrances', 'inventory-pos/good-entrances'),
  inventoryPoOtherCharge: new Api('inventory-pos/other-charges', 'inventory-pos/other-charges'),

  // reports
  reportCategory: new Api('reports/categories', 'reports/categories'),
  reportTemplate: ReportTemplateApi,
  reportSchedule: ReportScheduleApi,
  report: ReportApi,
  reportParameter: new Api('reports/parameters', 'reports/parameters'),
  reportParameterType: new Api('reports/parameter-types', 'reports/parameter-types'),
  reportFileType: new Api('reports/file-types', 'reports/file-types'),
  reportDatabase: new Api('reports/databases', 'reports/databases'),

  // file parser
  fileParser: FileParserApi,
  fileParserFile: new Api('file-parsers/files', 'file-parsers/files'),
  fileParserType: new Api('file-parsers/types', 'file-parsers/types'),
  fileParserFileType: new Api('file-parsers/file-types', 'file-parsers/types'),

  // cut sheet
  cutSheet: new Api('cut-sheets', 'cut-sheets'),
  cutSheetFile: new Api('cut-sheets/files', 'cut-sheets/files'),

  // history
  historyType: new Api('histories/types', 'histories/types'),
  historyModule: new Api('histories/modules', 'histories/modules'),

  // glass
  glass: GlassApi,
  glassBatch: GlassBatchApi,
  glassBatchStatus: new Api('glasses/statuses', 'glasses/statuses'),
  glassCodeMaxQuantity: new Api('glasses/code-max-quantities', 'glasses/code-max-quantities'),
  glassPurchaseOrderItem: new Api('glasses/purchase-order-items', 'glasses/purchase-order-items'),
  glassPoStatus: new Api('glasses/po-statuses', 'glasses/po-statuses'),
  glassSpecification: new Api('glasses/specifications', 'glasses/specifications'),
  glassPaintCode: new Api('glasses/paint-codes', 'glasses/paint-codes'),
  glassRemakeReason: new Api('glasses/remake-reasons', 'glasses/remake-reasons'),
  glassRemakeSubReason: new Api('glasses/remake-sub-reasons', 'glasses/remake-sub-reasons'),
  glassPackagingSlip: new Api('glasses/packaging-slips', 'glasses/packaging-slips'),
  glassPackagingSlipItem: new Api('glasses/packaging-slips/items', 'glasses/packaging-slips/items'),
  glassPackagingSlipItemHistory: new Api('glasses/packaging-slips/items/histories', 'glasses/packaging-slips/items/histories'),
  glassHistory: GlassHistoryApi,

  // supply to paint
  supplyToPaint: SupplyToPaintApi,
  supplyToPaintStatus: new Api('supply_to_paints/statuses', 'supply_to_paints/statuses'),
  supplyToPaintEmail: new Api('supply_to_paints/emails', 'supply_to_paints/emails'),

  // truck schedule
  truckScheduleRequest: TruckScheduleRequestApi,
  truckScheduleSlot: new Api('truck-schedules/slots', 'truck-schedules/slots'),
  truckScheduleStatus: new Api('truck-schedules/statuses', 'truck-schedules/statuses'),
  truckScheduleNote: new Api('truck-schedules/notes', 'truck-schedules/notes'),
  truckScheduleTruck: new Api('truck-schedules/trucks', 'truck-schedules/trucks'),
  truckScheduleTruckType: new Api('truck-schedules/truck-types', 'truck-schedules/truck-types'),
  truckScheduleTruckRequestTruck: new Api('truck-schedules/request-trucks', 'truck-schedules/request-trucks'),

  // production schedule
  productionSchedule: ProductionScheduleApi,
  productionScheduleNoteStatus: new Api('production-schedules/note-statuses', 'production-schedules/note-statuses'),
  productionScheduleNote: new Api('production-schedules/notes', 'production-schedules/notes'),

  // finished goods
  finishedGood: new Api('finished-goods', 'finished-goods'),
  finishedGoodHistory: new Api('finished-goods/histories', 'finished-goods/histories'),
  finishedGoodStatus: new Api('finished-goods/statuses', 'finished-goods/statuses'),
  finishedGoodType: new Api('finished-goods/types', 'finished-goods/types'),

  project: ProjectApi,
  // scope of project
  scopeOfProject: new Api('projects/scope-of-projects', 'projects/scope-of-projects'),
  scopeOfProjectElement: new Api('projects/scope-of-projects/elements', 'projects/scope-of-projects/elements'),

  // doors
  doorHinge: new Api('doors/hinges', 'doors/hinges'),
  doorSwing: new Api('doors/swings', 'doors/swings'),
  doorLite: new Api('doors/lites', 'doors/lites'),
  doorSize: new Api('doors/sizes', 'doors/sizes'),
  doorScreen: new Api('doors/screens', 'doors/screens'),

  // part pick list
  partPickList: PartPickList,
  partPickListItem: new Api('part-pick-lists/items', 'part-pick-lists/items'),
  partPickListStatus: new Api('part-pick-lists/statuses', 'part-pick-lists/statuses'),
  department: new Api('departments', 'departments'),

  note: new Api('notes', 'notes'),

  // dashboards
  dashboards: new Api('dashboards', 'dashboards'),

  // time offs
  timeOffRequest: TimeOffRequestsApi,
  timeOffStatus: new Api('time-offs/statuses', 'time-offs/statuses'),
  timeOffLeaveType: new Api('time-offs/leave-types', 'time-offs/leave-types'),
  timeOffHoliday: new Api('time-offs/holidays', 'time-offs/holidays'),
  timeOffRequestHistory: new Api('time-offs/histories', 'time-offs/histories'),
  timeOffAuthenticate: new Api('time-offs/authenticate', 'time-offs/authenticate'),

  // invoices
  invoice: InvoiceApi,
  invoiceType: new Api('invoices/types', 'invoices/types'),

  shippingReport: ShippingReportApi,

  dataImport: new Api('data-imports', 'data-imports'),

  manufacturer: ManufacturersApi,
  finishing: FinishingsApi,
  materialProperty: MaterialPropertiesApi,

  dataSync: new Api('data_sync', 'data_sync'),

  webhook: new Api('webhooks', 'webhooks'),

  adminModule: new Api('admin_modules', 'admin_modules'),

  currentLevel: new Api('current-levels', 'current-levels'),

  organization: OrganizationsApi,
  powerBi: new Api('power-bis', 'power-bis'),
  fobOption: new Api('fob-options', 'fob-options'),
  shippingOption: new Api('shipping-options', 'shipping-options'),

  alloy: new Api('alloys', 'alloys'),
  tempering: new Api('temperings', 'temperings'),
  tax: new Api('taxes', 'taxes'),
  term: new Api('terms', 'terms'),
  reportIssue: new Api('report-issues', 'report-issues'),
  paintOnOption: new Api('paint-on-options', 'paint-on-options'),

  orderAcknowledgment: OrderAcknowledgmentApi,

  // bill of lading
  billOfLading: new Api('bill-of-ladings', 'bill-of-ladings'),
  billOfLadingItem: new Api('bill-of-ladings/items', 'bill-of-ladings/items'),
  billOfLadingExtraItem: new Api('bill-of-ladings/extra-items', 'bill-of-ladings/extra-items'),
  billOfLadingExtraItemType: new Api('bill-of-ladings/extra-item-types', 'bill-of-ladings/extra-item-types'),
  billOfLadingStatus: new Api('bill-of-ladings/statuses', 'bill-of-ladings/statuses'),

  helper: new Api('helpers', 'helpers'),
};

Vue.prototype.$api = factories;
