var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isDeliveriesManager || _vm.isAdmin)?_c('div',[_c('v-container',{staticClass:"mt-3",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',[_vm._v("Delivery Requests")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openTruckRequestForm(null)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("fas fa-plus")]),_vm._v(" Delivery Request ")],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.truckRequests,"footer-props":_vm.footer,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"loading":_vm.loadingTruckRequests,"dense":"","fixed-header":"","height":_vm.windowHalfHeight,"server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{key:item.id,ref:("row-" + (item.id))},[_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","draggable":"true","disabled":_vm.isTruckRequestScheduled(item)},on:{"dragstart":function($event){return _vm.onDragStart($event, item, item.id)},"dragend":_vm.onDragEnd}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-solid fa-bars ")])],1)],1)]}}],null,true)},[(_vm.isTruckRequestScheduled(item))?_c('span',[_vm._v(" Delivery Request Already Scheduled ")]):_c('span',[_vm._v(" Drag to + Button to Schedule ")])])],1),_c('td',{staticClass:"text-center"},[(item.class)?_c('v-chip',{style:({
                        background:
                          item && item.class ? item.class.color : 'grey',
                      }),attrs:{"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.project.name))]),_c('td',[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_code ? ("" + (item.job_code)) : ''))])],1),_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" - "+_vm._s(item.job_name ? item.job_name : item.project ? item.project.name : ''))]),_c('span',{staticStyle:{"color":"#1976d2"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_reference ? ("; " + (item.job_reference)) : ''))])],1)],1),_c('td',{staticClass:"text-center"},[(item.truck_types && item.truck_types.length > 1)?_c('v-row',[_c('v-col',{staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("TRUCK TYPES")])]}}],null,true)},[_c('v-row',[_c('v-col',{staticClass:"text-center pt-6 pb-6"},_vm._l((item.truck_types),function(truck_type){return _c('v-row',{key:truck_type.id},[_c('v-col',{staticClass:"pr-1 pb-1 pt-1",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":truck_type ? truck_type.color : 'white'}},[_vm._v(" "+_vm._s(truck_type ? truck_type.name : '')+" ")])],1)],1)}),1)],1)],1)],1)],1):_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item.truck_types[0]
                          ? item.truck_types[0].color
                          : 'white'}},[_vm._v(" "+_vm._s(item.truck_types[0] ? item.truck_types[0].name : '')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.date ? item.date : '-')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.slot ? item.slot.date : '-')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.created_by_user_full_name ? item.created_by_user_full_name : '-')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.slot_id ? item.slot_id : '-')+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-chip',{style:({
                        background:
                          item && item.status ? item.status.color : 'grey',
                      }),attrs:{"small":"","text-color":"white"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.status ? item.status.name : '')+" ")])],1)],1),_c('td',{staticClass:"text-right"},[(item.bill_of_ladings.length > 0)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"success","outlined":"","small":""},on:{"click":function($event){return _vm.openBillOfLadingList(item)}}},[_vm._v(" BOL ")]):_vm._e(),(!_vm.isTruckRequestScheduled(item))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.openManualScheduleForm(item)}}},[_vm._v(" SCHEDULE ")]):_vm._e(),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openTruckRequestForm(item, true)}}},[_vm._v(" VIEW ")]),(!_vm.truckRequestHasBillOfLadings(item))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mt-1 mb-1",staticStyle:{"border-radius":"7px","width":"28px !important","height":"28px !important"},attrs:{"elevation":"0","fab":"","outlined":"","x-small":"","tile":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-3"},[_c('v-list-item',{on:{"click":function($event){return _vm.openTruckRequestForm(item)}}},[_c('v-list-item-title',{style:("color: green; font-weight: 600;")},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pt-4"},[_vm._v(" EDIT ")])],1)],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.softDeleteTruckRequest(item.id)}}},[_c('v-list-item-title',{style:("color: red; font-weight: 600;")},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pt-4"},[_vm._v(" DELETE ")])],1)],1)],1)],1)],1):_vm._e()],1)])]}}],null,false,2711499761)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Scheduled Deliveries ")]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{attrs:{"items":_vm.calendarTypes,"dense":"","hide-details":"","label":"Type","item-value":"type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}],null,false,1615892956),model:{value:(_vm.calendarType),callback:function ($$v) {_vm.calendarType=$$v},expression:"calendarType"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)],1),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1),_c('v-col',{staticClass:"pt-4 pl-0",attrs:{"cols":"auto"}},[(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"calendar-container"},[_c('v-calendar',{ref:"calendar",attrs:{"weekdays":_vm.weekday,"type":_vm.calendarType,"event-more":false,"events":_vm.events,"event-overlap-mode":"column","interval-count":"0","color":"primary"},on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onCalendarDrop},scopedSlots:_vm._u([{key:"day-label-header",fn:function(dayLabelHeader){return [_c('v-row',[(_vm.holidaysObj[dayLabelHeader.date])?_c('v-col',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.holidaysObj[dayLabelHeader.date])+" ")]):_c('v-col',[_vm._v(" "+_vm._s(dayLabelHeader.day)+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({class:("m-2 " + (_vm.dropEventColorClass(dayLabelHeader.date)) + " " + (_vm.draggingOverClass(dayLabelHeader.date))),attrs:{"id":"drop-event-btn","outlined":_vm.isDraggingOverDate !== dayLabelHeader.date,"medium":"","label":"","disabled":_vm.isAddEventDisabled ||
                            dayLabelHeader.date < _vm.currentDate},on:{"dragover":function($event){return _vm.onDragOver($event, dayLabelHeader.date)},"dragleave":_vm.onDragLeave,"drop":function($event){return _vm.onAddEventDrop($event, dayLabelHeader)}}},'v-btn',attrs,false),on),[_vm._v(" DROP NEW DELIVERY HERE ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Drag a Truck Request here to Create a New Scheduled Delivery ")])])]}},{key:"day-label",fn:function(dayLabel){return [_c('v-row',[(_vm.holidaysObj[dayLabel.date])?_c('v-col',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.holidaysObj[dayLabel.date])+" ")]):_c('v-col',[_vm._v(" "+_vm._s(dayLabel.day)+" ")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"mt-2 mb-2"},on),[_c('v-btn',_vm._g(_vm._b({class:((_vm.dropEventColorClass(dayLabel.date)) + " " + (_vm.draggingOverClass(dayLabel.date))),attrs:{"id":"drop-event-btn","outlined":_vm.isDraggingOverDate !== dayLabel.date,"medium":"","label":"","disabled":_vm.isAddEventDisabled ||
                            dayLabel.date < _vm.currentDate},on:{"dragover":function($event){return _vm.onDragOver($event, dayLabel.date)},"dragleave":_vm.onDragLeave,"drop":function($event){return _vm.onAddEventDrop($event, dayLabel)}}},'v-btn',attrs,false),on),[_vm._v(" DROP NEW DELIVERY HERE ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Drag a truck request here to create a new scheduled delivery ")])])]}},{key:"event",fn:function(ref){
                            var event = ref.event;
                            var attrs = ref.attrs;
return [_c('v-card',_vm._b({staticClass:"calendar-event pr-1 pl-1",style:({ background: '#ffffff', border: '1px solid #e0e0e0', borderLeft: ("8px solid " + (_vm.getEventColor(event))), borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', height: ((_vm.getEventHeight(event)) + "px") }),attrs:{"tile":"","data-event-id":event.id},on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.onEventDrop($event, event)},"click":function($event){return _vm.openScheduledDeliveryForm(event)}}},'v-card',attrs,false),[(!_vm.scheduledDeliveryHasBillOfLadings(event))?_c('v-btn',{staticClass:"close-icon",style:({
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        zIndex: 1,
                      }),attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.softDeleteScheduledDelivery(event.id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_c('v-card-title',{staticClass:"pa-1"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-chip',{style:({fontWeight: 'bold'}),attrs:{"label":"","small":"","color":event && event.status ? _vm.lightenColor(event.status.color) : '',"text-color":event.status.color}},[_vm._v(" "+_vm._s(event && event.status ? event.status.name : '')+" ")])],1),_c('v-col',[_c('span',{style:({ fontSize: '.9rem' })},[_vm._v(" "+_vm._s(event.name)+" ")])])],1)],1),_c('v-card-text',[_c('v-list',{staticClass:"pa-0",style:({ backgroundColor: 'transparent' })},[_vm._l((event.requests),function(request,index){return [(index < 4)?_c('div',{key:index,style:({})},[(index !== 0)?_c('v-divider'):_vm._e(),(index < 3)?_c('v-list-item',{style:({
                                fontSize: '.9rem',
                                minHeight: '36px !important',
                                fontWeight: 'bold',
                              })},[_vm._v(" "+_vm._s(request.job && request.job.code ? ("" + (request.job.code)) : '')+" - "+_vm._s(request.job && request.job.name ? request.job.name : request.project && request.project.name ? request.project.name : '')+" "+_vm._s(request.job && request.job.reference ? ("; " + (request.job.reference)) : '')+" ")]):_c('v-list-item',{style:({
                                fontSize: '0.8rem',
                                justifyContent: 'flex-end',
                              })},[_vm._v(" "+_vm._s(((event.requests.length - 3) + " more"))+" ")])],1):_vm._e()]})],2)],1)],1)]}}],null,false,1502096727),model:{value:(_vm.focusDay),callback:function ($$v) {_vm.focusDay=$$v},expression:"focusDay"}})],1)],1)],1)],1)],1)],1):(_vm.isDeliveriesRequester)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab,index){return _c('v-tab',{key:index},[_vm._v(" "+_vm._s(tab)+" ")])}),1)],1)],1)],1),_c('v-container',{staticClass:"mt-3",attrs:{"fluid":""}},[(_vm.selectedTab == 0)?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openTruckRequestForm(null)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("fas fa-plus")]),_vm._v(" Delivery Request ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.truckRequests,"footer-props":_vm.footer,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"loading":_vm.loadingTruckRequests,"height":_vm.windowHeight,"dense":"","fixed-header":"","server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                              var item = ref.item;
return [_c('tr',{key:item.id,ref:("row-" + (item.id))},[_c('td',{staticClass:"text-center"},[(item.class)?_c('v-chip',{style:({
                            background:
                              item && item.class ? item.class.color : 'grey',
                          }),attrs:{"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.project.name))]),_c('td',[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_code ? ("" + (item.job_code)) : ''))])],1),_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" - "+_vm._s(item.job_name ? item.job_name : item.project ? item.project.name : ''))]),_c('span',{staticStyle:{"color":"#1976d2"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_reference ? ("; " + (item.job_reference)) : ''))])],1)],1),_c('td',{staticClass:"text-center"},[(
                            item.truck_types && item.truck_types.length > 1
                          )?_c('v-row',[_c('v-col',{staticClass:"pl-1"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("TRUCK TYPES")])]}}],null,true)},[_c('v-row',[_c('v-col',{staticClass:"text-center pt-6 pb-6"},_vm._l((item.truck_types),function(truck_type){return _c('v-row',{key:truck_type.id},[_c('v-col',{staticClass:"pr-1 pb-1 pt-1",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":truck_type
                                            ? truck_type.color
                                            : 'white'}},[_vm._v(" "+_vm._s(truck_type ? truck_type.name : '')+" ")])],1)],1)}),1)],1)],1)],1)],1):_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item.truck_types[0]
                              ? item.truck_types[0].color
                              : 'white'}},[_vm._v(" "+_vm._s(item.truck_types[0] ? item.truck_types[0].name : '')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.date ? item.date : '-')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.slot ? item.slot.date : '-')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.created_by_user_full_name ? item.created_by_user_full_name : '-')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.slot_id ? item.slot_id : '-')+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-chip',{style:({
                            background:
                              item && item.status
                                ? item.status.color
                                : 'grey',
                          }),attrs:{"small":"","text-color":"white"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.status ? item.status.name : '')+" ")])],1)],1),_c('td',{staticClass:"text-right"},[(item.bill_of_ladings.length > 0)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"success","outlined":"","small":""},on:{"click":function($event){return _vm.openBillOfLadingList(item)}}},[_vm._v(" BOL ")]):_vm._e(),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openTruckRequestForm(item, true)}}},[_vm._v(" VIEW ")]),(
                            _vm.belongsToRequester(item) &&
                            !_vm.truckRequestHasBillOfLadings(item)
                          )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mt-1 mb-1",staticStyle:{"border-radius":"7px","width":"28px !important","height":"28px !important"},attrs:{"elevation":"0","fab":"","outlined":"","x-small":"","tile":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-3"},[_c('v-list-item',{on:{"click":function($event){return _vm.openTruckRequestForm(item)}}},[_c('v-list-item-title',{style:("color: green; font-weight: 600;")},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pt-4"},[_vm._v(" EDIT ")])],1)],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.softDeleteTruckRequest(item.id)}}},[_c('v-list-item-title',{style:("color: red; font-weight: 600;")},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pt-4"},[_vm._v(" DELETE ")])],1)],1)],1)],1)],1):_vm._e()],1)])]}}],null,false,4048381346)})],1)],1)],1)],1)],1):_vm._e(),(_vm.selectedTab == 1)?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{attrs:{"items":_vm.calendarTypes,"dense":"","hide-details":"","label":"Type","item-value":"type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}],null,false,1615892956),model:{value:(_vm.calendarType),callback:function ($$v) {_vm.calendarType=$$v},expression:"calendarType"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)],1),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1),_c('v-col',{staticClass:"pt-4 pl-0",attrs:{"cols":"auto"}},[(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('div',{staticClass:"calendar-container"},[_c('v-calendar',{ref:"calendar",attrs:{"weekdays":_vm.weekday,"type":_vm.calendarType,"event-more":false,"events":_vm.events,"event-overlap-mode":"column","event-color":_vm.getEventColor,"event-height":200,"interval-count":"0","color":"primary"},on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onCalendarDrop},scopedSlots:_vm._u([{key:"day-label",fn:function(dayLabel){return [_c('v-row',[(_vm.holidaysObj[dayLabel.date])?_c('v-col',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.holidaysObj[dayLabel.date])+" ")]):_c('v-col',[_vm._v(" "+_vm._s(dayLabel.day)+" ")])],1)]}},{key:"event",fn:function(ref){
                          var event = ref.event;
return [_c('v-card',{staticClass:"calendar-event pr-1 pl-1",style:({ background: _vm.getEventColor(event) }),attrs:{"tile":"","data-event-id":event.id,"height":200},on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.onEventDrop($event, event)},"click":function($event){return _vm.openScheduledDeliveryForm(event)}}},[_c('v-card-title',{staticClass:"pa-1"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-chip',{attrs:{"small":"","color":event && event.status
                                    ? event.status.color
                                    : '',"text-color":"white"}},[_vm._v(" "+_vm._s(event && event.status ? event.status.name : '')+" ")])],1),_c('v-col',[_c('span',{style:({ color: 'white', fontSize: '.9rem' })},[_vm._v(" "+_vm._s(event.name)+" ")])])],1)],1),_c('v-card-text',[_c('v-list',{staticClass:"pa-0",style:({ backgroundColor: 'transparent' })},[_vm._l((event.requests),function(request,index){return [(index < 4)?_c('div',{key:index,style:({})},[(index !== 0)?_c('v-divider'):_vm._e(),(index < 3)?_c('v-list-item',{style:({
                                    color: 'white !important',
                                    fontSize: '.9rem',
                                    minHeight: '36px !important',
                                    justifyContent: 'center',
                                  })},[_vm._v(" "+_vm._s(request.job && request.job.code ? ("" + (request.job.code)) : '')+" - "+_vm._s(request.job && request.job.name ? request.job.name : request.project && request.project.name ? request.project.name : '')+" "+_vm._s(request.job && request.job.reference ? ("; " + (request.job.reference)) : '')+" ")]):_c('v-list-item',{style:({
                                    color: 'white !important',
                                    fontSize: '0.8rem',
                                    justifyContent: 'flex-end',
                                  })},[_vm._v(" "+_vm._s(((event.requests.length - 3) + " more"))+" ")])],1):_vm._e()]})],2)],1)],1)]}}],null,false,2473187353),model:{value:(_vm.focusDay),callback:function ($$v) {_vm.focusDay=$$v},expression:"focusDay"}})],1)])],1)],1)],1)],1):_vm._e()],1)],1):(_vm.isDeliveriesView)?_c('div',[_c('v-container',{staticClass:"mt-3",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Scheduled Deliveries ")]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{attrs:{"items":_vm.calendarTypes,"dense":"","hide-details":"","label":"Type","item-value":"type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                  var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
                                  var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.calendarType),callback:function ($$v) {_vm.calendarType=$$v},expression:"calendarType"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)],1),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1),_c('v-col',{staticClass:"pt-4 pl-0",attrs:{"cols":"auto"}},[(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"calendar-container"},[_c('v-calendar',{ref:"calendar",attrs:{"weekdays":_vm.weekday,"type":_vm.calendarType,"event-more":false,"events":_vm.events,"event-overlap-mode":"column","event-color":_vm.getEventColor,"event-height":200,"interval-count":"0","color":"primary"},on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onCalendarDrop},scopedSlots:_vm._u([{key:"day-label-header",fn:function(dayLabelHeader){return [_c('v-row',[(_vm.holidaysObj[dayLabelHeader.date])?_c('v-col',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.holidaysObj[dayLabelHeader.date])+" ")]):_c('v-col',[_vm._v(" "+_vm._s(dayLabelHeader.day)+" ")])],1)]}},{key:"day-label",fn:function(dayLabel){return [_c('v-row',[(_vm.holidaysObj[dayLabel.date])?_c('v-col',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.holidaysObj[dayLabel.date])+" ")]):_c('v-col',[_vm._v(" "+_vm._s(dayLabel.day)+" ")])],1)]}},{key:"event",fn:function(ref){
                                  var event = ref.event;
return [_c('v-card',{staticClass:"calendar-event pr-1 pl-1",style:({ background: _vm.getEventColor(event) }),attrs:{"tile":"","data-event-id":event.id,"height":200},on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.onEventDrop($event, event)},"click":function($event){return _vm.openScheduledDeliveryForm(event)}}},[_c('v-card-title',{staticClass:"pa-1"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-chip',{attrs:{"small":"","color":event && event.status ? event.status.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(event && event.status ? event.status.name : '')+" ")])],1),_c('v-col',[_c('span',{style:({ color: 'white', fontSize: '.9rem' })},[_vm._v(" "+_vm._s(event.name)+" ")])])],1)],1),_c('v-card-text',[_c('v-list',{staticClass:"pa-0",style:({ backgroundColor: 'transparent' })},[_vm._l((event.requests),function(request,index){return [(index < 4)?_c('div',{key:index,style:({})},[(index !== 0)?_c('v-divider'):_vm._e(),(index < 3)?_c('v-list-item',{style:({
                                color: 'white !important',
                                fontSize: '.9rem',
                                minHeight: '36px !important',
                                justifyContent: 'center',
                              })},[_vm._v(" "+_vm._s(request.job && request.job.code ? ("" + (request.job.code)) : '')+" - "+_vm._s(request.job && request.job.name ? request.job.name : request.project && request.project.name ? request.project.name : '')+" "+_vm._s(request.job && request.job.reference ? ("; " + (request.job.reference)) : '')+" ")]):_c('v-list-item',{style:({
                                color: 'white !important',
                                fontSize: '0.8rem',
                                justifyContent: 'flex-end',
                              })},[_vm._v(" "+_vm._s(((event.requests.length - 3) + " more"))+" ")])],1):_vm._e()]})],2)],1)],1)]}}]),model:{value:(_vm.focusDay),callback:function ($$v) {_vm.focusDay=$$v},expression:"focusDay"}})],1)],1)],1)],1)],1)],1):_vm._e(),_c('truck-schedule-request-form',{ref:"truckScheduleRequestForm",on:{"success":_vm.success}}),_c('truck-schedule-delivery-form',{ref:"truckScheduledDeliveryForm",on:{"success":_vm.success}}),_c('truck-schedule-manual-schedule-form',{ref:"truckScheduleManualScheduleForm",on:{"success":_vm.success}}),_c('truck-schedule-bill-of-lading-list-form',{ref:"billOfLadingList"}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }