<template>
  <the-dialog-box
    ref="dialogBox"
    :is-submit-btn="false"
    :max-width="480"
    :persistent="true"
  >
    <template slot="header">
      <span>Schedule Truck Request {{ item && item.id ? item.id : '' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col class="pb-0">
          <v-date-picker
            v-model="selectedDate"
            full-width
            :events="events"
            :min="minScheduledDeliveryDate"
            @change="getScheduledDeliveries(selectedDate)"
          />
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="11" class="pb-0 pl-2 pr-2">
          <v-autocomplete
            v-model="scheduledDeliveryId"
            :items="scheduledDeliveries"
            item-text="title"
            item-value="id"
            label="Add To Scheduled Delivery"
            clearable
            :disabled="!selectedDate"
            :loading="loadingScheduledDeliveries"
            :hide-details="true"
          >
            <template #item="{ item }">
              <v-chip
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer; text-transform: uppercase"
                :color="item ? getInventoryClassColor(item) : 'white'"
              >
                {{ item ? getInventoryClassName(item) : '' }}
              </v-chip>
              <span style="font-weight: bold">
                {{ item.title }}
              </span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer; text-transform: uppercase"
                :color="item ? getInventoryClassColor(item) : 'white'"
              >
                {{ item ? getInventoryClassName(item) : '' }}
              </v-chip>
              <span style="font-weight: bold">
                {{ item.title }}
              </span>
            </template>
            <template #append-outer>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    outlined
                    small
                    :disabled="!scheduledDeliveryId || !selectedDate"
                    @click="addToScheduledDelivery"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span> Click to Add to Scheduled Delivery </span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 text-center"> OR </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="11" class="pl-2 pr-2">
          <v-btn
            color="primary"
            block
            outlined
            :disabled="!selectedDate"
            @click="submitNewScheduledDelivery"
          >
            + Schedule New Delivery
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <confirm-form ref="confirmForm" />
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  components: {},
  props: {},
  data: () => ({
    item: null,
    events: [],
    scheduledDeliveries: [],
    scheduledDeliveryId: null,
    selectedDate: null,
    loadingScheduledDeliveries: false,
    truckScheduleStatusInProgress: 2,
  }),
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([]),
    minScheduledDeliveryDate() {
      return moment(new Date().toLocaleDateString('en-US')).format(
        'YYYY-MM-DD'
      );
    },
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async open(item, events) {
      this.$bus.$emit('loading');
      this.item = null;
      this.scheduledDeliveryId = null;
      this.selectedDate = null;
      this.$refs.dialogBox.open();
      if (!item) {
        this.$refs.dialogBox.close();
        this.$bus.$emit('loading-stop');
        this.$bus.$emit('showError', 'Truck request not found');
        return;
      } else {
        this.item = JSON.parse(JSON.stringify(item));
        this.events = JSON.parse(JSON.stringify(events));
      }
      this.$bus.$emit('loading-stop');
    },

    async getScheduledDeliveries(date) {
      this.loadingScheduledDeliveries = true;
      const submitModel = {
        filters: {
          date: date,
        },
      };
      const res = await this.$api.truckScheduleSlot.read(submitModel);
      if (!res) {
        this.$bus.$emit('showError', 'Unable to retrieve scheduled deliveries');
        this.loadingScheduledDeliveries = false;
        return;
      }
      this.scheduledDeliveries = res;
      this.loadingScheduledDeliveries = false;
    },

    async addToScheduledDelivery() {
      const event = this.events.find((x) => x.id === this.scheduledDeliveryId);
      if (!this.isSameClass(this.item, event)) {
        const scheduledDeliveryClassName = event.requests[0].class.name;
        this.$bus.$emit(
          'showError',
          `Truck Request must be same class as Scheduled Delivery (${scheduledDeliveryClassName})`
        );
        return;
      }
      if (this.isExistingJobInScheduledDelivery(this.item, event)) {
        this.$bus.$emit(
          'showError',
          `A Truck Request of this Job already exists in this Scheduled Delivery`
        );
        return;
      }
      this.item.slot_id = event.id;
      this.item.status_id = this.truckScheduleStatusInProgress;

      const res = await this.$api.truckScheduleRequest.update(this.item);

      this.isAddEventDisabled = true;
      if (!res) {
        this.$bus.$emit(
          'showError',
          `Error adding Delivery Request to Scheduled Delivery ${event.name}`
        );
        return;
      }

      this.$emit('success');
      this.$refs.dialogBox.close();
    },

    async submitNewScheduledDelivery() {
      if (
        this.isExistingJobAcrossScheduledDeliveries(
          this.item,
          this.selectedDate
        )
      ) {
        this.$bus.$emit(
          'showError',
          `A Scheduled Delivery already exists with this Job for this date`
        );
        return;
      }
      if (
        this.isExistingInventoryClassAcrossScheduledDeliveries(
          this.item,
          this.selectedDate
        )
      ) {
        this.$bus.$emit(
          'showError',
          `A Scheduled Delivery already exists with this Inventory Class for this date`
        );
        return;
      }
      const newTruckSchedule = {
        id: 0,
        // truck_schedule_request_id: truckRequest.id,
        requests: [this.item],
        date: this.selectedDate,
      };
      //submit a new truck_schedule_slot
      const res = await this.$api.truckScheduleSlot.store(newTruckSchedule);

      if (!res) {
        this.$bus.$emit('showError', 'Unable to create new Scheduled Delivery');
        return false;
      }
      //on success, show success message and refresh the calendar
      this.$emit('success');
      this.$refs.dialogBox.close();
    },

    isSameClass(truckRequest, event) {
      if (event.requests == null || event.requests.length <= 0) return true;
      return truckRequest.inventory_class_id === event.requests[0].class.id;
    },

    isExistingJobInScheduledDelivery(truckRequest, event) {
      const jobId = truckRequest.job_id;
      const requests = Array.isArray(event.requests) ? event.requests : [];
      return requests.some((x) => x.job_id === jobId);
    },

    isExistingJobAcrossScheduledDeliveries(truckRequest, date) {
      const jobId = truckRequest.job_id;
      return this.events
        .filter((event) => event.date === date)
        .some((event) =>
          event.requests.some((request) => request.job_id === jobId)
        );
    },

    isExistingInventoryClassAcrossScheduledDeliveries(truckRequest, date) {
      const inventoryClassId = truckRequest.inventory_class_id;
      return this.events
        .filter((event) => event.date === date)
        .some((event) =>
          event.requests.some(
            (request) => request.inventory_class_id === inventoryClassId
          )
        );
    },

    getInventoryClassName(scheduledDelivery) {
      return scheduledDelivery?.requests?.[0]?.class?.name ?? '';
    },

    getInventoryClassColor(scheduledDelivery) {
      return scheduledDelivery?.requests?.[0]?.class?.color ?? 'white';
    },
  },
};
</script>