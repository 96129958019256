<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen scrollable persistent>
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <span class="headline">{{ headline }}</span>
                <span
                  v-if="item.po_number"
                  style="font-weight: bold; font-size: 24px"
                  >#{{ item.po_number }}</span
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="2" class="pb-0">
                <inventory-class-select-element
                  v-model="item.inventory_class_id"
                  :disabled="goodEntrance || (glassItems.length > 0)"
                  :is-required="true"
                  :is-add-all="false"
                  :is-inst="false"
                  :is-vwd-glass="false"
                />
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-autocomplete
                  v-model="item.supplier_id"
                  :disabled="goodEntrance"
                  label="Supplier"
                  :items="suppliers"
                  :rules="[(v) => !!v || 'Supplier is required']"
                  :item-text="(item) => `${item.code} ${item.name}`"
                  item-value="id"
                  clearable
                  :single-select="false"
                >
                  <template #selection="{ item }">
                    <span>
                      <span class="font-weight-bold">{{ item.code }}</span>
                      {{ item.name }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      <span class="font-weight-bold">{{ item.code }}</span>
                      {{ item.name }}
                    </span>
                  </template>
                  <template slot="label"> Supplier<RedAsterisk /> </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="pb-0">
                <v-autocomplete
                  v-model="item.supplier_contact_id"
                  :disabled="goodEntrance || !item.supplier_id"
                  :items="supplierContacts"
                  auto-select-first
                  item-text="name"
                  item-value="id"
                  :loading="supplierContactLoading"
                >
                  <template slot="label"> Contact </template>
                  <template #append-outer>
                    <v-btn
                      color="primary"
                      outlined
                      small
                      :disabled="!item.supplier_id"
                      @click="openContactForm()"
                    >
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-autocomplete
                  v-model="item.job_id"
                  :disabled="goodEntrance"
                  label="Job"
                  :items="jobs"
                  :item-text="(item) => `${item.code} ${item.name}`"
                  item-value="id"
                  clearable
                  :single-select="false"
                  :loading="jobsLoading"
                >
                  <template #selection="{ item }">
                    <span>
                      <span style="font-weight: bold">{{
                        item.code ? `${item.code}` : ''
                      }}</span>
                      - {{ item.name ? item.name : '' }}
                      <span style="color: #1976d2">{{
                        item.reference ? `; ${item.reference}` : ''
                      }}</span>
                    </span>
                  </template>

                  <template #item="{ item }">
                    <span>
                      <span style="font-weight: bold">{{
                        item.code ? `${item.code}` : ''
                      }}</span>
                      - {{ item.name ? item.name : ''
                      }}<span style="color: #1976d2">{{
                        item.reference ? `; ${item.reference}` : ''
                      }}</span>
                    </span>
                  </template>
                  <template #append-outer>
                    <v-btn
                      color="primary"
                      outlined
                      small
                      @click="openJobForm()"
                    >
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col v-if="!isPatioDoorClass" cols="2" class="pb-0">
                <v-autocomplete
                  v-model="item.color_id"
                  :items="colors"
                  item-text="code"
                  item-value="id"
                  :disabled="isColorDisabled || goodEntrance"
                  :clearable="true"
                  :loading="loadingColors"
                  :rules="[
                    (v) => !isColorRequired || !!v || 'Color is required',
                  ]"
                >
                  <template #selection="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                  <template slot="label">
                    Color<RedAsterisk v-if="isColorRequired" />
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <!-- <v-col v-if="isPatioDoorClass" cols="2" class="pt-0">
                <v-autocomplete
                  v-model="interiorColor"
                  :items="colors"
                  item-text="code"
                  return-object
                  :rules="[(v) => !!v || 'Interior color is required']"
                  :loading="loadingColors"
                  clearable
                >
                  <template slot="label">
                    Interior Color<RedAsterisk />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col v-if="isPatioDoorClass" cols="2" class="pt-0">
                <v-autocomplete
                  v-model="exteriorColor"
                  :items="colors"
                  item-text="code"
                  return-object
                  :rules="[(v) => !!v || 'Exterior color is required']"
                  :loading="loadingColors"
                  clearable
                >
                  <template slot="label">
                    Exterior Color<RedAsterisk />
                  </template>
                </v-autocomplete>
              </v-col> -->
              <v-col cols="2" class="pt-0">
                <v-autocomplete
                  v-model="item.location_id"
                  :disabled="goodEntrance"
                  :items="locations"
                  auto-select-first
                  item-text="name"
                  item-value="id"
                  :loading="loadingLocations"
                  :rules="[(v) => !!v || 'Ship To is required']"
                >
                  <template slot="label"> Ship To<RedAsterisk /> </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="1" class="pt-0">
                <v-autocomplete
                  v-model="item.shipping_option_id"
                  :disabled="goodEntrance"
                  :items="shippingOptions"
                  item-text="name"
                  item-value="id"
                >
                  <template slot="label"> Ship Via </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="1" class="pt-0">
                <v-autocomplete
                  v-model="item.fob_option_id"
                  :disabled="goodEntrance"
                  :items="fobOptions"
                  item-text="name"
                  item-value="id"
                >
                  <template slot="label"> Free on Load </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="pt-0">
                <v-menu
                  ref="menuRequiredBy"
                  v-model="dateMenuRequiredBy"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.required_by"
                      :disabled="goodEntrance"
                      label="Required By Date"
                      readonly
                      v-bind="attrs"
                      append-icon="mdi-calendar"
                      v-on="on"
                      @change="$refs.menuRequiredBy.save(item.required_by)"
                    />
                  </template>
                  <v-date-picker v-model="item.required_by" no-title scrollable>
                    <v-spacer />
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="item.required_by = null"
                    >
                      Clear
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="pt-0">
                <v-autocomplete
                  v-model="item.currency_id"
                  :disabled="goodEntrance"
                  :items="currencies"
                  item-text="name"
                  item-value="id"
                  auto-select-first
                >
                  <template slot="label"> Currency </template>
                </v-autocomplete>
              </v-col>
              <v-col v-if="!isPatioDoorClass" class="text-end pt-0">
                <v-btn
                  v-if="!goodEntrance"
                  color="primary"
                  outlined
                  :disabled="
                    !item.supplier_id ||
                    !item.inventory_class_id ||
                    (isColorRequired && !item.color_id)
                  "
                  @click="openItem"
                >
                  <v-icon small class="mr-1"> fas fa-plus </v-icon>
                  New Item
                </v-btn>
              </v-col>
            </v-row>
            <inventory-po-glass-package-table-form
              v-if="item && item.inventory_class_id === inventoryClassIdPatioDoor"
              :glass-items="glassItems"
              :po-items="poItems"
              ref="inventoryPoGlassPackageTableForm"
              @update-glass-items="updateGlassItems"
              @edit-glass-item="editGlassItem"
            />
            <v-row>
              <v-spacer />
              <v-col v-if="isPatioDoorClass" cols="auto" class="text-end pt-3">
                <v-btn
                  v-if="!goodEntrance"
                  color="primary"
                  outlined
                  :disabled="
                    !item.supplier_id ||
                    !item.inventory_class_id ||
                    (isColorRequired && !item.color_id) ||
                    !glassItems || glassItems.length == 0
                  "
                  @click="openItem"
                >
                  <v-icon small class="mr-1"> fas fa-plus </v-icon>
                  New Item
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card elevation="0" outlined>
                  <v-data-table
                    :headers="headers"
                    :items="poItems"
                    :loading="loadingPoItems"
                    hide-default-footer
                    :items-per-page="-1"
                    dense
                    item-key="temp_id"
                    show-expand
                    :expanded="expandedItems"
                    :item-class="poItemClass"
                  >
                    <template #[`item.data-table-expand`]="{ item }">
                      <tr>
                        <td>
                          <v-icon
                            v-if="
                              item.goodEntrances &&
                              item.goodEntrances.length > 0
                            "
                            style="font-size: 22px !important"
                            @click="toggleExpand(item)"
                          >
                            {{
                              expandedItems.includes(item)
                                ? 'mdi-chevron-down'
                                : 'mdi-chevron-up'
                            }}
                          </v-icon>
                        </td>
                      </tr>
                    </template>
                    <template #[`item.status`]="{ item }">
                      <v-chip
                        class="mt-1 mb-1 mr-3"
                        small
                        :color="poItemColorStatus(item)"
                      >
                        {{ poItemStatus(item) }}
                      </v-chip>
                    </template>
                    <template #[`item.width`]="{ item }">
                      <span>{{
                        item.width ? convertToFraction(item.width) : '-'
                      }}</span>
                    </template>
                    <template #[`item.height`]="{ item }">
                      <span>{{
                        item.height ? convertToFraction(item.height) : '-'
                      }}</span>
                    </template>
                    <template #[`item.part`]="{ item }">
                      <span style="font-weight: bold"
                        >{{ item.item_code }} - {{ item.item_name }}
                        {{
                          item.clone_index ? `(${item.clone_index})` : ''
                        }}</span
                      >
                    </template>
                    <template #[`item.fritted`]="{ item }">
                      {{
                        item.is_fritted !== null && item.is_fritted === 1
                          ? 'Fritted'
                          : 'Non-Fritted'
                      }}
                    </template>
                    <template #[`item.weight`]="{ item }">
                      {{ item.weight }}
                    </template>
                    <template #[`item.quantity`]="{ item }">
                      {{ item.quantity }} {{ item.uom_name }}
                    </template>
                    <template #[`item.price`]="{ item }">
                      <span style="white-space: nowrap"
                        >{{
                          selectedCurrency ? selectedCurrency.symbol_short : ''
                        }}
                        {{
                          parseFloat(item.price).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        }}</span
                      >
                    </template>
                    <template #[`item.subtotal`]="{ item }">
                      <span style="white-space: nowrap"
                        >{{
                          selectedCurrency ? selectedCurrency.symbol_short : ''
                        }}
                        {{
                          parseFloat(item.price * item.quantity).toLocaleString(
                            'en-US',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        }}</span
                      >
                    </template>
                    <template #[`item.tax`]="{ item }">
                      <span style="white-space: nowrap"
                        >{{
                          selectedCurrency ? selectedCurrency.symbol_short : ''
                        }}
                        {{
                          parseFloat(getItemTax(item)).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        }}</span
                      >
                    </template>
                    <template #[`item.total`]="{ item }">
                      <span style="white-space: nowrap"
                        >{{
                          selectedCurrency ? selectedCurrency.symbol_short : ''
                        }}
                        {{
                          parseFloat(getItemTotal(item)).toLocaleString(
                            'en-US',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        }}</span
                      >
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-btn
                        v-if="isClone"
                        class="ml-1 mt-1 mb-1"
                        color="#00897B"
                        outlined
                        small
                        @click="clone(item)"
                      >
                        Clone
                      </v-btn>
                      <v-btn
                        v-if="!goodEntrance"
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        :disabled="
                          item.supply_to_paint_status_id &&
                          item.supply_to_paint_status_id !=
                            supplyToPaintStatusIdNew
                        "
                        @click="openEdit(item)"
                      >
                        EDIT
                      </v-btn>
                      <v-btn
                        v-if="!goodEntrance"
                        class="ml-1 mt-1 mb-1"
                        color="error"
                        outlined
                        small
                        :disabled="
                          item.supply_to_paint_status_id &&
                          item.supply_to_paint_status_id !=
                            supplyToPaintStatusIdNew
                        "
                        @click="softDelete(item)"
                      >
                        DELETE
                      </v-btn>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <!-- extra th for expander column -->
                        <th />
                        <!-- extra th for status column -->
                        <th v-if="goodEntrance">
                          <v-chip small :color="totalReceivedColorStatus()">
                            {{ totalReceivedStatus() }}
                          </v-chip>
                        </th>
                        <th />
                        <template
                          v-if="
                            item.inventory_class_id ===
                            inventoryClassIdPatioDoor
                          "
                        >
                          <th v-for="index in 7" :key="index" />
                          <!-- 5 extra <th/> for patio door -->
                        </template>
                        <th />
                        <th>
                          <span
                            :style="{
                              color: isDarkMode ? '' : '#000000',
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                              textAlign: 'right',
                            }"
                            >Totals:
                          </span>
                        </th>
                        <th>
                          <span
                            :style="{
                              color: isDarkMode ? '' : '#000000',
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                            }"
                            >{{ getTotalQuantityItems() }}</span
                          >
                        </th>
                        <th
                          v-if="
                            item.inventory_class_id !==
                              inventoryClassIdPatioDoor &&
                            item.inventory_class_id !== inventoryClassIdPaint
                          "
                        >
                          <span
                            :style="{
                              color: isDarkMode ? '' : '#000000',
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                            }"
                            >{{ getTotalLength() }}</span
                          >
                        </th>
                        <th v-if="isWeightHeading">
                          <span
                            :style="{
                              color: isDarkMode ? '' : '#000000',
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                            }"
                            >{{ getTotalWeight() }} kg</span
                          >
                        </th>
                        <th class="text-end pr-1">
                          <span
                            :style="{
                              color: isDarkMode ? '' : '#000000',
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                            }"
                            >{{
                              selectedCurrency
                                ? selectedCurrency.symbol_short
                                : ''
                            }}
                            {{
                              parseFloat(getTotalUnitPrice()).toLocaleString(
                                'en-US',
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }}</span
                          >
                        </th>
                        <th class="text-end pr-1">
                          <span
                            :style="{
                              color: isDarkMode ? '' : '#000000',
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                            }"
                            >{{
                              selectedCurrency
                                ? selectedCurrency.symbol_short
                                : ''
                            }}
                            {{
                              parseFloat(getSubtotal()).toLocaleString(
                                'en-US',
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }}</span
                          >
                        </th>
                        <th class="text-end pr-1">
                          <span
                            :style="{
                              color: isDarkMode ? '' : '#000000',
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                            }"
                            >{{
                              selectedCurrency
                                ? selectedCurrency.symbol_short
                                : ''
                            }}
                            {{
                              parseFloat(getTotalTax(false)).toLocaleString(
                                'en-US',
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }}</span
                          >
                        </th>
                        <th class="text-end pr-1">
                          <span
                            :style="{
                              color: isDarkMode ? '' : '#000000',
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                            }"
                            >{{
                              selectedCurrency
                                ? selectedCurrency.symbol_short
                                : ''
                            }}
                            {{
                              parseFloat(getTotal(false)).toLocaleString(
                                'en-US',
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }}</span
                          >
                        </th>
                        <th />
                      </tr>
                    </template>
                    <template #expanded-item="{ headers, item }">
                      <td class="px-0 py-2" :colspan="headers.length">
                        <v-data-table
                          v-if="
                            item.goodEntrances &&
                            item.goodEntrances.length > 0 &&
                            item.goodEntrances
                          "
                          id="sub-table"
                          :colspan="headers.length"
                          :headers="subHeaders(item)"
                          :items="item.goodEntrances"
                          hide-default-footer
                          :items-per-page="-1"
                          dense
                        >
                          <template #[`item.item_full_name`]="">
                            {{ item.item_full_name }}
                          </template>
                          <template #[`item.fritted`]="{ item }">
                            {{
                              item.is_fritted !== null && item.is_fritted === 1
                                ? 'Fritted'
                                : 'Non-Fritted'
                            }}
                          </template>
                          <template #[`item.actions`]="{ item }">
                            <v-btn
                              class="ml-1 mt-1 mb-1"
                              color="error"
                              outlined
                              small
                              @click="deleteReceivedItem(item)"
                            >
                              Remove
                            </v-btn>
                          </template>
                        </v-data-table>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card elevation="0" outlined>
                  <inventory-po-good-entrance-table-form
                    v-if="goodEntrance"
                    ref="inventoryPoGoodEntranceTableForm"
                    :po-items="poItems"
                    :inventory-class-id="inventoryClassId"
                    :is-repaint="isRepaint"
                    :inventory-po-id="poId"
                    @goodsReceivedSubmitted="goodsReceivedSubmitted"
                    @updateSubmitButtonState="updateSubmitButtonState"
                  />
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <inventory-po-other-form
                  ref="inventoryPoOtherForm"
                  :disabled="goodEntrance"
                  :is-tax="item.is_tax"
                  :currency="selectedCurrency"
                  @success="success"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <inventory-po-discount-form
                  ref="inventoryPoDiscountForm"
                  :disabled="goodEntrance"
                  :is-tax="item.is_tax"
                  :currency="selectedCurrency"
                  :sub-total="getSubtotal()"
                  :other-sub-total="getOtherSubtotal()"
                  @success="success"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row align="center">
                  <v-col class="pb-0">
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontSize: '18px',
                      }"
                      >Notes</span
                    >
                  </v-col>
                  <!-- <v-col
                    v-if="item.id"
                    class="text-end"
                  >
                    <v-btn
                      color="orange"
                      outlined
                      @click="openNoteForm"
                    >
                      SEE ALL NOTES
                    </v-btn>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <v-textarea v-model="item.note" outlined rows="3" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="auto" class="mr-10">
                <v-row v-if="isMinimumSetupCharge">
                  <v-col class="pt-2">
                    <v-checkbox
                      v-model="item.is_minimum_setup_charge_applicable"
                      :disabled="goodEntrance"
                      :true-value="1"
                      :false-value="0"
                      class="mt-0"
                      persistent-hint
                      hint="This limitation is imposed by repaint supplier."
                    >
                      <template slot="label">
                        <span
                          :style="{
                            color: isDarkMode ? '' : '#000000',
                            fontSize: '16px',
                          }"
                          >Minimum Setup Charge Applicable?</span
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    isMinimumSetupCharge &&
                    item.is_minimum_setup_charge_applicable == 1
                  "
                >
                  <v-col class="pt-2">
                    <v-checkbox
                      v-model="item.is_paint_available"
                      :disabled="goodEntrance"
                      :true-value="1"
                      :false-value="0"
                      class="mt-0"
                      persistent-hint
                      hint="If paint isn't available, the minimum order charge will be applied."
                    >
                      <template slot="label">
                        <span
                          :style="{
                            color: isDarkMode ? '' : '#000000',
                            fontSize: '16px',
                          }"
                          >Supplier confirmed that Paint is available?</span
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-2">
                    <v-checkbox
                      v-model="item.is_tax"
                      :disabled="goodEntrance"
                      :true-value="1"
                      :false-value="0"
                      class="mt-0"
                      persistent-hint
                      hint="If checked, tax will be applied to the total."
                    >
                      <template slot="label">
                        <span
                          :style="{
                            color: isDarkMode ? '' : '#000000',
                            fontSize: '16px',
                          }"
                          >Is tax included?</span
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="auto">
                <v-row v-if="isMinimumSetupCharge">
                  <v-col class="text-end pr-0">
                    <span
                      :style="`font-weight: bold; color: ${
                        minSetupCharge != 0 && getAllSubtotal() < minSetupCharge
                          ? isDarkMode
                            ? ''
                            : '#000000'
                          : 'rgba(0,0,0,0.4)'
                      }; font-size: 16px;`"
                      >Min Setup Charge:</span
                    >
                  </v-col>
                  <v-col
                    class="text-end pl-0"
                    style="min-width: 250px !important"
                  >
                    <span
                      :style="`font-weight: bold; color: ${
                        minSetupCharge != 0 && getAllSubtotal() < minSetupCharge
                          ? isDarkMode
                            ? ''
                            : '#000000'
                          : 'rgba(0,0,0,0.4)'
                      }; font-size: 16px;`"
                      >{{ selectedCurrency ? selectedCurrency.symbol : '' }}
                      {{
                        parseFloat(minSetupCharge).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-end pr-0">
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }"
                      >Subtotal:</span
                    >
                  </v-col>
                  <v-col
                    class="text-end pl-0"
                    style="min-width: 250px !important"
                  >
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }"
                      >{{ selectedCurrency ? selectedCurrency.symbol : '' }}
                      {{
                        parseFloat(getAllSubtotal()).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}</span
                    >
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    minSetupCharge != 0 && getAllSubtotal() < minSetupCharge
                  "
                >
                  <v-col class="text-end pr-0 pb-0 ml-1" cols="auto">
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }"
                      >Chargeable Amount:</span
                    ><br />
                  </v-col>
                  <v-col
                    class="text-end pl-0 pb-0"
                    style="min-width: 250px !important"
                  >
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }"
                      >{{ selectedCurrency ? selectedCurrency.symbol : '' }}
                      {{
                        parseFloat(getAllSubtotal(true)).toLocaleString(
                          'en-US',
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )
                      }}</span
                    >
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    minSetupCharge != 0 && getAllSubtotal() < minSetupCharge
                  "
                >
                  <v-col class="pl-4 pt-0">
                    <span style="font-size: 12px; color: rgba(0, 0, 0, 0.6)"
                      >Will apply if Subtotal below Min Setup Charge</span
                    >
                  </v-col>
                </v-row>
                <v-row v-if="getDiscountItemQty() > 0">
                  <v-col class="text-end pr-0">
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }"
                      >Discount:</span
                    >
                  </v-col>
                  <v-col
                    class="text-end pl-0"
                    style="min-width: 250px !important"
                  >
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }"
                      >{{ selectedCurrency ? selectedCurrency.symbol : '' }} -{{
                        parseFloat(getDiscount()).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}</span
                    >
                  </v-col>
                </v-row>
                <v-row v-if="item.is_tax == 1">
                  <v-col class="text-end pr-0">
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }"
                      >Tax:</span
                    >
                  </v-col>
                  <v-col
                    class="text-end pl-0"
                    style="min-width: 250px !important"
                  >
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }"
                      >{{ selectedCurrency ? selectedCurrency.symbol : '' }}
                      {{
                        parseFloat(getAllTotalTax()).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-end pr-0">
                    <span
                      :style="{
                        color: isDarkMode ? '' : '#000000',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }"
                      >Total:</span
                    >
                  </v-col>
                  <v-col
                    class="text-end pl-0"
                    style="min-width: 250px !important"
                  >
                    <span
                      :style="`font-weight: bold; font-size: 20px; color: ${
                        isDarkMode ? '' : '#000000'
                      };`"
                      >{{ selectedCurrency ? selectedCurrency.symbol : '' }}
                      {{
                        parseFloat(getAllTotal()).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="normal" class="mr-2 ml-10" @click="closeDialog">
              CLOSE
            </v-btn>
            <v-btn
              color="primary"
              :disabled="goodEntrance ? submitButtionIsDisabled : false"
              @click="handleSubmit"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <job-form
      ref="jobForm"
      :is-supervisor="false"
      :is-client="false"
      :is-work-order="false"
      :is-address="false"
      :is-job-name="false"
      :is-reference="true"
      @success="jobSuccess"
    />
    <inventory-po-item-form
      ref="inventoryPOItemForm"
      :is-tax="item.is_tax"
      :currency="selectedCurrency"
      :glassItems="glassItems"
      @success="success"
    />
    <!-- <note-form
      ref="noteForm"
      @success="noteFormSuccess"
    /> -->
    <confirm-form ref="confirmForm" />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import JobForm from '@/components/forms/jobs/JobForm';
import InventoryPoItemForm from '@/components/forms/inventoryPos/InventoryPoItemForm';
import InventoryPoOtherForm from '@/components/forms/inventoryPos/InventoryPoOtherForm';
import InventoryPoDiscountForm from '@/components/forms/inventoryPos/InventoryPoDiscountForm';
import InventoryPoGoodEntranceTableForm from '@/components/forms/inventoryPos/InventoryPoGoodEntranceTableForm.vue';
import InventoryPoGlassPackageTableForm from '@/components/forms/inventoryPos/InventoryPoGlassPackageTableForm.vue';
// import NoteForm from '@/components/forms/notes/NoteForm';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {
    JobForm,
    InventoryPoItemForm,
    InventoryPoOtherForm,
    InventoryPoDiscountForm,
    InventoryPoGoodEntranceTableForm,
    InventoryPoGlassPackageTableForm,
    // NoteForm,
  },
  props: {
    goodEntrance: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      po_number: null,
      inventory_class_id: null,
      job_id: null,
      supplier_id: null,
      status_id: 1,
      color_id: null,
      location_id: null,
      required_by: null,
      note: null,
      is_minimum_setup_charge_applicable: 1,
      is_paint_available: 1,
      fob_option_id: null,
      shipping_option_id: null,
      currency_id: null,
      is_tax: 1,
      supplier_contact_id: null,
    },
    poId: null,
    poItems: [],
    loadingPoItems: false,
    expandedItems: [],
    totalItems: [
      {
        part: '',
        note: '',
        color_code: '',
        uom_name: '',
        location_name: '',
        price: '',
        quantity: '',
        subtotal: '',
        tax: '',
        total: '',
      },
    ],
    jobs: [],
    suppliers: [],
    glassSpecifications: [],
    dialog: false,
    loading: false,
    jobsLoading: false,
    tax: 0.13,
    colors: [],
    locations: [],
    colorPrices: [],
    fobOptions: [],
    shippingOptions: [],
    currencies: [],
    supplierContacts: [],
    loadingColors: false,
    loadingLocations: false,
    dateMenuRequiredBy: false,
    inventoryClassId4500: 1,
    inventoryClassId4800: 2,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSid: 9,
    inventoryClassIdPanelParts: 10,
    inventoryClassIdPart: 11,
    inventoryClassIdPaint: 12,
    inventoryClassIdPatioDoor: 14,
    inventoryPoIssuedStatusId: 3,
    inventoryPoPartiallyReceivedStatusId: 5,
    inventoryPoFullyReceivedStatusId: 6,
    historyModuleReceivingQrId: 3,
    historyTypeCreateId: 1,
    historyTypeReplenishId: 6,
    colorPrice: null,
    minSetupCharge: 0,
    supplyToPaintStatusIdNew: 1,
    noteTypeIdInventoryPo: 1,
    typeIdNew: 1,
    typeIdRepaint: 2,
    isColorRequired: true,
    isColorDisabled: false,
    isMinimumSetupCharge: false,
    isInit: false,
    cloneCount: {},
    currencyIdCad: 1,
    selectedCurrency: null,
    supplierContactLoading: false,
    inventoryPoCalculationTypeIdLength: 1,
    inventoryPoCalculationTypeIdWeight: 2,
    submitButtionIsDisabled: true,
    isWeightHeading: false,

    //patio door
    glassItems:[],
    inventoryItemIdPatioDoorsOX: 3001,
    inventoryItemIdPatioDoorsXO: 3002,

    // data properties for good entrance
    loadingItemsToBeReceived: false,
    inventoryClassId: null,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([]),

    isClone() {
      const isClone = !this.goodEntrance && this.item.inventory_class_id !== this.inventoryClassIdPatioDoor;
      return isClone;
    },

    isUpcCode() {
      return (
        this.inventoryClassId === this.inventoryClassIdPart ||
        this.inventoryClassId === this.inventoryClassIdInst
      );
    },

    isPatioDoorClass() {
      return (
        this.inventoryClassId === this.inventoryClassIdPatioDoor ||
        (this.item &&
          this.item.inventory_class_id === this.inventoryClassIdPatioDoor)
      );
    },

    isPaintClass() {
      return this.inventoryClassId === this.inventoryClassIdPaint;
    },

    isGlassClass() {
      return this.inventoryClassId === this.inventoryClassIdGlass;
    },

    isRepaint() {
      return this.item && this.item.type_id === this.typeIdRepaint;
    },

    headline() {
      const action = this.item.po_number ? 'Update' : 'Create';
      const type = this.goodEntrance ? 'Goods Entrance' : '';

      return `${this.goodEntrance ? '' : action} ${type} Inventory PO`.trim();
    },

    headers() {
      const newHeaders = [];

      newHeaders.push({
        text: '',
        value: 'data-table-expand',
        width: '2%',
      });
      if (this.goodEntrance) {
        newHeaders.push({
          text: 'Status',
          value: 'status',
          width: '5%',
          align: 'center',
        });
      }
      if (this.item.inventory_class_id !== this.inventoryClassIdPatioDoor) {
        newHeaders.push({ text: 'Item', value: 'part', width: '18%' });
      }
      newHeaders.push({ text: 'Note', value: 'note', width: '10%' });

      if (this.item.inventory_class_id !== this.inventoryClassIdPatioDoor) {
        newHeaders.push({
          text: 'From Colors',
          value: 'color_names',
          width: '15%',
        });
      }

      if (this.item.inventory_class_id === this.inventoryClassIdPatioDoor) {
        newHeaders.push({ text: 'Glass', value: 'glass.g_code' });
        newHeaders.push({ text: 'Interior Colors', value: 'color.code' });
        newHeaders.push({ text: 'Exterior Colors', value: 'ex_color.code' });
        newHeaders.push({ text: 'Door Height', value: 'height' });
        newHeaders.push({ text: 'Door Width', value: 'width' });
        newHeaders.push({ text: 'Fritted', value: 'fritted' });
        newHeaders.push({ text: 'Screen', value: 'door_screen.name' });
        newHeaders.push({ text: 'OX Qty', value: 'ox_quantity' });
        newHeaders.push({ text: 'XO Qty', value: 'xo_quantity' });
      }

      newHeaders.push({ text: 'Quantity', value: 'quantity', width: '5%' });

      if (
        this.item.inventory_class_id !== this.inventoryClassIdPatioDoor &&
        this.item.inventory_class_id !== this.inventoryClassIdPaint
      ) {
        newHeaders.push({ text: 'Length', value: 'length_name', width: '5%' });
      }

      if (this.isWeightHeading) {
        newHeaders.push({ text: 'Weight', value: 'weight', width: '5%' });
      }

      newHeaders.push({
        text: 'Unit Price',
        value: 'price',
        width: this.goodEntrance ? '20%' : '5%',
        align: 'end',
      });

      newHeaders.push({
        text: 'Subtotal',
        value: 'subtotal',
        width: '5%',
        align: 'end',
      });

      newHeaders.push({
        text: 'Tax',
        value: 'tax',
        width: '5%',
        align: 'end',
      });

      newHeaders.push({
        text: 'Total',
        value: 'total',
        width: '5%',
        align: 'end',
      });

      newHeaders.push({
        text: 'Actions',
        value: 'actions',
        width: '20%',
        align: 'end',
      });

      return newHeaders;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.inventory_class_id': function () {
      this.getJobs();
    },
    // eslint-disable-next-line func-names
    'item.color_id': function (val) {
      if (!val) return;
      const color = this.colors.find((x) => x.id === val);
      if (!color) return;
      const colorPrice = this.colorPrices.find((x) =>
        color.code.toUpperCase().includes(x.code.toUpperCase())
      );
      if (!colorPrice) return;
      this.colorPrice = colorPrice;
      this.isMinimumSetupCharge = true;
      this.setupMinCharge();
      if (this.isInit) return;
      if (this.item.supplier_id) {
        this.item.is_minimum_setup_charge_applicable = 1;
        this.item.is_paint_available = 1;
      } else {
        this.item.is_minimum_setup_charge_applicable = 0;
        this.item.is_paint_available = 0;
      }
    },
    // eslint-disable-next-line func-names
    'item.is_minimum_setup_charge_applicable': function () {
      if (!this.colorPrice) return;
      this.setupMinCharge();
    },
    // eslint-disable-next-line func-names
    'item.is_paint_available': function () {
      if (!this.colorPrice) return;
      this.setupMinCharge();
    },
    // eslint-disable-next-line func-names
    'item.supplier_id': function (val, prevVal) {
      if (!val) return;
      const supplier = this.suppliers.find((x) => x.id === val);
      if (!supplier) return;
      if (
        supplier.inventory_po_type_ids &&
        supplier.inventory_po_type_ids.includes(this.typeIdRepaint)
      ) {
        if (
          supplier.inventory_po_calculation_type_id ===
          this.inventoryPoCalculationTypeIdLength
        ) {
          this.isColorDisabled = false;
          this.isColorRequired = true;
          this.isWeightHeading = false;
        } else if (
          supplier.inventory_po_calculation_type_id ===
          this.inventoryPoCalculationTypeIdWeight
        ) {
          this.isWeightHeading = true;
        }

        if (!this.isInit && this.item.color_id != null) {
          this.isMinimumSetupCharge = true;
          this.item.is_minimum_setup_charge_applicable = 1;
          this.item.is_paint_available = 1;
          this.setupMinCharge();
        }
      } else {
        if (this.item.inventory_class_id === this.inventoryClassIdSid)
          this.isColorDisabled = false;
        else this.isColorDisabled = true;
        this.isColorRequired = false;
        this.isMinimumSetupCharge = false;
        this.item.color_id = null;
        this.item.is_minimum_setup_charge_applicable = 0;
        this.item.is_paint_available = 0;
      }
      if (prevVal && val !== prevVal) {
        this.item.supplier_contact_id = null;
        this.getSupplierContacts();
      } else {
        this.getSupplierContacts();
      }
    },
    // eslint-disable-next-line func-names
    'item.currency_id': function () {
      if (!this.item.currency_id) return;
      this.selectedCurrency = this.currencies.find(
        (x) => x.id === this.item.currency_id
      );
    },
  },
  created() {
    this.item = { ...this.itemDto };
    this.init();
  },
  methods: {
    init() {
      this.getJobs();
      this.getSuppliers();
      this.getColors();
      this.getLocations();
      this.getColorPrices();
      this.getFobOptions();
      this.getShippingOptions();
      this.getCurrencies();
    },

    async get(id) {
      this.expandedItems = [];
      this.loadingPoItems = true;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.inventoryPo.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.poItems = [];
        this.$bus.$emit('loading');
        this.isInit = false;
        return;
      }
      const firstIndex = 0;
      this.item = res.data[firstIndex];

      if (res.data[firstIndex].latest_note) {
        this.item.note = res.data[firstIndex].latest_note.text;
      }

      const submitModel2 = {
        filters: {
          inventory_po_id: id,
          is_patio_door: this.isPatioDoorClass || null,
        },
      };
      const res2 = await this.$api.inventoryPoItem.read(submitModel2);
      if (!res2) {
        this.poItems = [];
        this.$bus.$emit('loading');
        this.isInit = false;
        return;
      }
      this.poItems = res2;

      if (this.goodEntrance) {
        for (let i = 0; i < this.poItems.length; i++) {
          const poItem = this.poItems[i];
          const submitModel3 = {
            filters: {
              inventory_po_item_id: poItem.id,
            },
          };
          // eslint-disable-next-line no-await-in-loop
          const res3 = await this.$api.inventoryGoodEntrance.read(submitModel3);
          if (!res3 || res3.length <= 0 || !res3[0]) {
            this.poItems[i].goodEntrances = [];
            this.poItems[i].quantity_received = 0;
          } else {
            this.poItems[i].goodEntrances = res3;
            this.poItems[i].quantity_received = this.poItems[
              i
            ].goodEntrances.reduce(
              (sum, goodEntrance) =>
                sum + (this.isGlassClass ? 1 : goodEntrance.quantity),
              0
            );
          }
        }
      }

      if (this.isPatioDoorClass) {
        //set glassItems array
        this.glassItems = [];
        const glassItems = {}
        this.poItems.forEach((poItem) => {
          if (!glassItems[poItem.glass.g_code]) {
            glassItems[poItem.glass.g_code] = true;
            poItem.glass.temp_id = poItem.glass.glass.id;
            this.glassItems.push(poItem.glass);
          }
        })
        this.glassItems.sort((a, b) => a.temp_id - b.temp_id);
      }

      this.loadingPoItems = false;
      this.isInit = false;

      this.$nextTick(async () => {
        if (this.$refs.inventoryPoOtherForm) {
          this.$refs.inventoryPoOtherForm.get(id);
          this.$refs.inventoryPoOtherForm.isOpen = true;
        }
      });
    },

    handleSubmit() {
      this.goodEntrance ? this.submitGoodsReceived() : this.submit();
    },

    async submit() {
      if (!(await this.$refs.form.validate())) return;

      this.$bus.$emit('loading');

      let res = null;
      let inventoryPoId = this.item.id;

      if (this.item.id) {
        res = await this.$api.inventoryPo.update(this.item);
      } else {
        res = await this.$api.inventoryPo.store(this.item);
        inventoryPoId = res;
      }

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      if (this.isPatioDoorClass) {
        this.poItems = this.processPatioDoorItems(this.poItems);
      }

      this.poItems.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
        x.inventory_po_id = inventoryPoId;
      });

      const submitModelItem = {
        inventory_po_id: inventoryPoId,
        items: this.poItems,
      };

      const res2 = await this.$api.inventoryPoItem.store(submitModelItem);

      if (!res2) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      if (this.$refs.inventoryPoOtherForm) {
        const otherExpenses = this.$refs.inventoryPoOtherForm.items;
        otherExpenses.forEach((x) => {
          // eslint-disable-next-line no-param-reassign
          x.inventory_po_id = inventoryPoId;
        });

        const submitModelExpense = {
          inventory_po_id: inventoryPoId,
          items: otherExpenses,
        };

        const res3 = await this.$api.inventoryPoExpense.store(
          submitModelExpense
        );

        if (!res3) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading');
          return;
        }
      }

      if (this.$refs.inventoryPoDiscountForm) {
        const discounts = this.$refs.inventoryPoDiscountForm.items;
        discounts.forEach((x) => {
          // eslint-disable-next-line no-param-reassign
          x.inventory_po_id = inventoryPoId;
        });

        const submitModelDiscount = {
          inventory_po_id: inventoryPoId,
          items: discounts,
        };

        const res3 = await this.$api.inventoryPoDiscount.store(
          submitModelDiscount
        );

        if (!res3) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading');
          return;
        }
      }

      if (
        this.item.note &&
        this.item.note.length > 0 &&
        this.isNewNote(this.item.note)
      ) {
        const note = {
          text: this.item.note,
          record_id: inventoryPoId,
          type_id: this.noteTypeIdInventoryPo,
        };
        const res4 = await this.$api.note.store(note);
        if (!res4) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading');
          return;
        }
      }

      this.$bus.$emit('loading');
      this.$emit('success');
      this.closeDialog();
    },

    async getJobs() {
      this.jobsLoading = true;
      const submitModel = {
        filters: { inventory_class_id: this.item.inventory_class_id },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;
      this.jobsLoading = false;
    },

    async getSuppliers() {
      const submitModel = {};
      const res = await this.$api.supplier.read(submitModel);
      if (!res || !res.data) {
        this.suppliers = [];
        return;
      }
      this.suppliers = res.data;
    },

    async getColors() {
      this.loadingColors = true;
      const submitModel = {
        filters: {},
      };

      this.colors = await this.$api.color.read(submitModel);
      this.loadingColors = false;
    },

    async getLocations() {
      this.loadingLocations = true;
      this.locations = await this.$api.location.getList();
      this.loadingLocations = false;
    },

    async getFobOptions() {
      this.fobOptions = await this.$api.fobOption.getList();
    },

    async getShippingOptions() {
      this.shippingOptions = await this.$api.shippingOption.getList();
    },

    async getColorPrices() {
      const submitModel = {
        filters: {},
      };
      this.colorPrices = await this.$api.inventoryPoColorPrice.read(
        submitModel
      );
    },

    async getCurrencies() {
      this.currencies = await this.$api.currency.get();
    },

    async getSupplierContacts(isDefaultLast = false) {
      this.supplierContactLoading = true;
      const submitModel = {
        filters: {
          supplier_id: this.item.supplier_id,
        },
      };
      const res = await this.$api.supplierContact.read(submitModel);
      if (!res || res.length < 1 || !res[0]) {
        this.supplierContacts = [];
        this.supplierContactLoading = false;
        return;
      }
      this.supplierContacts = res;
      if (isDefaultLast)
        this.item.supplier_contact_id =
          this.supplierContacts[this.supplierContacts.length - 1].id;
      else if (!this.item.supplier_contact_id)
        this.item.supplier_contact_id = this.supplierContacts[0].id;
      this.supplierContactLoading = false;
    },

    async open(id, inventoryClassId) {
      this.inventoryClassId = inventoryClassId;
      this.isInit = true;
      this.poId = id;
      this.poItems = [];
      this.glassItems = [];
      this.isColorDisabled = true;
      this.isColorRequired = false;
      this.isMinimumSetupCharge = false;
      if (this.$refs.form) this.$refs.form.reset();
      this.dialog = true;
      this.loading = true;
      if (id) {
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
        this.item.inventory_class_id = inventoryClassId;
        this.item.currency_id = this.currencyIdCad;
        this.item.is_tax = 1;
        this.poItems = [];
        this.isInit = false;
      }

      this.$nextTick(async () => {
        if (this.$refs.inventoryPoOtherForm) {
          this.$refs.inventoryPoOtherForm.items = [];
          if (id) {
            this.$refs.inventoryPoOtherForm.get(id);
          }
        }
        if (this.$refs.inventoryPoDiscountForm) {
          this.$refs.inventoryPoDiscountForm.items = [];
          if (id) {
            this.$refs.inventoryPoDiscountForm.get(id);
          }
        }
      });
    },

    expandItems() {
      this.poItems.forEach((poItem) => {
        if (poItem.goodEntrances.length > 0) {
          this.expandedItems.push(poItem);
        }
      });
    },

    openItem() {
      const supplier = this.suppliers.find(
        (x) => x.id === this.item.supplier_id
      );
      if (!supplier) return;

      this.$refs.inventoryPOItemForm.open(
        null,
        this.item.inventory_class_id,
        supplier.inventory_po_type_ids ? supplier.inventory_po_type_ids : null,
        this.colorPrice,
        supplier.inventory_po_calculation_type_id
      );
    },

    openEdit(item) {
      const supplier = this.suppliers.find(
        (x) => x.id === this.item.supplier_id
      );
      if (!supplier) return;
      this.$refs.inventoryPOItemForm.open(
        item,
        item.inventory_item
          ? item.inventory_item.inventory_class_id
          : this.item.inventory_class_id,
        supplier.inventory_po_type_ids ? supplier.inventory_po_type_ids : null,
        this.colorPrice,
        supplier.inventory_po_calculation_type_id
      );
    },

    // openNoteForm() {
    //   this.$refs.noteForm.open(this.item.id, this.noteTypeIdInventoryPo);
    // },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record?',
      });
      if (item.id) {
        const res = await this.$api.inventoryPoItem.delete(item.id);
        if (!res) {
          this.$bus.$emit('showError');
          return;
        }
      }
      if (this.isPatioDoorClass) {
        await this.softDeletePatioDoorItem(item);
      }
      if (!confirm) return;
      const index = this.poItems.findIndex((x) => x.temp_id === item.temp_id);
      if (index >= 0) {
        this.poItems.splice(index, 1);
      }
    },

    async softDeletePatioDoorItem(item) {
      if (item.ox_id) {
        const res = await this.$api.inventoryPoItem.delete(item.ox_id);
        if (!res) {
          this.$bus.$emit('showError');
          return;
        }
      }
      if (item.xo_id) {
        const res = await this.$api.inventoryPoItem.delete(item.xo_id);
        if (!res) {
          this.$bus.$emit('showError');
          return;
        }
      }
    },

    async jobSuccess() {
      await this.getJobs();
      const latestItemId = Math.max(...this.jobs.map((x) => x.id));
      if (latestItemId) {
        this.item.job_id = latestItemId;
      }
    },

    success(item) {
      const index = this.poItems.findIndex((x) => x.temp_id === item.temp_id);
      if (index >= 0) {
        this.poItems.splice(index, 1, item);
      } else {
        this.poItems.push(item);
      }
    },

    closeDialog() {
      this.cloneCount = {};
      this.item = {};
      this.dialog = false;
      this.$emit('close-form');
    },

    async clone(item) {
      if (!this.cloneCount[item.item_code]) {
        this.cloneCount[item.item_code] = 1;
        // eslint-disable-next-line no-param-reassign
        item.clone_index = 1;
      }

      // eslint-disable-next-line no-plusplus
      this.cloneCount[item.item_code]++;
      const newItem = { ...item };
      newItem.id = 0;
      newItem.temp_id = Date.now();
      newItem.clone_index = this.cloneCount[item.item_code];
      this.poItems.push(newItem);
    },

    // getQuantity(item) {
    //   if (this.item.inventory_class_id === this.inventoryClassIdPatioDoor) {
    //     return (parseInt(item.xo_quantity, 10) || 0) + (parseInt(item.ox_quantity, 10) || 0);
    //   }
    //   return item.quantity;
    // },

    getItemTax(item) {
      const tax = this.item.is_tax ? this.tax : 0;
      return item.price * item.quantity * tax;
    },

    getItemTotal(item) {
      const tax = this.item.is_tax ? this.tax : 0;
      return item.price * item.quantity + item.price * item.quantity * tax;
    },

    getSubtotal() {
      let subtotal = 0;
      this.poItems.forEach((item) => {
        subtotal += item.price * item.quantity;
      });
      return subtotal;
    },

    getDiscount() {
      let discount = 0;
      let subtotal = this.getSubtotal();
      const othersSubtotal = this.$refs.inventoryPoOtherForm
        ? this.$refs.inventoryPoOtherForm.getSubtotal()
        : 0;
      subtotal += othersSubtotal;
      if (this.$refs.inventoryPoDiscountForm) {
        const discountItems = this.$refs.inventoryPoDiscountForm.items;
        discountItems.forEach((item) => {
          if (item.is_percent) {
            discount += parseFloat((subtotal * item.percent) / 100);
          } else discount += parseFloat(item.price);
          if (item.is_percent) {
            subtotal -= parseFloat((subtotal * item.percent) / 100);
          } else subtotal -= parseFloat(item.price);
        });
      }
      return discount;
    },

    getDiscountItemQty() {
      if (this.$refs.inventoryPoDiscountForm) {
        return this.$refs.inventoryPoDiscountForm.items.length;
      }
      return 0;
    },

    getOtherSubtotal() {
      return this.$refs.inventoryPoOtherForm
        ? this.$refs.inventoryPoOtherForm.getSubtotal()
        : 0;
    },

    getTotal() {
      return this.getSubtotal() + this.getTotalTax();
    },

    getTotalTax() {
      const tax = this.item.is_tax ? this.tax : 0;
      return this.getSubtotal() * tax;
    },

    setupMinCharge() {
      if (!this.item.is_minimum_setup_charge_applicable)
        this.minSetupCharge = 0;
      else if (this.item.is_paint_available) {
        this.minSetupCharge = this.colorPrice.min_setup_price;
      } else {
        this.minSetupCharge = this.colorPrice.min_order_price;
      }
    },

    getAllSubtotal(isChargable = false) {
      let subtotal = this.getSubtotal();
      const othersSubtotal = this.$refs.inventoryPoOtherForm
        ? this.$refs.inventoryPoOtherForm.getSubtotal()
        : 0;
      subtotal += othersSubtotal;

      if (this.$refs.inventoryPoDiscountForm && subtotal !== 0) {
        const discountItems = this.$refs.inventoryPoDiscountForm.items;
        discountItems.forEach((item) => {
          if (item.is_percent) {
            subtotal -= (subtotal * item.percent) / 100;
          } else subtotal -= item.price;
        });
      }
      // Make total price = Minimum set up charge if Supplier is of Type Repaint and Total is less than Min Setup Charge
      if (isChargable && this.item.supplier_id) {
        const supplier = this.suppliers.find(
          (x) => x.id === this.item.supplier_id
        );
        if (
          supplier &&
          supplier.inventory_po_type_ids &&
          supplier.inventory_po_type_ids.includes(this.typeIdRepaint) &&
          this.item.is_minimum_setup_charge_applicable === 1 &&
          this.colorPrice != null
        ) {
          if (
            this.item.is_paint_available === 1 &&
            this.item.is_paint_available === 1 &&
            subtotal < this.colorPrice.min_setup_price
          ) {
            return this.colorPrice.min_setup_price;
          }
          if (
            this.item.is_paint_available === 0 &&
            subtotal < this.colorPrice.min_order_price
          ) {
            return this.colorPrice.min_order_price;
          }
        }
      }
      return subtotal;
    },

    getAllTotalTax() {
      const tax = this.item.is_tax ? this.tax : 0;
      return this.getAllSubtotal(true) * tax;
    },

    getAllTotal() {
      return this.getAllSubtotal(true) + this.getAllTotalTax();
    },

    getTotalUnitPrice() {
      let totalUnitPrice = 0;
      this.poItems.forEach((item) => {
        totalUnitPrice += parseFloat(item.price);
      });
      return totalUnitPrice;
    },

    getTotalQuantityItems() {
      // group items by uom_id and count totals for each uom_id
      const items = this.poItems.reduce((acc, item) => {
        const found = acc.find((x) => x.uom_id === item.uom_id);
        if (found) {
          found.quantity =
            parseInt(found.quantity, 10) + parseInt(item.quantity, 10);
        } else {
          acc.push({
            uom_id: item.uom_id,
            quantity: item.quantity,
            uom_name: item.uom_name,
          });
        }
        return acc;
      }, []);

      if (items.length === 0) return 0;

      return items.map((x) => `${x.quantity} ${x.uom_name}`).join(', ');
    },

    getTotalLength() {
      const lengthGroups = {};

      this.poItems.forEach((item) => {
        const length = item.length_name;

        if (length) {
          const match = length.match(/^(\d+(\.\d+)?)\s*(\w+)\.?$/);

          if (match) {
            const value = parseFloat(match[1]);
            const unit = match[3];
            const totalLength = value * item.quantity;

            if (!lengthGroups[unit]) {
              lengthGroups[unit] = 0;
            }

            lengthGroups[unit] += totalLength;
          }
        }
      });

      return Object.keys(lengthGroups)
        .map((unit) => `${lengthGroups[unit]} ${unit}`)
        .join(', ');
    },

    getTotalWeight() {
      let totalWeight = 0;

      this.poItems.forEach((item) => {
        if (item.weight && item.quantity)
          totalWeight += item.weight * item.quantity;
      });

      return totalWeight;
    },

    async openJobForm() {
      const res = await this.$api.job.get('generate-manual-code');
      if (!res) return;
      this.$refs.jobForm.open();
      this.$nextTick(() => {
        this.$refs.jobForm.item.code = res;
        this.$refs.jobForm.item.is_manual = 1;
        if (this.item.inventory_class_id)
          this.$refs.jobForm.item.inventory_class_id =
            this.item.inventory_class_id;
      });
    },

    async openContactForm() {
      const message = 'Please enter contact name';
      let propVal = prompt(message);

      if (!propVal || propVal === '') {
        return false;
      }
      propVal = propVal.trim();

      const submitModel = {
        name: propVal,
        supplier_id: this.item.supplier_id,
      };

      const res = await this.$api.supplierContact.store(submitModel);

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.getSupplierContacts(true);
    },

    openConfirmForm(item) {
      item.po_number = this.item.po_number;
      const supplier = this.suppliers.find(
        (x) => x.id === this.item.supplier_id
      );
      if (!supplier) return;
      this.$refs.inventoryPOItemForm.open(
        item,
        this.item.inventory_class_id,
        supplier.inventory_po_type_ids ? supplier.inventory_po_type_ids : null,
        this.colorPrice,
        null,
        true
      );
    },

    async deleteReceivedItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this item?',
      });
      if (!confirm) return;
      const res = await this.$api.inventoryGoodEntrance.softDelete(item.id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      const poItemId = item.inventory_po_item_id;
      const goodEntranceId = item.id;
      this.removeGoodEntranceFromPoItems(poItemId, goodEntranceId);
      this.$bus.$emit('showSuccess');
      await this.get(this.poId);
      this.setInventoryPoStatus();
    },

    removeGoodEntranceFromPoItems(poItemId, goodEntranceId) {
      const poItemIndex = this.poItems.findIndex(
        (poItem) => poItem.id === poItemId
      );

      if (poItemIndex >= 0) {
        const goodEntranceIndex = this.poItems[
          poItemIndex
        ].goodEntrances.findIndex(
          (goodEntrance) => goodEntrance.id === goodEntranceId
        );

        if (goodEntranceIndex >= 0) {
          this.poItems[poItemIndex].goodEntrances.splice(goodEntranceIndex, 1);
          if (this.poItems[poItemIndex].goodEntrances.length === 0) {
            this.expandedItems = this.expandedItems.filter(
              (expandedItem) => expandedItem.id !== poItemId
            );
          }
        } else {
          this.$bus.$emit('showError');
        }
      } else {
        this.$bus.$emit('showError');
      }
    },

    async setInventoryPoStatus() {
      const quantityReceived = this.poItems.reduce(
        (sum, poItem) => sum + poItem.quantity_received,
        0
      );
      const quantityOrdered = this.poItems.reduce(
        (sum, poItem) => sum + poItem.quantity,
        0
      );
      if (quantityReceived >= quantityOrdered) {
        // set status to quantity fulfilled
        this.$emit('changeInventoryPoStatus', {
          id: this.item.id,
          status_id: this.inventoryPoFullyReceivedStatusId,
        });
      } else if (quantityReceived > 0 && quantityReceived < quantityOrdered) {
        // set status to partially fulfilled
        this.$emit('changeInventoryPoStatus', {
          id: this.item.id,
          status_id: this.inventoryPoPartiallyReceivedStatusId,
        });
      } else if (quantityReceived === 0) {
        this.$emit('changeInventoryPoStatus', {
          id: this.item.id,
          status_id: this.inventoryPoIssuedStatusId,
        });
      }
    },

    toggleExpand(item) {
      if (!item) return;
      const index = this.expandedItems.map((i) => i.id).indexOf(item.id);
      if (index > -1) {
        this.expandedItems.splice(index, 1);
      } else {
        this.expandedItems.push(item);
      }
    },

    poItemStatus(item) {
      let quantityLabel = '';
      if (item.quantity_received > item.quantity) {
        quantityLabel = 'Over ';
      } else if (item.quantity_received === item.quantity) {
        quantityLabel = 'Full ';
      } else if (item.quantity_received > 0) {
        quantityLabel = 'Partial ';
      }
      return `${quantityLabel}(${
        item.quantity_received ? item.quantity_received : 0
      }/${item.quantity})`;
    },

    poItemColorStatus(item) {
      let statusColor = '';
      if (item.quantity_received === item.quantity) {
        statusColor = '#00BFA5';
      } else if (
        item.quantity_received < item.quantity &&
        item.quantity_received > 0
      ) {
        statusColor = '#C2B8AF';
      }
      return statusColor;
    },

    totalReceivedStatus() {
      let quantityReceived = 0;
      let quantityOrdered = 0;
      this.poItems.forEach((poItem) => {
        quantityReceived += parseInt(poItem.quantity_received);
        quantityOrdered += parseInt(poItem.quantity);
      });
      let quantityLabel = '';
      if (quantityReceived > quantityOrdered) {
        quantityLabel = 'Over ';
      } else if (quantityReceived === quantityOrdered) {
        quantityLabel = 'Full ';
      } else if (quantityReceived > 0) {
        quantityLabel = 'Partial ';
      }
      return `${quantityLabel}(${quantityReceived || 0}/${quantityOrdered})`;
    },

    totalReceivedColorStatus() {
      let statusColor = '';
      let quantityReceived = 0;
      let quantityOrdered = 0;
      this.poItems.forEach((poItem) => {
        quantityReceived += parseInt(poItem.quantity_received);
        quantityOrdered += parseInt(poItem.quantity);
      });
      if (quantityReceived === quantityOrdered) {
        statusColor = '#00BFA5';
      } else if (quantityReceived < quantityOrdered && quantityReceived > 0) {
        statusColor = '#C2B8AF';
      }
      return statusColor;
    },

    poItemClass(item) {
      let itemClass = '';
      if (item.quantity_received === item.quantity) {
        itemClass = 'full';
      } else if (
        item.quantity_received < item.quantity &&
        item.quantity_received > 0
      ) {
        itemClass = 'partial';
      }
      return itemClass;
    },

    processPatioDoorItems(poItems) {
      const ungroupedPoItems = [];

      poItems.forEach((groupedPoItem) => {
        const inventoryItemPatioDoorOX = { 
          ...groupedPoItem,
          id: groupedPoItem.ox_id || null,
          inventory_item_id: this.inventoryItemIdPatioDoorsOX, 
          quantity: groupedPoItem.ox_quantity 
        };
        delete inventoryItemPatioDoorOX.xo_quantity;
        delete inventoryItemPatioDoorOX.ox_quantity;

        ungroupedPoItems.push(inventoryItemPatioDoorOX);

        const inventoryItemPatioDoorXO = { 
          ...groupedPoItem,
          id: groupedPoItem.xo_id || null,
          inventory_item_id: this.inventoryItemIdPatioDoorsXO, 
          quantity: groupedPoItem.xo_quantity 
        };
        delete inventoryItemPatioDoorXO.xo_quantity;
        delete inventoryItemPatioDoorXO.ox_quantity;

        ungroupedPoItems.push(inventoryItemPatioDoorXO);
      });

      return ungroupedPoItems;
    },

    async goodsReceivedSubmitted(isSubmitAll) {
      await this.get(this.poId);
      this.setInventoryPoStatus();
      if (isSubmitAll) {
        this.closeDialog();
      }
    },

    submitGoodsReceived() {
      this.$refs.inventoryPoGoodEntranceTableForm.submitGoodsReceived();
    },

    subHeaders(item) {
      const inventoryClassId = item.glass_paint_code_id
        ? this.inventoryClassIdPaint
        : item.inventory_item.inventory_class_id;
      const newSubHeaders = [];

      newSubHeaders.push({
        text: 'QR Code',
        value: 'inventory_tag.inventory_tag',
        width: '5%',
      });

      newSubHeaders.push({
        text: 'Item Name',
        value: 'item_full_name',
        width: '20%',
      });

      if (
        inventoryClassId !== this.inventoryClassIdSid &&
        inventoryClassId !== this.inventoryClassIdGlass &&
        inventoryClassId !== this.inventoryClassIdVWDGlass &&
        inventoryClassId !== this.inventoryClassIdPaint &&
        inventoryClassId !== this.inventoryClassIdPatioDoor
      ) {
        newSubHeaders.push({
          text: 'Colour',
          value: 'inventory_tag.color.code',
          width: '8%',
        });
      }

      if (
        inventoryClassId !== this.inventoryClassIdSid &&
        inventoryClassId !== this.inventoryClassIdGlass &&
        inventoryClassId !== this.inventoryClassIdVWDGlass &&
        inventoryClassId !== this.inventoryClassIdPanels &&
        inventoryClassId !== this.inventoryClassIdPaint &&
        inventoryClassId !== this.inventoryClassIdPatioDoor
      ) {
        newSubHeaders.push({
          text: 'Length',
          value: 'inventory_tag.length.name',
          width: '5%',
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdPatioDoor) {
        // newSubHeaders.push({ text: 'Glass', value: 'inventory_tag.glass' });
        newSubHeaders.push({
          text: 'Interior Colors',
          value: 'inventory_tag.color.code',
        });
        newSubHeaders.push({
          text: 'Exterior Colors',
          value: 'inventory_tag.ex_color.code',
        });
        newSubHeaders.push({
          text: 'Door Height',
          value: 'inventory_tag.height',
        });
        newSubHeaders.push({
          text: 'Door Width',
          value: 'inventory_tag.width',
        });
        newSubHeaders.push({ text: 'Fritted', value: 'fritted' });
        newSubHeaders.push({
          text: 'Screen',
          value: 'inventory_tag.door_screen.name',
        });
      }

      newSubHeaders.push({
        text: 'UOM',
        value: 'inventory_tag.uom.name',
        width: '5%',
      });

      newSubHeaders.push({
        text: 'Qty Received',
        value: 'quantity',
        width: '4%',
      });

      newSubHeaders.push({
        text: 'Packing Slip#',
        value: 'packaging_slip',
        width: '8%',
      });

      newSubHeaders.push({
        text: 'Received At',
        value: this.isUpcCode
          ? 'history.created_at'
          : 'inventory_tag.created_at',
        width: '8%',
      });

      newSubHeaders.push({
        text: 'Received By',
        value: this.isUpcCode
          ? 'history.created_by_name'
          : 'inventory_tag.created_by_name',
        width: '8%',
      });

      newSubHeaders.push({
        text: 'Created At',
        value: 'created_at',
        width: '8%',
      });

      newSubHeaders.push({
        text: 'Created By',
        value: 'created_by_name',
        width: '8%',
      });

      newSubHeaders.push({ text: 'Actions', value: 'actions', align: 'end' });

      return newSubHeaders;
    },

    updateSubmitButtonState(isEnabled) {
      if (this.goodEntrance) {
        this.submitButtionIsDisabled = !isEnabled;
      }
    },

    // async noteFormSuccess() {
    //   await this.get(this.poId);
    // },

    convertToFraction(decimal) {
      return FormatHelpers.convertToFraction(decimal);
    },

    isNewNote(note) {
      if (!this.item.latest_note) return true;
      return this.item.latest_note.text !== note;
    },

    updateGlassItems(newItems) {
      this.glassItems = newItems; // Update glassItems when received from ChildOne
    },

    editGlassItem(newItems, item) {
      this.glassItems = newItems; // Update glassItems when received from ChildOne
      //find the inventory po items that have the changed glass then update the is_fritted for those inventory po items
      this.updatePoItemsIsFritted(item);
    },

    updatePoItemsIsFritted(item) {
      this.poItems.forEach((poItem) => {
        if (poItem.glass.g_code == item.g_code) {
          poItem.is_fritted = item.glass && (item.glass.fritted !== null && item.glass.fritted !== undefined && item.glass.fritted !== '')? 1 : 0;
        }
      })
    },
  },
};
</script>

<style scoped>
::v-deep
  #sub-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  background-color: #5f5f5f;
  height: 27px !important;
}

::v-deep .full {
  background-color: rgba(67, 255, 0, 0.1);
}

::v-deep .partial {
  background-color: rgba(255, 236, 0, 0.1);
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-right: 4px !important;
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
</style>
