var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"is-submit-btn":!_vm.isView,"submit-btn-text":_vm.item.id ? 'Update' : 'Create',"max-width":1000}},[_c('template',{slot:"header"},[_c('span',[_vm._v(_vm._s(_vm.item.id ? (_vm.isView ? '' : 'Update') : 'Create')+" Delivery Request "+_vm._s(_vm.item.id ? ("#" + (_vm.item.id)) : ''))])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('inventory-class-select-element',{attrs:{"is-add-all":false,"is-required":!_vm.isView,"disabled":_vm.isView},on:{"on-change":_vm.inventoryClassChange},model:{value:(_vm.item.inventory_class_id),callback:function ($$v) {_vm.$set(_vm.item, "inventory_class_id", $$v)},expression:"item.inventory_class_id"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.jobs,"item-text":function (item) { return ((item.code) + " - " + (item.name)); },"item-value":"id","clearable":"","no-data-text":!_vm.item.inventory_class_id
                  ? 'Select an Inventory Class first'
                  : 'No data available',"loading":_vm.loadingJobs,"disabled":(_vm.loadingJobs && _vm.jobs && _vm.jobs.length === 0) || _vm.isView,"label":"Job","rules":[function (v) { return !!v || 'Job is required'; }]},on:{"change":_vm.updateInventoryClass},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [(item.sub_class)?_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.sub_class ? item.sub_class.color : 'white'}},[_vm._v(" "+_vm._s(item && item.sub_class ? item.sub_class.name : '')+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976d2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [(item.sub_class)?_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.sub_class ? item.sub_class.color : 'white'}},[_vm._v(" "+_vm._s(item && item.sub_class ? item.sub_class.name : '')+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976d2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}}]),model:{value:(_vm.item.job_id),callback:function ($$v) {_vm.$set(_vm.item, "job_id", $$v)},expression:"item.job_id"}},[_c('template',{slot:"label"},[_vm._v(" Job"),(!_vm.isView)?_c('RedAsterisk'):_vm._e()],1)],2)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.projects,"item-text":"name","item-value":"id","clearable":"","loading":_vm.loadingProjects,"disabled":(_vm.loadingProjects && _vm.projects && _vm.projects.length === 0) ||
                _vm.isView,"rules":[function (v) { return !!v || 'Project is required'; }]},on:{"change":_vm.getJobs},model:{value:(_vm.item.project_id),callback:function ($$v) {_vm.$set(_vm.item, "project_id", $$v)},expression:"item.project_id"}},[_c('template',{slot:"label"},[_vm._v(" Project"),(!_vm.isView)?_c('RedAsterisk'):_vm._e()],1)],2)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-o"},[_c('v-autocomplete',{attrs:{"items":_vm.truckTypes,"item-text":"name","item-value":"id","label":"Truck Types","clearable":"","multiple":"","return-object":"","rules":[function (v) { return !!v || 'Truck Type is required'; }],"disabled":_vm.isView},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.item.truck_types),callback:function ($$v) {_vm.$set(_vm.item, "truck_types", $$v)},expression:"item.truck_types"}},[_c('template',{slot:"label"},[_vm._v(" Truck Type"),(!_vm.isView)?_c('RedAsterisk'):_vm._e()],1)],2)],1)],1),(!_vm.item.id)?_c('v-row',[_c('v-col',{staticClass:"pt-4"},[_c('v-textarea',{attrs:{"outlined":"","rows":"3","label":"Note","disabled":_vm.isView},model:{value:(_vm.item.comment),callback:function ($$v) {_vm.$set(_vm.item, "comment", $$v)},expression:"item.comment"}})],1)],1):_c('v-row',[_c('v-col',{staticClass:"pt-6"},[_c('v-row',[_c('v-col',{staticClass:"pt-4"},[_c('span',{style:({
                    color: _vm.isDarkMode ? '' : '#000000',
                    fontSize: '16px',
                  })},[_vm._v("Notes")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card-text',{staticStyle:{"max-height":"300px","overflow-y":"auto","border":"1px solid #d3d3d3","border-radius":"3px"}},[_c('v-list',{attrs:{"two-line":""}},[_vm._l((_vm.item.notes),function(note,index){return [_c('v-list-item',{key:note.id},[_c('v-list-item-content',[_vm._v(" "+_vm._s(note.text)+" "),_c('v-list-item-subtitle',{staticStyle:{"padding-top":"10px"}},[_vm._v(_vm._s(note.created_at))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(((note.user.first_name ? note.user.first_name : '') + " " + (note.user.last_name ? note.user.last_name : ''))))])],1)],1),(index < _vm.item.notes.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1)],1)],1)],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("Requested Delivery Date"),(!_vm.isView)?_c('RedAsterisk'):_vm._e()],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-date-picker',{attrs:{"full-width":"","min":_vm.minDeliveryDate,"disabled":_vm.isView},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }