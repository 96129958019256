<template>
  <v-container>
    <the-dialog-box
      ref="dialogBox"
      :submit="submit"
      :max-width="1000"
      :submit-btn-text="!isNew ? 'Save' : 'Create'"
      :cancel-btn-text="'Close'"
    >
      <template slot="header">
        <span>{{ !isNew ? 'Update' : 'Create' }} Inventory PO Item</span>
      </template>
      <template slot="body">
        <v-row>
          <v-col
            class="pa-3 ma-3"
            :style="{ backgroundColor: isDarkMode ? '' : '#E8F5E9', border: '2px solid rgba(0,0,0,0.2)', borderRadius: '4px'}"
          >
            <v-row>
              <v-col
                md="3"
                sm="6"
                class="pb-0"
              >
                <inventory-class-select-element
                  v-model="inventoryClassId"
                  :is-add-all="false"
                  :is-required="true"
                  :is4500="inventoryClassId === class4500Id"
                  :is4800="inventoryClassId === class4800Id"
                  :is-railings="inventoryClassId === classRailingsId"
                  :is-inst="inventoryClassId === classInstId"
                  :is-glass="inventoryClassId === classGlassId"
                  :is-commercial="inventoryClassId === classCommercialId"
                  :is-vwd-glass="inventoryClassId === classVWDGlass"
                  :is-panels="inventoryClassId === classPanelsId"
                  :is-sid="inventoryClassId === classSidgId"
                  :is-rail-part="inventoryClassId === classIdRailParts || inventoryClassId === classRailingsId"
                  :is-parts="inventoryClassId === classPartsId"
                  :is-paint="inventoryClassId === classPaintId"
                  :is-equipment="inventoryClassId === classEquipmentId"
                  :is-patio-door="inventoryClassId === classPatioDoorId"
                  :is-euro-door="inventoryClassId === classEuroDoorId"
                  :is-medical-aid="inventoryClassId === classMedicalAidId"
                />
              </v-col>
              <v-col
                md="6"
                sm="12"
                class="pb-0"
                v-if="!isPatioDoorClass"
              >
                <v-autocomplete
                  v-if="inventoryClassId == classPaintId"
                  v-model="item.glass_paint_code_id"
                  clearable
                  :items="glassPaintCodes"
                  item-value="id"
                  :loading="loadingInventoryItem"
                  :item-text="
                    (item) => item.code + ': ' + item.description
                  "
                  :rules="[(v) => !!v || 'Paint Code  is required']"
                >
                  <template slot="label">
                    Paint Code<RedAsterisk />
                  </template>
                  <template #item="{ item }">
                    <v-chip
                      class="mt-1 mb-1 mr-3"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      color="#8dbf8d"
                    >
                      PAINT
                    </v-chip><span style="font-weight: bold;">{{ item.code }}</span><span>{{ ': ' + item.description }}</span>
                  </template>
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #selection="{ item }">
                    <v-chip
                      class="mt-1 mb-1 mr-3"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      color="#8dbf8d"
                    >
                      PAINT
                    </v-chip><span style="font-weight: bold;">{{ item.code }}</span><span>{{ ': ' + item.description }}</span>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-else
                  v-model="item.inventory_item_id"
                  :items="inventoryItems"
                  item-value="id"
                  :loading="loadingInventoryItem"
                  :item-text="
                    (item) => item.material_id + ': ' + item.name
                  "
                  :rules="[(v) => !!v || 'Inventory Item is required']"
                  no-data-text="Select a Class first"
                  clearable
                  @change="getQuantityOnHand"
                >
                  >
                  <template slot="label">
                    Inventory Item<RedAsterisk />
                  </template>
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #item="{ item }">
                    <v-chip
                      class="mt-1 mb-1 mr-3"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="item && item.classes ? item.classes.color : 'white'"
                    >
                      {{ item && item.classes ? item.classes.name : '' }}
                    </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                  </template>
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #selection="{ item }">
                    <v-chip
                      class="mt-1 mb-1 mr-3"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="item && item.classes ? item.classes.color : 'white'"
                    >
                      {{ item && item.classes ? item.classes.name : '' }}
                    </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                md="3"
                sm="6"
                class="pb-0"
                v-if="isPatioDoorClass"
              >
                <v-text-field
                  v-model="item.ox_quantity"
                  prefix="OX:"
                  :rules="[(v) => v !== null && v !== undefined && v !== '' || 'OX Quantity is required', (v) => /^\d+$/.test(v) || 'Please enter a whole number']"
                  step="1"
                  type="number"
                  hint="Enter Quantity for OX Patio Doors"
                  persistent-hint
                >
                  <template slot="label">
                    Quantity<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                md="3"
                sm="6"
                class="pb-0"
                v-if="isPatioDoorClass"
              >
                <v-text-field
                  v-model="item.xo_quantity"
                  prefix="XO:"
                  :rules="[(v) => v !== null && v !== undefined && v !== '' || 'XO Quantity is required', (v) => /^\d+$/.test(v) || 'Please enter a whole number']"
                  step="1"
                  type="number"
                  hint="Enter Quantity for XO Patio Doors"
                  persistent-hint
                >
                  <template slot="label">
                    Quantity<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                md="3"
                sm="6"
                class="pb-0"
              >
                <v-autocomplete
                  v-model="item.uom_id"
                  :items="uoms"
                  auto-select-first
                  item-text="name"
                  item-value="id"
                  :loading="loadingUom"
                  :rules="[(v) => !!v || 'UOM is required']"
                  clearable
                >
                  <template slot="label">
                    UOM<RedAsterisk />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="!item.color_ids || item.color_ids.length != 0 || isTypeRepaint"
                cols="12"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="item.color_ids"
                  :items="colors"
                  item-text="code"
                  item-value="id"
                  :clearable="true"
                  :multiple="calculationTypeId === calculationTypeIdLength ? true : false"
                  :loading="loadingColors"
                  :hint="calculationTypeId === calculationTypeIdLength ? 'Picker will choose one of the selected colors' : 'Select color from the list'"
                  persistent-hint
                >
                  <template slot="label">
                    {{ calculationTypeId === calculationTypeIdLength ? 'Repaint from Colors' : 'Color' }}<RedAsterisk v-if="calculationTypeId === calculationTypeIdWeight" />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="inventoryClassId == classPanelsId || inventoryClassId == class4500Id || inventoryClassId == class4800Id || inventoryClassId == classRailingsId || inventoryClassId == classCommercialId"
                :cols="inventoryClassId == classRailingsId || inventoryClassId == classIdRailParts ? '4' : '6'"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="item.alloy_id"
                  :items="alloys"
                  item-text="name"
                  item-value="id"
                  clearable
                >
                  <template slot="label">
                    Alloy
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="inventoryClassId == classPanelsId || inventoryClassId == class4500Id || inventoryClassId == class4800Id || inventoryClassId == classRailingsId || inventoryClassId == classCommercialId"
                :cols="inventoryClassId == classRailingsId || inventoryClassId == classIdRailParts ? '4' : '6'"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="item.tempering_id"
                  :items="temperings"
                  item-text="name"
                  item-value="id"
                  clearable
                >
                  <template slot="label">
                    Tempering
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="inventoryClassId == classRailingsId || inventoryClassId == classIdRailParts || inventoryClassId == classPanelsId"
                cols="4"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="item.paint_on_option_id"
                  :items="paintOnOptions"
                  item-text="name"
                  item-value="id"
                  clearable
                >
                  <template slot="label">
                    Paint On
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="isPatioDoorClass"
                md="3"
                sm="6"
                class="pb-0 pt-0"
              >
                <v-text-field
                  v-model="item.height"
                  :rules="[(v) => !!v || 'Door height is required']"
                >
                  <template slot="label">
                    Door Height<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                v-if="isPatioDoorClass"
                md="3"
                sm="6"
                class="pb-0 pt-0"
              >
                <v-text-field
                  v-model="item.width"
                  :rules="[(v) => !!v || 'Door width is required']"
                >
                  <template slot="label">
                    Door Width<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                v-if="isPatioDoorClass"
                md="3"
                sm="6"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="item.color_id"
                  :items="colors"
                  item-text="code"
                  item-value="id"
                  :rules="[(v) => !!v || 'Interior color is required']"
                  :loading="loadingColors"
                  clearable
                >
                  <template slot="label">
                    Interior Color<RedAsterisk />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="isPatioDoorClass"
                md="3"
                sm="6"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="item.ex_color_id"
                  :items="colors"
                  item-text="code"
                  item-value="id"
                  :rules="[(v) => !!v || 'Exterior color is required']"
                  :loading="loadingColors"
                  clearable
                >
                  <template slot="label">
                    Exterior Color<RedAsterisk />
                  </template>
                </v-autocomplete>
              </v-col>
              <!-- <v-col
                v-if="isPatioDoorClass"
                md="3"
                sm="6"
                class="pb-0 pt-0"
              >
                <v-text-field
                  v-model="g_code"
                  readonly
                  @click="openGlassPoItemForm(item.glass)"
                  :rules="[(v) => !!v || 'Glass is required']"
                >
                  <template slot="label">
                    Glass<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col> -->
              <v-col
                v-if="isPatioDoorClass"
                md="3"
                sm="6"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="g_code"
                  :items="glassItems"
                  item-text="g_code"
                  item-value="g_code"
                  :rules="[(v) => !!v || 'Glass is required']"
                  clearable
                  return-object
                  @change="handleGlassChange"
                >
                  <template slot="label">
                    Glass<RedAsterisk />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="!isPatioDoorClass && inventoryClassId !== classGlassId"
                md="3"
                sm="6"
                class="pb-0 pt-0"
              >
                <v-autocomplete
                  v-model="item.length_id"
                  :items="lengths"
                  item-text="name"
                  item-value="id"
                  :disabled="!item.inventory_item_id"
                  :loading="loadingLength"
                  clearable
                >
                  <template slot="label">
                    Length
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                :md="inventoryClassId === classGlassId || isPatioDoorClass ? '9' : '6'"
                sm="3"
                class="pb-0 pt-0"
              >
                <v-text-field
                  v-model="item.price"
                  :rules="[(v) => !!v || 'Unit Price is required']"
                  type="number"
                  :suffix="`${isTax ? `Ontario HST 13%: $${(item.price * 0.13).toFixed(2)}` : ''} `"
                  :prefix="`${currency ? currency.symbol: ''}`"
                  min="0"
                  @blur="updateItemPrice"
                >
                  <template slot="label">
                    Unit Price<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                v-if="!isPatioDoorClass"
                md="3"
                sm="6"
                class="pb-0 pt-0"
              >
                <v-text-field
                  v-model="item.quantity"
                  :rules="[(v) => !!v || 'Quantity is required', (v) => /^\d+$/.test(v) || 'Please enter a whole number']"
                  type="number"
                  step="1"
                  min="0"
                >
                  <template slot="label">
                    Quantity<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col
                v-if="isPatioDoorClass"
                cols="6"
                class="pb-0 pt-0"
              >
                <v-radio-group
                  v-model="item.door_screen_id"
                  :rules="[(v) => !!v || 'Door screen selection is required']"
                  :disabled="!item.inventory_item_id"
                >
                  <template #label>
                    <span style="font-size: 1.2em; color: rgba(0, 0, 0, 0.65);">Screen<RedAsterisk /></span>
                  </template>
                  <v-row>
                    <v-col cols="auto">
                      <v-radio
                        label="WITH SCREEN"
                        :value="1"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-radio
                        label="WITHOUT SCREEN"
                        :value="2"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-col
                v-if="isPatioDoorClass"
                cols="6"
                class="pb-0 pt-0"
              >
                <v-radio-group
                  v-model="item.is_fritted"
                  :rules="[(v) => v !== null && v !== undefined && v !== '' || 'Fritted or Non-Fritted selection is required']"
                  :disabled="!!g_code"
                >
                  <template #label>
                    <span style="font-size: 1.2em; color: rgba(0, 0, 0, 0.65);">Fritted<RedAsterisk /> </span>
                    <span style="font-size: .8em; color: rgba(0, 0, 0, 0.65);"> (auto selected from Glass)</span>
                  </template>
                  <v-row>
                    <v-col cols="auto">
                      <v-radio
                        label="FRITTED"
                        :value="1"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-radio
                        label="NON-FRITTED"
                        :value="0"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pb-0 pt-0"
          >
            <span style="font-size: 18px;">Note</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-0 pb-0"
          >
            <v-textarea
              v-model="item.note"
              outlined
              rows="3"
            />
          </v-col>
        </v-row>
        <v-row v-if="!isPatioDoorClass">
          <v-col
            class="pt-20 pb-20"
          >
            <InventoryPoItemFormCurrentAndDangerLevelTable
              :inventory-item-id="item.inventory_item_id || item.glass_paint_code_id"
              :inventory-class-id="inventoryClassId"
              :locations="locations"
            />
          </v-col>
        </v-row>
      </template>
    </the-dialog-box>
    <inventory-item-show-image-form
      ref="itemShowImageForm"
    />
    <!-- <glass-po-item-form
      ref="glassPoItemForm"
      @success-po="glassPoItemSuccess"
    /> -->
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import InventoryPoItemFormCurrentAndDangerLevelTable from '@/components/forms/inventoryPos/InventoryPoItemFormCurrentAndDangerLevelTable';
// import GlassPoItemForm from '@/components/forms/glasses/GlassPOItemForm';
import * as ValidationHelpers from '@/helpers/ValidationHelpers';

export default {
  components: {
    InventoryItemShowImageForm,
    InventoryPoItemFormCurrentAndDangerLevelTable,
    // GlassPoItemForm,
  },
  props: {
    isTax: {
      type: Number,
      default: 1,
    },
    currency: {
      type: Object,
      default: null,
    },
    glassItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      inventory_item_id: null,
      glass_paint_code_id: null,
      uom_id: null,
      quantity: null,
      price: null,
      note: null,
      temp_id: null,
      length_id: null,
      color_ids: [],
      tempering_id: null,
      alloy_id: null,
      color_id: null,
      ex_color_id: null,
      height: null,
      width: null,
      glass: {},
      is_fritted: null,
      door_screen_id: 1,
      paint_on_option_id: null,
      ox_quantity: null,
      xo_quantity: null,
    },

    uoms: [],
    colors: [],
    lengths: [],
    locations: [],
    inventoryItems: [],
    glassPaintCodes: [],
    colorPrices: [],
    inventoryTags: [],
    alloys: [],
    temperings: [],
    doorScreens: [],

    quantityOnHand: 0,
    class4500Id: 1,
    class4800Id: 2,
    classRailingsId: 3,
    classInstId: 4,
    classGlassId: 5,
    classCommercialId: 6,
    classVWDGlass: 7,
    classPanelsId: 8,
    classSidgId: 9,
    classIdRailParts: 10,
    classPartsId: 11,
    classPaintId: 12,
    classEquipmentId: 13,
    classPatioDoorId: 14,
    classEuroDoorId: 15,
    classMedicalAidId: 16,
    isNew: false,
    loadingLength: false,
    colorPrice: null,
    colorPriceIdMillFinish: 1,
    isInit: false,
    inventoryClassId: null,
    loadingInventoryItem: false,
    loadingUom: false,
    typeIdNew: 1,
    typeIdRepaint: 2,
    inventoryPoTypes: [],
    loadingColors: false,
    inventoryPoTypeIds: [],
    isTypeRepaint: false,
    colorIdMillFinish: 9,
    calculationTypeId: null,
    calculationTypeIdLength: 1,
    calculationTypeIdWeight: 2,
    paintOnOptions: [],

    currentLevel: null,
    dangerLevel: null,
    uomIdBucket: 9,
    uomIdEach: 13,
    g_code: null,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
    filterHistoryRecordTypeId() {
      if (this.inventoryClassId === this.class4500Id || this.inventoryClassId === this.class4800Id || this.inventoryClassId === this.classCommercialId || this.inventoryClassId === this.classSidgId) return this.historyRecordTypeQrCodeId;
      if (this.inventoryClassId === this.classPartsId || this.inventoryClassId === this.classPaintId) return this.historyRecordTypeUpcCodeId;
      return this.historyRecordTypeQrCodeId; // need a default
    },

    isPatioDoorClass() {
      return this.inventoryClassId === this.classPatioDoorId;
    },

    getUomIdMap() {
      return {
        [this.classPaintId]: this.uomIdBucket,
        [this.classPatioDoorId]: this.uomIdEach,
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.inventory_item_id': function () {
      this.getUoms();
      if (this.isInit) return;
      this.triggerPriceChange();
    },
    // eslint-disable-next-line func-names
    'item.length_id': function () {
      if (this.isInit) return;
      this.triggerPriceChange();
    },
    // eslint-disable-next-line func-names
    'item.color_ids': function () {
      if (this.isInit) return;
      this.triggerPriceChange();
    },
    inventoryClassId() {
      if (this.isInit) return;
      this.getInventoryItems();
    },
  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async getLocations() {
      // need to get locations for the current inventory item TODO
      const res = await this.$api.location.get();

      if (!res) return;
      this.locations = res;
    },

    async getUoms() {
      const uomId = this.getUomIdMap[this.inventoryClassId] || null;

      let inventoryItemId = this.item.inventory_item_id;
      if (this.inventoryClassId === this.classPaintId || this.inventoryClassId === this.classPatioDoorId) {
        inventoryItemId = null;
      }
    
      const submitData = {
        filters:
        {
          id: uomId,
          inventory_item_id: inventoryItemId,
        },
      };
      this.uoms = await this.$api.uom.read(submitData);
      if (this.uoms.length === 1) {
        this.item.uom_id = this.uoms[0].id;
      }
    },

    async getInventoryItems() {
      this.loadingInventoryItem = true;
      this.inventoryItems = [];
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      this.loadingInventoryItem = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },

    async getGlassPaintCodes() {
      this.loading = true;
      const submitModel = {
        filters: {
          is_awd_paint: true,
        },
      };
      this.glassPaintCodes = await this.$api.glassPaintCode.read(submitModel);
      this.loading = false;
    },

    async getLengths() {
      this.loadingLength = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.item ? this.item.inventory_item_id : null,
          inventory_class_id: this.item.inventory_class_id,
        },
      };
      this.lengths = await this.$api.length.read(submitData);
      this.loadingLength = false;
    },

    async getTypes() {
      this.inventoryPoTypes = await this.$api.inventoryPoType.get();
    },

    async getColors() {
      this.loadingColors = true;
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
      this.loadingColors = false;
    },

    async getColorPrices() {
      const submitModel = {
        filters: {
        },
      };
      this.colorPrices = await this.$api.inventoryPoColorPrice.read(submitModel);
    },

    async getQuantityOnHand() {
      await this.getInventoryTags();
    },

    async getInventoryTags() {
      const submitModel = {
        filters: {
          inventory_item_id: this.item.inventory_item_id,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (!res || !res.data) {
        return;
      }
      this.inventoryTags = res.data;
    },

    async getAlloys() {
      const submitData = {
        filters:
        {
          inventory_class_id: this.inventoryClassId === this.classPanelsId ? this.inventoryClassId : null,
        },
      };
      this.alloys = await this.$api.alloy.read(submitData);
    },

    async getTemperings() {
      const submitData = {
        filters:
        {
          inventory_class_id: this.inventoryClassId === this.classPanelsId ? this.inventoryClassId : null,
        },
      };
      this.temperings = await this.$api.tempering.read(submitData);
    },

    async getDoorScreens() {
      this.doorScreens = await this.$api.doorScreen.get();
    },

    async getPaintOnOptions() {
      this.paintOnOptions = await this.$api.paintOnOption.get();
    },

    async submit() {
      if (this.isPatioDoorClass && (!this.isDecimalInFractions(this.item.width) || !this.isDecimalInFractions(this.item.height))) {
        this.$bus.$emit('showError', 'Incorrect Width or Height value');
        return false;
      }

      if (this.item.inventory_item_id) {
        const inventoryItem = this.inventoryItems.find((x) => x.id === this.item.inventory_item_id);
        if (inventoryItem) {
          this.item.item_code = inventoryItem.material_id;
          this.item.item_name = inventoryItem.name;
          this.item.weight = inventoryItem.weight;
        }
      }

      if (this.item.glass_paint_code_id) {
        const glassPaintCode = this.glassPaintCodes.find((x) => x.id === this.item.glass_paint_code_id);
        if (glassPaintCode) {
          this.item.item_code = glassPaintCode.code;
          this.item.item_name = glassPaintCode.description;
        }
      }

      if (this.item.uom_id) {
        const uom = this.uoms.find((x) => x.id === this.item.uom_id);
        if (uom) {
          this.item.uom_name = uom.name;
        }
      }

      if (this.item.length_id) {
        const length = this.lengths.find((x) => x.id === this.item.length_id);
        if (length) {
          this.item.length_name = length.name;
        }
      }

      if (this.calculationTypeId === this.calculationTypeIdLength) {
        const colors = this.colors.filter((x) => this.item.color_ids.includes(x.id));
        if (colors) {
          this.item.color_names = colors.map((x) => x.code).join(', ');
        }
      } else {
        const colors = this.colors.find((x) => x.id === this.item.color_ids);
        if (colors) {
          this.item.color_names = colors.code;
        }
      }

      if (this.item.color_id) {
        const color = this.colors.find((x) => x.id === this.item.color_id);
        if (color) {
          this.item.color = color;
        }
      }

      if (this.item.ex_color_id) {
        const color = this.colors.find((x) => x.id === this.item.ex_color_id);
        if (color) {
          this.item.ex_color = color;
        }
      }

      if (this.item.door_screen_id) {
        const doorScreen = this.doorScreens.find((x) => x.id === this.item.door_screen_id);
        if (doorScreen) {
          this.item.door_screen = doorScreen;
        }
      }

      if (this.isPatioDoorClass) {
        this.item.quantity = (parseInt(this.item.xo_quantity, 10) || 0) + (parseInt(this.item.ox_quantity, 10) || 0);
      }

      this.item.glass = JSON.parse(JSON.stringify(this.item.glass));
      this.item.total = this.item.quantity * this.item.price;
      this.$bus.$emit('showSuccess');
      this.$emit('success', { ...JSON.parse(JSON.stringify(this.item)) });
      return true;
    },

    updateItemPrice() {
      if (this.inventoryClassId !== this.classPartsId) return;
      const submitModel = {
        id: this.item.inventory_item_id,
        price: this.item.price,
      };
      this.$api.inventoryItem.update(submitModel, 'price');
    },

    open(item, inventoryClassId, inventoryPoTypeIds, colorPrice, calculationTypeId, openReceive = false) {
      this.isInit = true;
      this.isTypeRepaint = false;
      this.inventoryClassId = inventoryClassId;
      this.colorPrice = colorPrice;
      this.isTypeRepaint = inventoryPoTypeIds.includes(this.typeIdRepaint);
      this.calculationTypeId = calculationTypeId;
      this.currentLevel = null;
      this.$refs.dialogBox.open();
      if (item) {
        this.item = { ...item };
        if (calculationTypeId === this.calculationTypeIdWeight) {
          this.item.color_ids = item.color_ids;
        }
        this.g_code = this.item.glass ? this.item.glass.g_code : null;
        this.isNew = false;
      } else {
        this.item = { ...this.itemDto };
        this.item.temp_id = Date.now();
        if (inventoryClassId === this.classPaintId) {
          this.item.uom_id = this.uomIdBucket;
        } else if (inventoryClassId === this.classPatioDoorId) {
          this.item.uom_id = this.uomIdEach;
        }

        this.isNew = true;

        if (this.isTypeRepaint) {
          const colorIdMillFinish = 9;
          this.item.color_ids = [colorIdMillFinish];
        }
      }
      this.$nextTick(async () => {
        this.isInit = false;
      });

      this.getInventoryItems();
      this.getUoms();
      this.getLengths();
      this.getTypes();
      this.getColors();
      this.getColorPrices();
      this.getGlassPaintCodes();
      this.getAlloys();
      this.getTemperings();
      this.getDoorScreens();
      this.getLocations();
      this.getPaintOnOptions();
    },

    openGlassPoItemForm(item) {
      this.$refs.glassPoItemForm.open(item, null, true);
    },

    // glassPoItemSuccess(item) {
    //   if (!item) return;
    //   this.item.glass = item;
    //   this.g_code = item.g_code;
    //   this.item.is_fritted = item.glass && item.glass.fritted ? 1 : 0;
    // },

    handleGlassChange(item) {
      if (!item) return;
      this.item.glass = item;
      this.g_code = item.g_code;
      this.item.is_fritted = item.glass && item.glass.fritted ? 1 : 0;
    },

    triggerPriceChange() {
      if (this.inventoryClassId === this.classPartsId) {
        this.findItemPrice();
      } else if (this.calculationTypeId === this.calculationTypeIdLength) {
        this.triggerPriceChangeLength();
      } else {
        this.triggerPriceChangeWeight();
      }
    },

    findItemPrice() {
      if (!this.item.inventory_item_id) return;

      const inventoryItem = this.inventoryItems.find((x) => x.id === this.item.inventory_item_id);

      if (!inventoryItem || inventoryItem.inventory_class_id !== this.classPartsId || !inventoryItem.price) return;

      this.item.price = inventoryItem.price;
    },

    triggerPriceChangeLength() {
      if (!this.item.length_id || !this.colorPrice) return;
      const length = this.lengths.find((x) => x.id === this.item.length_id);
      if (!length) {
        this.item.price = 0;
        return;
      }
      const lengthNumber = length.name.split(' ')[0];
      if (isNaN(lengthNumber) || !lengthNumber) {
        this.item.price = 0;
        return;
      }
      let colorPrice = null;
      // If Repaint calculate unit price based on Color selected and Length
      if (this.isTypeRepaint) {
        let additionExpense = 0;
        // If Repainting over Mill Finish color, then use price from colorPrice plus addition expense
        if (this.item.color_ids.includes(this.colorIdMillFinish)) {
          colorPrice = this.colorPrice;
        } else {
          colorPrice = this.colorPrice;
          additionExpense = this.colorPrice.additional_expense;
        }
        const price = lengthNumber * (colorPrice.extrusion_price + additionExpense);
        this.item.price = parseFloat(price.toFixed(5));
      }
    },

    triggerPriceChangeWeight() {
      if (!this.item.inventory_item_id || !this.item.color_ids) return;
      const inventoryItem = this.inventoryItems.find((x) => x.id === this.item.inventory_item_id);
      if (!inventoryItem) {
        this.item.price = 0;
        return;
      }
      const { weight } = inventoryItem;
      if (isNaN(weight) || !weight) {
        this.item.price = 0;
        return;
      }
      // If Repaint calculate unit price based on Color selected and Weight
      // Price formular = Die default weight * Length in Meters * Color price by Die type (AH or AS for Mill Finish) + (Non Mill Finish color price * Length in Meters)[Only if Non Mill Finish color selected]
      const length = this.lengths.find((x) => x.id === this.item.length_id);
      if (!length) {
        this.item.price = 0;
        return;
      }
      const lengthNumber = length.name.split(' ')[0];
      if (isNaN(lengthNumber) || !lengthNumber) {
        this.item.price = 0;
        return;
      }
      const lengthNumberInMeter = lengthNumber * 0.3048;

      const itemLengthPrice = weight * lengthNumberInMeter;

      const color = this.colors.find((x) => x.id === this.item.color_ids);
      if (!color) return;
      const colorPrice = this.colorPrices.find((x) => color.code.toUpperCase().includes(x.code.toUpperCase()));

      const millFinishColorPrice = this.colorPrices.find((x) => x.id === this.colorPriceIdMillFinish);
      if (!millFinishColorPrice) {
        this.item.price = 0;
        return;
      }

      let dieColorPrice = 0;
      // check type of itenventory item
      if (inventoryItem.material_id.substring(0, 2) === 'AH') {
        dieColorPrice = millFinishColorPrice.inventory_hollow_price;
      } else if (inventoryItem.material_id.substring(0, 2) === 'AS') {
        dieColorPrice = millFinishColorPrice.inventory_solid_price;
      } else {
        this.item.price = 0;
      }

      let price = itemLengthPrice * dieColorPrice;

      if (colorPrice.id !== this.colorPriceIdMillFinish) {
        let paintingColorPrice = 0;

        if (inventoryItem.material_id.substring(0, 2) === 'AH') {
          paintingColorPrice = colorPrice.inventory_hollow_price;
        } else if (inventoryItem.material_id.substring(0, 2) === 'AS') {
          paintingColorPrice = colorPrice.inventory_solid_price;
        } else {
          this.item.price = 0;
        }

        price += (lengthNumberInMeter * paintingColorPrice);
      }

      this.item.price = parseFloat(price.toFixed(5));
    },

    isDecimalInFractions(decimalNumber) {
      return ValidationHelpers.isDecimalInFractions(decimalNumber);
    },
  },
};
</script>
