<template>
  <v-container fluid>
    <!-- Role 31: Both v-data-table and v-calendar -->
    <div v-if="isDeliveriesManager || isAdmin">
      <v-container class="mt-3" fluid>
        <v-row>
          <v-col>
            <v-card elevation="0" outlined>
              <v-card-title>
                <v-row>
                  <v-col>Delivery Requests</v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      outlined
                      @click="openTruckRequestForm(null)"
                    >
                      <v-icon small class="mr-1">fas fa-plus</v-icon>
                      Delivery Request
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="truckRequests"
                :footer-props="footer"
                :items-per-page="pagination.itemsPerPage"
                :search="search"
                :loading="loadingTruckRequests"
                dense
                fixed-header
                :height="windowHalfHeight"
                :server-items-length="total"
                @pagination="updatePagination"
              >
                <template #item="{ item }">
                  <tr :ref="`row-${item.id}`" :key="item.id">
                    <td>
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <div v-on="on">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              draggable="true"
                              @dragstart="onDragStart($event, item, item.id)"
                              @dragend="onDragEnd"
                              :disabled="isTruckRequestScheduled(item)"
                            >
                              <v-icon small> fa-solid fa-bars </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span v-if="isTruckRequestScheduled(item)">
                          Delivery Request Already Scheduled
                        </span>
                        <span v-else> Drag to + Button to Schedule </span>
                      </v-tooltip>
                    </td>
                    <td class="text-center">
                      <v-chip
                        v-if="item.class"
                        small
                        :style="{
                          background:
                            item && item.class ? item.class.color : 'grey',
                        }"
                        text-color="white"
                      >
                        {{ item && item.class ? item.class.name : '' }}
                      </v-chip>
                    </td>
                    <td>{{ item.id }}</td>
                    <td>{{ item.project.name }}</td>
                    <td>
                      <span style="font-weight: bold">
                        <text-highlight :queries="search">{{
                          item.job_code ? `${item.job_code}` : ''
                        }}</text-highlight>
                      </span>
                      <text-highlight :queries="search">
                        -
                        {{
                          item.job_name
                            ? item.job_name
                            : item.project
                            ? item.project.name
                            : ''
                        }}</text-highlight
                      >
                      <span style="color: #1976d2">
                        <text-highlight :queries="search">{{
                          item.job_reference ? `; ${item.job_reference}` : ''
                        }}</text-highlight>
                      </span>
                    </td>
                    <td class="text-center">
                      <v-row
                        v-if="item.truck_types && item.truck_types.length > 1"
                      >
                        <v-col class="pl-1">
                          <v-tooltip top color="black">
                            <template #activator="{ on }">
                              <span style="color: #1976d2" v-on="on"
                                >TRUCK TYPES</span
                              >
                            </template>
                            <v-row>
                              <v-col class="text-center pt-6 pb-6">
                                <v-row
                                  v-for="truck_type in item.truck_types"
                                  :key="truck_type.id"
                                >
                                  <v-col cols="auto" class="pr-1 pb-1 pt-1">
                                    <v-chip
                                      class="mt-1 mb-1"
                                      small
                                      text-color="white"
                                      style="cursor: pointer"
                                      :color="
                                        truck_type ? truck_type.color : 'white'
                                      "
                                    >
                                      {{ truck_type ? truck_type.name : '' }}
                                    </v-chip>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-chip
                        v-else
                        class="mt-1 mb-1"
                        small
                        text-color="white"
                        style="cursor: pointer"
                        :color="
                          item.truck_types[0]
                            ? item.truck_types[0].color
                            : 'white'
                        "
                      >
                        {{
                          item.truck_types[0] ? item.truck_types[0].name : ''
                        }}
                      </v-chip>
                    </td>
                    <td>
                      <text-highlight :queries="search">
                        {{ item && item.date ? item.date : '-' }}
                      </text-highlight>
                    </td>
                    <td>
                      <text-highlight :queries="search">
                        {{ item && item.slot ? item.slot.date : '-' }}
                      </text-highlight>
                    </td>
                    <td>
                      <text-highlight :queries="search">
                        {{
                          item && item.created_by_user_full_name
                            ? item.created_by_user_full_name
                            : '-'
                        }}
                      </text-highlight>
                    </td>
                    <td>
                      <text-highlight :queries="search">
                        {{ item && item.slot_id ? item.slot_id : '-' }}
                      </text-highlight>
                    </td>
                    <td class="text-center">
                      <v-chip
                        small
                        :style="{
                          background:
                            item && item.status ? item.status.color : 'grey',
                        }"
                        text-color="white"
                      >
                        <text-highlight :queries="search">
                          {{ item && item.status ? item.status.name : '' }}
                        </text-highlight>
                      </v-chip>
                    </td>
                    <td class="text-right">
                      <v-btn
                        v-if="item.bill_of_ladings.length > 0"
                        class="ml-1 mt-1 mb-1"
                        color="success"
                        outlined
                        small
                        @click="openBillOfLadingList(item)"
                      >
                        BOL
                      </v-btn>
                      <v-btn
                        v-if="!isTruckRequestScheduled(item)"
                        class="ml-1 mt-1 mb-1"
                        color="purple"
                        outlined
                        small
                        @click="openManualScheduleForm(item)"
                      >
                        SCHEDULE
                      </v-btn>
                      <!-- <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openTruckRequestForm(item)"
                  >
                    EDIT
                  </v-btn> -->
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        @click="openTruckRequestForm(item, true)"
                      >
                        VIEW
                      </v-btn>
                      <v-menu
                        v-if="!truckRequestHasBillOfLadings(item)"
                        offset-y
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="ml-1 mt-1 mb-1"
                            elevation="0"
                            fab
                            outlined
                            x-small
                            v-bind="attrs"
                            tile
                            color="primary"
                            style="
                              border-radius: 7px;
                              width: 28px !important;
                              height: 28px !important;
                            "
                            v-on="on"
                          >
                            <v-icon> mdi-dots-vertical </v-icon>
                          </v-btn>
                        </template>
                        <v-list class="pa-3">
                          <v-list-item @click="openTruckRequestForm(item)">
                            <v-list-item-title
                              :style="`color: green; font-weight: 600;`"
                            >
                              <v-row align="center">
                                <v-col
                                  cols="auto"
                                  class="pr-0"
                                  style="white-space: nowrap"
                                />
                                <v-col class="pt-4"> EDIT </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="softDeleteTruckRequest(item.id)">
                            <v-list-item-title
                              :style="`color: red; font-weight: 600;`"
                            >
                              <v-row align="center">
                                <v-col
                                  cols="auto"
                                  class="pr-0"
                                  style="white-space: nowrap"
                                />
                                <v-col class="pt-4"> DELETE </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-card elevation="0" outlined>
              <v-card-title>
                <v-row>
                  <v-col cols="auto"> Scheduled Deliveries </v-col>
                  <v-col cols="1">
                    <v-select
                      v-model="calendarType"
                      :items="calendarTypes"
                      dense
                      hide-details
                      label="Type"
                      item-value="type"
                    >
                      <template #item="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto"> </v-col>
                  <v-col cols="auto" class="pr-0 pl-0">
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon> mdi-chevron-left </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="pr-0 pl-0">
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="pt-4 pl-0">
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <div class="calendar-container">
                <v-calendar
                  ref="calendar"
                  v-model="focusDay"
                  :weekdays="weekday"
                  :type="calendarType"
                  :event-more="false"
                  :events="events"
                  event-overlap-mode="column"
                  interval-count="0"
                  color="primary"
                  @dragover.prevent
                  @drop="onCalendarDrop"
                >
                  <template #day-label-header="dayLabelHeader">
                    <!-- for week and day view  -->
                    <v-row>
                      <v-col
                        v-if="holidaysObj[dayLabelHeader.date]"
                        style="font-weight: bold"
                      >
                        {{ holidaysObj[dayLabelHeader.date] }}
                      </v-col>
                      <v-col v-else>
                        {{ dayLabelHeader.day }}
                      </v-col>
                    </v-row>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div v-on="on">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            id="drop-event-btn"
                            :class="`m-2 ${dropEventColorClass(dayLabelHeader.date)} ${draggingOverClass(dayLabelHeader.date)}`"
                            :outlined="isDraggingOverDate !== dayLabelHeader.date"
                            medium
                            label
                            @dragover="onDragOver($event, dayLabelHeader.date)"
                            @dragleave="onDragLeave"
                            @drop="onAddEventDrop($event, dayLabelHeader)"
                            :disabled="
                              isAddEventDisabled ||
                              dayLabelHeader.date < currentDate
                            "
                          >
                            DROP NEW DELIVERY HERE
                          </v-btn>
                        </div>
                      </template>
                      <span>
                        Drag a Truck Request here to Create a New Scheduled
                        Delivery
                      </span>
                    </v-tooltip>
                  </template>
                  <template #day-label="dayLabel">
                    <!-- For Month View -->
                    <v-row>
                      <v-col
                        v-if="holidaysObj[dayLabel.date]"
                        style="font-weight: bold"
                      >
                        {{ holidaysObj[dayLabel.date] }}
                      </v-col>
                      <v-col v-else>
                        {{ dayLabel.day }}
                      </v-col>
                    </v-row>
                    <!-- For Month View -->
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div v-on="on" class="mt-2 mb-2">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            id="drop-event-btn"
                            :class="`${dropEventColorClass(dayLabel.date)} ${draggingOverClass(dayLabel.date)}`"
                            :outlined="isDraggingOverDate !== dayLabel.date"
                            medium
                            label
                            @dragover="onDragOver($event, dayLabel.date)"
                            @dragleave="onDragLeave"
                            @drop="onAddEventDrop($event, dayLabel)"
                            :disabled="
                              isAddEventDisabled ||
                              dayLabel.date < currentDate
                            "
                          >
                            DROP NEW DELIVERY HERE
                          </v-btn>
                        </div>
                      </template>
                      <span>
                        Drag a truck request here to create a new scheduled
                        delivery
                      </span>
                    </v-tooltip>
                  </template>
                  <!-- Slot for customizing events -->
                  <template #event="{ event, attrs }">
                    <v-card
                      class="calendar-event pr-1 pl-1"
                      :style="{ background: '#ffffff', border: '1px solid #e0e0e0', borderLeft: `8px solid ${getEventColor(event)}`, borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', height: `${getEventHeight(event)}px` }"
                      tile
                      :data-event-id="event.id"
                      @dragover.prevent
                      @drop="onEventDrop($event, event)"
                      @click="openScheduledDeliveryForm(event)"
                      v-bind="attrs"
                    >
                      <!-- <v-chip
                        small
                        :color="event && event.status ? event.status.color : ''"
                        :style="{
                          position: 'absolute',
                          top: '4px',
                          left: '4px',
                          zIndex: 1,
                          color: 'white',
                        }"
                      >
                        {{ event && event.status ? event.status.name : '' }}
                      </v-chip> -->
                      <v-btn
                        v-if="!scheduledDeliveryHasBillOfLadings(event)"
                        icon
                        class="close-icon"
                        :style="{
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          zIndex: 1,
                        }"
                        @click.stop="softDeleteScheduledDelivery(event.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-card-title class="pa-1">
                        <v-row justify="center" align="center">
                          <v-col cols="auto">
                            <v-chip
                              label
                              small
                              :color="
                                event && event.status ? lightenColor(event.status.color) : ''
                              "
                              :style="{fontWeight: 'bold'}"
                              :text-color="event.status.color"
                            >
                              {{
                                event && event.status ? event.status.name : ''
                              }}
                            </v-chip>
                          </v-col>
                          <v-col>
                            <span
                              :style="{ fontSize: '.9rem' }"
                            >
                              {{ event.name }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-list
                          class="pa-0"
                          :style="{ backgroundColor: 'transparent' }"
                        >
                          <template v-for="(request, index) in event.requests">
                            <div v-if="index < 4" :key="index" :style="{}">
                              <v-divider v-if="index !== 0" />
                              <v-list-item
                                v-if="index < 3"
                                :style="{
                                  fontSize: '.9rem',
                                  minHeight: '36px !important',
                                  fontWeight: 'bold',
                                }"
                              >
                                {{
                                  request.job && request.job.code
                                    ? `${request.job.code}`
                                    : ''
                                }}
                                -
                                {{
                                  request.job && request.job.name
                                    ? request.job.name
                                    : request.project && request.project.name
                                    ? request.project.name
                                    : ''
                                }}
                                {{
                                  request.job && request.job.reference
                                    ? `; ${request.job.reference}`
                                    : ''
                                }}
                              </v-list-item>
                              <v-list-item
                                v-else
                                :style="{
                                  fontSize: '0.8rem',
                                  justifyContent: 'flex-end',
                                }"
                              >
                                {{ `${event.requests.length - 3} more` }}
                              </v-list-item>
                            </div>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-calendar>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Role 32: Tabs for v-data-table and v-calendar -->
    <div v-else-if="isDeliveriesRequester">
      <v-row>
        <v-col class="pb-0">
          <v-tabs v-model="selectedTab">
            <v-tabs v-model="selectedTab">
              <v-tab v-for="(tab, index) in tabs" :key="index">
                {{ tab }}
              </v-tab>
            </v-tabs>
          </v-tabs>
        </v-col>
      </v-row>
      <v-container class="mt-3" fluid>
        <v-row v-if="selectedTab == 0">
          <v-col>
            <v-row>
              <v-spacer />
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  outlined
                  @click="openTruckRequestForm(null)"
                >
                  <v-icon small class="mr-1">fas fa-plus</v-icon>
                  Delivery Request
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-card elevation="0" outlined>
                  <v-data-table
                    :headers="headers"
                    :items="truckRequests"
                    :footer-props="footer"
                    :items-per-page="pagination.itemsPerPage"
                    :search="search"
                    :loading="loadingTruckRequests"
                    :height="windowHeight"
                    dense
                    fixed-header
                    :server-items-length="total"
                    @pagination="updatePagination"
                  >
                    <template #item="{ item }">
                      <tr :ref="`row-${item.id}`" :key="item.id">
                        <td class="text-center">
                          <v-chip
                            v-if="item.class"
                            small
                            :style="{
                              background:
                                item && item.class ? item.class.color : 'grey',
                            }"
                            text-color="white"
                          >
                            {{ item && item.class ? item.class.name : '' }}
                          </v-chip>
                        </td>
                        <td>{{ item.id }}</td>
                        <td>{{ item.project.name }}</td>
                        <td>
                          <span style="font-weight: bold">
                            <text-highlight :queries="search">{{
                              item.job_code ? `${item.job_code}` : ''
                            }}</text-highlight>
                          </span>
                          <text-highlight :queries="search">
                            -
                            {{
                              item.job_name
                                ? item.job_name
                                : item.project
                                ? item.project.name
                                : ''
                            }}</text-highlight
                          >
                          <span style="color: #1976d2">
                            <text-highlight :queries="search">{{
                              item.job_reference
                                ? `; ${item.job_reference}`
                                : ''
                            }}</text-highlight>
                          </span>
                        </td>
                        <td class="text-center">
                          <v-row
                            v-if="
                              item.truck_types && item.truck_types.length > 1
                            "
                          >
                            <v-col class="pl-1">
                              <v-tooltip top color="black">
                                <template #activator="{ on }">
                                  <span style="color: #1976d2" v-on="on"
                                    >TRUCK TYPES</span
                                  >
                                </template>
                                <v-row>
                                  <v-col class="text-center pt-6 pb-6">
                                    <v-row
                                      v-for="truck_type in item.truck_types"
                                      :key="truck_type.id"
                                    >
                                      <v-col cols="auto" class="pr-1 pb-1 pt-1">
                                        <v-chip
                                          class="mt-1 mb-1"
                                          small
                                          text-color="white"
                                          style="cursor: pointer"
                                          :color="
                                            truck_type
                                              ? truck_type.color
                                              : 'white'
                                          "
                                        >
                                          {{
                                            truck_type ? truck_type.name : ''
                                          }}
                                        </v-chip>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-chip
                            v-else
                            class="mt-1 mb-1"
                            small
                            text-color="white"
                            style="cursor: pointer"
                            :color="
                              item.truck_types[0]
                                ? item.truck_types[0].color
                                : 'white'
                            "
                          >
                            {{
                              item.truck_types[0]
                                ? item.truck_types[0].name
                                : ''
                            }}
                          </v-chip>
                        </td>
                        <td>
                          <text-highlight :queries="search">
                            {{ item && item.date ? item.date : '-' }}
                          </text-highlight>
                        </td>
                        <td>
                          <text-highlight :queries="search">
                            {{ item && item.slot ? item.slot.date : '-' }}
                          </text-highlight>
                        </td>
                        <td>
                          <text-highlight :queries="search">
                            {{
                              item && item.created_by_user_full_name
                                ? item.created_by_user_full_name
                                : '-'
                            }}
                          </text-highlight>
                        </td>
                        <td>
                          <text-highlight :queries="search">
                            {{ item && item.slot_id ? item.slot_id : '-' }}
                          </text-highlight>
                        </td>
                        <td class="text-center">
                          <v-chip
                            small
                            :style="{
                              background:
                                item && item.status
                                  ? item.status.color
                                  : 'grey',
                            }"
                            text-color="white"
                          >
                            <text-highlight :queries="search">
                              {{ item && item.status ? item.status.name : '' }}
                            </text-highlight>
                          </v-chip>
                        </td>
                        <td class="text-right">
                          <v-btn
                            v-if="item.bill_of_ladings.length > 0"
                            class="ml-1 mt-1 mb-1"
                            color="success"
                            outlined
                            small
                            @click="openBillOfLadingList(item)"
                          >
                            BOL
                          </v-btn>
                          <!-- <v-btn
                            class="ml-1 mt-1 mb-1"
                            color="primary"
                            outlined
                            small
                            @click="openTruckRequestForm(item)"
                          >
                            EDIT
                          </v-btn> -->
                          <v-btn
                            class="ml-1 mt-1 mb-1"
                            color="primary"
                            outlined
                            small
                            @click="openTruckRequestForm(item, true)"
                          >
                            VIEW
                          </v-btn>
                          <v-menu
                            v-if="
                              belongsToRequester(item) &&
                              !truckRequestHasBillOfLadings(item)
                            "
                            offset-y
                          >
                            <template #activator="{ on, attrs }">
                              <v-btn
                                class="ml-1 mt-1 mb-1"
                                elevation="0"
                                fab
                                outlined
                                x-small
                                v-bind="attrs"
                                tile
                                color="primary"
                                style="
                                  border-radius: 7px;
                                  width: 28px !important;
                                  height: 28px !important;
                                "
                                v-on="on"
                              >
                                <v-icon> mdi-dots-vertical </v-icon>
                              </v-btn>
                            </template>
                            <v-list class="pa-3">
                              <v-list-item @click="openTruckRequestForm(item)">
                                <v-list-item-title
                                  :style="`color: green; font-weight: 600;`"
                                >
                                  <v-row align="center">
                                    <v-col
                                      cols="auto"
                                      class="pr-0"
                                      style="white-space: nowrap"
                                    />
                                    <v-col class="pt-4"> EDIT </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                @click="softDeleteTruckRequest(item.id)"
                              >
                                <v-list-item-title
                                  :style="`color: red; font-weight: 600;`"
                                >
                                  <v-row align="center">
                                    <v-col
                                      cols="auto"
                                      class="pr-0"
                                      style="white-space: nowrap"
                                    />
                                    <v-col class="pt-4"> DELETE </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="selectedTab == 1">
          <v-col>
            <v-row>
              <v-col cols="1">
                <v-select
                  v-model="calendarType"
                  :items="calendarTypes"
                  dense
                  hide-details
                  label="Type"
                  item-value="type"
                >
                  <template #item="{ item }">
                    <v-chip
                      class="mt-1 mb-1"
                      small
                      text-color="white"
                      style="cursor: pointer"
                      :color="item ? item.color : 'white'"
                    >
                      {{ item ? item.name : '' }}
                    </v-chip>
                  </template>
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #selection="{ item }">
                    <v-chip
                      class="mt-1 mb-1"
                      small
                      text-color="white"
                      style="cursor: pointer"
                      :color="item ? item.color : 'white'"
                    >
                      {{ item ? item.name : '' }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-spacer />
              <v-col cols="auto"> </v-col>
              <v-col cols="auto" class="pr-0 pl-0">
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon> mdi-chevron-left </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="pr-0 pl-0">
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="pt-4 pl-0">
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-card elevation="0" outlined>
                  <div class="calendar-container">
                    <v-calendar
                      ref="calendar"
                      v-model="focusDay"
                      :weekdays="weekday"
                      :type="calendarType"
                      :event-more="false"
                      :events="events"
                      event-overlap-mode="column"
                      :event-color="getEventColor"
                      :event-height="200"
                      interval-count="0"
                      color="primary"
                      @dragover.prevent
                      @drop="onCalendarDrop"
                    >
                      <template #day-label="dayLabel">
                        <!-- for month view  -->
                        <v-row>
                          <v-col
                            v-if="holidaysObj[dayLabel.date]"
                            style="font-weight: bold"
                          >
                            {{ holidaysObj[dayLabel.date] }}
                          </v-col>
                          <v-col v-else>
                            {{ dayLabel.day }}
                          </v-col>
                        </v-row>
                      </template>
                      <!-- Slot for customizing events -->
                      <template #event="{ event }">
                        <v-card
                          class="calendar-event pr-1 pl-1"
                          :style="{ background: getEventColor(event) }"
                          tile
                          :data-event-id="event.id"
                          @dragover.prevent
                          @drop="onEventDrop($event, event)"
                          :height="200"
                          @click="openScheduledDeliveryForm(event)"
                        >
                          <v-card-title class="pa-1">
                            <v-row justify="center" align="center">
                              <v-col cols="auto">
                                <v-chip
                                  small
                                  :color="
                                    event && event.status
                                      ? event.status.color
                                      : ''
                                  "
                                  text-color="white"
                                >
                                  {{
                                    event && event.status
                                      ? event.status.name
                                      : ''
                                  }}
                                </v-chip>
                              </v-col>
                              <v-col>
                                <span
                                  :style="{ color: 'white', fontSize: '.9rem' }"
                                >
                                  {{ event.name }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text>
                            <v-list
                              class="pa-0"
                              :style="{ backgroundColor: 'transparent' }"
                            >
                              <template
                                v-for="(request, index) in event.requests"
                              >
                                <div v-if="index < 4" :key="index" :style="{}">
                                  <v-divider v-if="index !== 0" />
                                  <v-list-item
                                    v-if="index < 3"
                                    :style="{
                                      color: 'white !important',
                                      fontSize: '.9rem',
                                      minHeight: '36px !important',
                                      justifyContent: 'center',
                                    }"
                                  >
                                    {{
                                      request.job && request.job.code
                                        ? `${request.job.code}`
                                        : ''
                                    }}
                                    -
                                    {{
                                      request.job && request.job.name
                                        ? request.job.name
                                        : request.project &&
                                          request.project.name
                                        ? request.project.name
                                        : ''
                                    }}
                                    {{
                                      request.job && request.job.reference
                                        ? `; ${request.job.reference}`
                                        : ''
                                    }}
                                  </v-list-item>
                                  <v-list-item
                                    v-else
                                    :style="{
                                      color: 'white !important',
                                      fontSize: '0.8rem',
                                      justifyContent: 'flex-end',
                                    }"
                                  >
                                    {{ `${event.requests.length - 3} more` }}
                                  </v-list-item>
                                </div>
                              </template>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-calendar>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Role 33: Only v-calendar -->
    <div v-else-if="isDeliveriesView">
      <v-container class="mt-3" fluid>
        <v-row>
          <v-col>
            <v-card elevation="0" outlined>
              <v-card-title>
                <v-row>
                  <v-col cols="auto"> Scheduled Deliveries </v-col>
                  <v-col cols="1">
                    <v-select
                      v-model="calendarType"
                      :items="calendarTypes"
                      dense
                      hide-details
                      label="Type"
                      item-value="type"
                    >
                      <template #item="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer"
                          :color="item ? item.color : 'white'"
                        >
                          {{ item ? item.name : '' }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <!-- <v-btn
              outlined
              color="primary"
              class="mr-4"
              @click="setToday"
            >
              Today
            </v-btn> -->
                  </v-col>
                  <v-col cols="auto" class="pr-0 pl-0">
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon> mdi-chevron-left </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="pr-0 pl-0">
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="pt-4 pl-0">
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <div class="calendar-container">
                <v-calendar
                  ref="calendar"
                  v-model="focusDay"
                  :weekdays="weekday"
                  :type="calendarType"
                  :event-more="false"
                  :events="events"
                  event-overlap-mode="column"
                  :event-color="getEventColor"
                  :event-height="200"
                  interval-count="0"
                  color="primary"
                  @dragover.prevent
                  @drop="onCalendarDrop"
                >
                  <template #day-label-header="dayLabelHeader">
                    <!-- for week and day view  -->
                    <v-row>
                      <v-col
                        v-if="holidaysObj[dayLabelHeader.date]"
                        style="font-weight: bold"
                      >
                        {{ holidaysObj[dayLabelHeader.date] }}
                      </v-col>
                      <v-col v-else>
                        {{ dayLabelHeader.day }}
                      </v-col>
                    </v-row>
                  </template>
                  <template #day-label="dayLabel">
                    <!-- for month view  -->
                    <v-row>
                      <v-col
                        v-if="holidaysObj[dayLabel.date]"
                        style="font-weight: bold"
                      >
                        {{ holidaysObj[dayLabel.date] }}
                      </v-col>
                      <v-col v-else>
                        {{ dayLabel.day }}
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Slot for customizing events -->
                  <template #event="{ event }">
                    <v-card
                      class="calendar-event pr-1 pl-1"
                      :style="{ background: getEventColor(event) }"
                      tile
                      :data-event-id="event.id"
                      @dragover.prevent
                      @drop="onEventDrop($event, event)"
                      :height="200"
                      @click="openScheduledDeliveryForm(event)"
                    >
                      <v-card-title class="pa-1">
                        <v-row justify="center" align="center">
                          <v-col cols="auto">
                            <v-chip
                              small
                              :color="
                                event && event.status ? event.status.color : ''
                              "
                              text-color="white"
                            >
                              {{
                                event && event.status ? event.status.name : ''
                              }}
                            </v-chip>
                          </v-col>
                          <v-col>
                            <span
                              :style="{ color: 'white', fontSize: '.9rem' }"
                            >
                              {{ event.name }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-list
                          class="pa-0"
                          :style="{ backgroundColor: 'transparent' }"
                        >
                          <template v-for="(request, index) in event.requests">
                            <div v-if="index < 4" :key="index" :style="{}">
                              <v-divider v-if="index !== 0" />
                              <v-list-item
                                v-if="index < 3"
                                :style="{
                                  color: 'white !important',
                                  fontSize: '.9rem',
                                  minHeight: '36px !important',
                                  justifyContent: 'center',
                                }"
                              >
                                {{
                                  request.job && request.job.code
                                    ? `${request.job.code}`
                                    : ''
                                }}
                                -
                                {{
                                  request.job && request.job.name
                                    ? request.job.name
                                    : request.project && request.project.name
                                    ? request.project.name
                                    : ''
                                }}
                                {{
                                  request.job && request.job.reference
                                    ? `; ${request.job.reference}`
                                    : ''
                                }}
                              </v-list-item>
                              <v-list-item
                                v-else
                                :style="{
                                  color: 'white !important',
                                  fontSize: '0.8rem',
                                  justifyContent: 'flex-end',
                                }"
                              >
                                {{ `${event.requests.length - 3} more` }}
                              </v-list-item>
                            </div>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-calendar>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <truck-schedule-request-form
      ref="truckScheduleRequestForm"
      @success="success"
    />
    <truck-schedule-delivery-form
      ref="truckScheduledDeliveryForm"
      @success="success"
    />
    <truck-schedule-manual-schedule-form
      ref="truckScheduleManualScheduleForm"
      @success="success"
    />
    <truck-schedule-bill-of-lading-list-form ref="billOfLadingList" />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import TruckScheduleRequestForm from '@/components/forms/truckSchedules/TruckScheduleRequestForm';
import TruckScheduleDeliveryForm from '@/components/forms/truckSchedules/TruckScheduleDeliveryForm';
import TruckScheduleManualScheduleForm from '@/components/forms/truckSchedules/TruckScheduleManualScheduleForm';
import TruckScheduleBillOfLadingListForm from '@/components/forms/truckSchedules/TruckScheduleBillOfLadingListForm';

export default {
  components: {
    TruckScheduleRequestForm,
    TruckScheduleDeliveryForm,
    TruckScheduleManualScheduleForm,
    TruckScheduleBillOfLadingListForm,
  },
  data: () => ({
    roles: [],
    truckRequest: null,
    truckRequests: [],
    scheduledDeliveries: [],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    rowsPerPageItems: [10, 20, 50, 100, 500, 1000],
    loadingTruckRequests: false,
    topSpaceHeight: 250,
    windowHeight: 1000,
    windowHalfHeight: 1000,
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    sort: {
      order_by_first: 'id',
      direction_sort: true,
    },

    calendarType: 'week',
    calendarTypes: [
      { type: 'month', name: 'MONTH', color: '#388E3C' },
      { type: 'week', name: 'WEEK', color: '#B71C1C' },
      { type: 'day', name: 'DAY', color: '#FBC02D' },
    ],
    weekday: [1, 2, 3, 4, 5, 6],
    focusDay: '',

    total: 0,
    totalRecords: 0,
    selectedTab: 0,
    table: 'truckScheduleRequestTable',
    inventoryClassId: null,
    userRoleIdAdmin: 3,
    userRoleIdDeliveriesManager: 31,
    userRoleIdDeliveriesRequester: 32,
    userRoleIdDeliveriesView: 33,
    roles: [],
    holidays: [],
    holidaysObj: {},

    manualScheduleDate: null,

    isDraggingOverDate: null,
    isAddEventDisabled: true,
    manualSchedule: false,
    truckScheduleStatusInProgress: 2,

    tabs: ['Delivery Requests', 'Scheduled Deliveries'],
    selectedTab: 0,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
    isAdmin() {
      return this.roles.includes(this.userRoleIdAdmin);
    },
    isDeliveriesManager() {
      return this.roles.includes(this.userRoleIdDeliveriesManager);
    },
    isDeliveriesRequester() {
      return this.roles.includes(this.userRoleIdDeliveriesRequester);
    },
    isDeliveriesView() {
      return this.roles.includes(this.userRoleIdDeliveriesView);
    },
    currentDate() {
      return moment(new Date().toLocaleDateString('en-US')).format(
        'YYYY-MM-DD'
      );
    },
    headers() {
      const headers = [];

      if (this.isDeliveriesManager || this.isAdmin) {
        headers.push({
          text: '',
          value: 'draggable',
          width: '1%',
          sortable: false,
        });
      }
      headers.push({
        text: 'Class',
        value: 'class',
        width: '3%',
        align: 'center',
      });
      headers.push({ text: 'ID', value: 'id', width: '5%' });
      headers.push({ text: 'Project', value: 'project.name', width: '10%' });
      headers.push({ text: 'WO#', value: 'job.full_name', width: '15%' });
      headers.push({
        text: 'Truck Types',
        value: 'truck_types',
        width: '10%',
        align: 'center',
      });
      headers.push({
        text: 'Requested Delivery Date',
        value: 'date',
        width: '10%',
      });
      headers.push({
        text: 'Scheduled Delivery Date',
        value: 'slot_id',
        width: '10%',
      });
      headers.push({
        text: 'Requested By',
        value: 'created_by_full_name',
        width: '5%',
      });
      headers.push({
        text: 'Scheduled Deliveries ID',
        value: 'slot_id',
        width: '7%',
      });
      headers.push({
        text: 'Status',
        value: 'status.name',
        width: '10%',
        align: 'center',
      });
      headers.push({
        text: 'Actions',
        value: 'actions',
        align: 'end',
        width: '15%',
        sortable: false,
      });

      return headers;
    },
    events() {
      const events = [];
      this.scheduledDeliveries.forEach((scheduledDelivery) => {
        const scheduledDeliveryEvent = {
          ...scheduledDelivery,
          start: scheduledDelivery.date,
          end: scheduledDelivery.date,
          name: scheduledDelivery.title,
          // event_type: 'time_off',
        };
        events.push(scheduledDeliveryEvent);
      });
      return events;
    },
  },
  watch: {
    pagination() {
      this.getTruckRequests();
    },
    search() {
      this.getTruckRequests();
    },
    inventoryClassId() {},
  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.windowHalfHeight = (window.innerHeight - this.topSpaceHeight) / 2;
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.init();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async init() {
      this.getTruckRequests();
      this.getScheduledDeliveries();
      await this.getHolidays();
      this.setHolidaysObj();
    },

    async getTruckRequests() {
      this.loadingTruckRequests = true;
      this.truckRequests = [];
      this.total = 0;
      const submitModel = {
        filters: {},
      };
      const res = await this.$api.truckScheduleRequest.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        this.truckRequests = [];
        this.total = 0;
        return;
      }
      if (!res || !res.data) {
        this.loadingTruckRequests = false;
        this.$bus.$emit('showError', 'Unable to get truck requests');
        this.truckRequests = [];
        this.total = 0;
        return;
      }
      this.loadingTruckRequests = false;
      this.truckRequests = res.data;
      this.total = res.total;
    },

    async getScheduledDeliveries() {
      this.scheduledDeliveries = [];
      const submitModel = {};
      const res = await this.$api.truckScheduleSlot.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        this.scheduledDeliveries = [];
        return;
      }
      if (!res) {
        this.$bus.$emit('showError', 'Unable to get scheduled deliveries');
        this.scheduledDeliveries = [];
        return;
      }
      this.scheduledDeliveries = res;
    },

    async softDeleteTruckRequest(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this delivery request?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.truckScheduleRequest.delete(id);

      if (!res) {
        this.$bus.$emit('showError', 'Unable to delete delivery request');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getTruckRequests();
      this.getScheduledDeliveries();
    },

    openTruckRequestForm(item, isView = false) {
      if (!item) {
        this.$refs.truckScheduleRequestForm.open();
      } else {
        this.$refs.truckScheduleRequestForm.open(item.id, isView);
      }
    },

    openBillOfLadingList(item) {
      this.$refs.billOfLadingList.open(item);
    },

    openManualScheduleForm(item) {
      this.$refs.truckScheduleManualScheduleForm.open(item, this.events);
    },

    async softDeleteScheduledDelivery(id) {
      const confirm = await this.$refs.confirmForm.open({
        title:
          'Are you sure that you want to delete this scheduled delivery? All associated delivery requests and bill of ladings will be unassigned.',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.truckScheduleSlot.delete(id);

      if (!res) {
        this.$bus.$emit('showError', 'Unable to delete scheduled delivery');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getTruckRequests();
      this.getScheduledDeliveries();
    },

    onDragStart(ev, item, itemId) {
      // ev.dataTransfer.setData("text", ev.target.id);
      ev.dataTransfer.setData('application/json', JSON.stringify(item));

      // Use the row element as the drag image
      this.createDragImage(ev, itemId);

      this.isAddEventDisabled = false;
    },

    createDragImage(ev, itemId) {
      const rowElement = this.$refs[`row-${itemId}`]; // Get the row element
      if (rowElement) {
        const clone = rowElement.cloneNode(true); // Clone the row for a snapshot

        this.copyStyles(rowElement, clone);

        clone.style.position = 'absolute';
        clone.style.top = '-9999px'; // Hide it off-screen
        document.body.appendChild(clone); // Append it to the DOM
        ev.dataTransfer.setDragImage(clone, 0, 0); // Use it as the drag image

        // Clean up the cloned element after dragging
        setTimeout(() => document.body.removeChild(clone), 0);
      }
    },

    copyStyles(source, target) {
      const computedStyle = window.getComputedStyle(source);
      for (const key of computedStyle) {
        target.style[key] = computedStyle.getPropertyValue(key);
      }

      // Recursively copy styles for child elements
      for (let i = 0; i < source.children.length; i++) {
        this.copyStyles(source.children[i], target.children[i]);
      }
    },

    openScheduledDeliveryForm(item) {
      this.$refs.truckScheduledDeliveryForm.open(item.id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.getTruckRequests();
      this.getScheduledDeliveries();
    },

    onDragEnd(event) {
      // console.log('onDragEnd');
      this.isAddEventDisabled = true;
    },

    async onEventDrop(ev, event) {
      // console.log('Dropped on $event: ', ev);
      // console.log('Dropped on evenet: ', event);

      //set this truck request's slot_id to equal the event.id
      const truckRequest = JSON.parse(
        ev.dataTransfer.getData('application/json')
      );
      // console.log('Dropped on event truck request: ', truckRequest);
      if (!this.isSameClass(truckRequest, event)) {
        const scheduledDeliveryClassName = event.requests[0].class.name;
        this.$bus.$emit(
          'showError',
          `Truck Request must be same class as Scheduled Delivery (${scheduledDeliveryClassName})`
        );
        return;
      }
      if (this.isExistingJobInScheduledDelivery(truckRequest, event)) {
        this.$bus.$emit(
          'showError',
          `A Truck Request of this Job already exists in this Scheduled Delivery`
        );
        return;
      }
      truckRequest.slot_id = event.id;
      truckRequest.status_id = this.truckScheduleStatusInProgress;

      const res = await this.$api.truckScheduleRequest.update(truckRequest);

      this.isAddEventDisabled = true;
      if (!res) {
        this.$bus.$emit(
          'showError',
          `Error adding Delivery Request to Scheduled Delivery ${event.name}`
        );
        return;
      }

      this.$bus.$emit('showSuccess');
      this.getScheduledDeliveries();
      this.getTruckRequests();
    },

    isSameClass(truckRequest, event) {
      // console.log('isSameClass truckRequest: ', truckRequest);
      // console.log('isSameClass event: ', event);
      if (event.requests == null || event.requests.length <= 0) return true;
      return truckRequest.inventory_class_id === event.requests[0].class.id;
    },

    isExistingJobInScheduledDelivery(truckRequest, event) {
      // console.log('isExistingJob truckRequest: ', truckRequest);
      // console.log('isExistingJob event: ', event);
      const jobId = truckRequest.job_id;
      const requests = Array.isArray(event.requests) ? event.requests : [];
      return requests.some((x) => x.job_id === jobId);
    },

    isExistingJobAcrossScheduledDeliveries(truckRequest, date) {
      // console.log('truckRequest: ', truckRequest);
      // console.log('date: ', date);
      const jobId = truckRequest.job_id;
      // console.log('jobId: ', jobId);
      return this.events
        .filter((event) => event.date === date)
        .some((event) =>
          event.requests.some((request) => request.job_id === jobId)
        );
    },

    isExistingInventoryClassAcrossScheduledDeliveries(truckRequest, date) {
      // console.log('truckRequest: ', truckRequest);
      // console.log('date: ', date);
      const inventoryClassId = truckRequest.inventory_class_id;
      // console.log('inventoryClassId: ', inventoryClassId);
      return this.events
        .filter((event) => event.date === date)
        .some((event) =>
          event.requests.some(
            (request) => request.inventory_class_id === inventoryClassId
          )
        );
    },

    scheduledDeliveryHasBillOfLadings(event) {
      return event.requests.some(
        (request) => request.bill_of_ladings.length > 0
      );
    },

    truckRequestHasBillOfLadings(truckRequest) {
      return truckRequest.bill_of_ladings.length > 0;
    },

    isTruckRequestScheduled(truckRequest) {
      return truckRequest.slot_id !== null;
    },

    onCalendarDrop(event) {
      console.log('Dropped on calendar');
    },

    onDragOver(event, date) {
      event.preventDefault();
      this.isDraggingOverDate = date;
    },

    onDragLeave() {
      this.isDraggingOverDate = null;
    },

    async onAddEventDrop(ev, dayLabelHeader) {
      // console.log('onAddEventDrop ev: ', ev);
      const truckRequest = JSON.parse(
        ev.dataTransfer.getData('application/json')
      );
      // console.log('draggedItem: ', truckRequest);
      // console.log('dayLabelHeader: ', dayLabelHeader);
      if (
        this.isExistingJobAcrossScheduledDeliveries(
          truckRequest,
          dayLabelHeader.date
        )
      ) {
        this.$bus.$emit(
          'showError',
          `A Scheduled Delivery already exists with this Job for this date`
        );
        return;
      }
      if (
        this.isExistingInventoryClassAcrossScheduledDeliveries(
          truckRequest,
          dayLabelHeader.date
        )
      ) {
        this.$bus.$emit(
          'showError',
          `A Scheduled Delivery already exists with this Inventory Class for this date`
        );
        return;
      }
      const newTruckSchedule = {
        id: 0,
        // truck_schedule_request_id: truckRequest.id,
        requests: [truckRequest],
        date: dayLabelHeader.date,
      };
      //submit a new truck_schedule_slot
      const res = await this.$api.truckScheduleSlot.store(newTruckSchedule);

      if (!res) {
        this.$bus.$emit('showError', 'Unable to create new Scheduled Delivery');
        return false;
      }
      //on success, show success message and refresh the calendar
      this.isDraggingOverDate = null;
      this.$bus.$emit('showSuccess');
      this.getScheduledDeliveries();
      this.getTruckRequests();
    },

    submitManualSchedule() {},

    belongsToRequester(item) {
      return item.created_by_user.id === this.user.id;
    },

    // getChipColor(baseColor) {
    //   const lighterColor = this.lightenColor(baseColor, 20); // 20% lighter
    //   return `linear-gradient(45deg, ${baseColor}, ${lighterColor})`;
    // },
    getEventColor(event) {
      // const baseColor =
      //   event.requests && event.requests[0] && event.requests[0].class
      //     ? event.requests[0].class.color
      //     : '#757575';
      // const lighterColor = this.lightenColor(baseColor, 20); // 20% lighter
      // return `linear-gradient(45deg, ${baseColor}, ${lighterColor})`;
      return event.requests && event.requests[0] && event.requests[0].class
        ? event.requests[0].class.color
        : '#757575';
    },
    // lightenColor(color, percent) {
    //   const num = parseInt(color.slice(1), 16),
    //     amt = Math.round(2.55 * percent),
    //     R = (num >> 16) + amt,
    //     G = ((num >> 8) & 0x00ff) + amt,
    //     B = (num & 0x0000ff) + amt;
    //   return `#${(
    //     0x1000000 +
    //     (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    //     (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    //     (B < 255 ? (B < 1 ? 0 : B) : 255)
    //   )
    //     .toString(16)
    //     .slice(1)
    //     .toUpperCase()}`;
    // },

    onResize() {
      this.windowHalfHeight = (window.innerHeight - this.topSpaceHeight) / 2;
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updatePagination(pagination) {
      if (
        pagination.page !== this.pagination.page ||
        pagination.itemsPerPage !== this.pagination.itemsPerPage
      ) {
        this.pagination = pagination;
        this.storeState('pagination', pagination);
        this.itemsPerPage = this.pagination.itemsPerPage;
      }
    },

    viewDay({ date }) {
      this.focusDay = date;
      this.type = 'day';
    },

    // setToday() {
    //   this.type = 'day';
    //   this.focusDay = '';
    // },

    prev() {
      // console.log('prev');
      // console.log('this.$refs.calendar: ', this.$refs.calendar);
      this.$refs.calendar.prev();
    },

    next() {
      // console.log('next');
      // console.log('this.$refs.calendar: ', this.$refs.calendar);
      this.$refs.calendar.next();
    },

    async getHolidays() {
      this.loading = true;
      const submitModel = {
        filters: {},
      };
      this.holidays = await this.$api.timeOffHoliday.read(submitModel);
      this.loading = false;
    },

    setHolidaysObj() {
      if (this.holidays.length > 0) {
        this.holidays.forEach((holiday) => {
          this.holidaysObj[holiday.date] = holiday.name;
        });
      } else {
        this.holidaysObj = {};
        this.holidays = [];
      }
    },

    dropEventColorClass(date) {
      // if date is greater than or equal to today, return the class 'drop-event' else return nothing
      if (moment(date).isSameOrAfter(this.currentDate)) {
        return 'drop-event-btn';
      }
    },

    draggingOverClass(date) {
      if (this.isDraggingOverDate === date) {
        return 'dragging-over-btn';
      }
      return '';
    },

    getEventHeight(event) {
      let height = 0;
      let firstRow = 57;
      height += firstRow;
      if (event.requests.length === 1) {
        height += 36;
      } else if (event.requests.length === 2) {
        height += 72;
      } else if (event.requests.length === 3) {
        height += 108;
      } else if (event.requests.length >= 4) {
        height += 156;
      }
      return height;
    },

    lightenColor(hex) {
      // Remove the '#' if it exists
      let normalizedHex = hex.replace('#', '');

      // If the hex is in shorthand form (e.g., "abc"), convert it to full form ("aabbcc")
      if (normalizedHex.length === 3) {
        normalizedHex = normalizedHex
          .split('')
          .map(char => char + char)
          .join('');
      }

      // Ensure we have exactly 6 characters now
      if (normalizedHex.length !== 6) {
        throw new Error("Invalid hex color format. Please use '#RRGGBB' or '#RGB'.");
      }

      // Convert the R, G, and B components from hex to decimal
      const r = parseInt(normalizedHex.slice(0, 2), 16);
      const g = parseInt(normalizedHex.slice(2, 4), 16);
      const b = parseInt(normalizedHex.slice(4, 6), 16);

      // Return the rgba string with an alpha of 0.12
      return `rgba(${r}, ${g}, ${b}, 0.12)`;
    },
  },
};
</script>
<style scoped>
/* ::v-deep .theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
  display: none !important;
}
::v-deep .theme--light.v-calendar-daily .v-calendar-daily__intervals-head {
  display: none !important;
} */
/* ::v-deep .v-calendar-daily__body{
  display: none;
} */
::v-deep .v-calendar-daily__intervals-head {
  display: none !important;
}
::v-deep .v-calendar-daily__scroll-area {
  overflow-y: hidden !important;
}
/* first column in calendar monthly view */
/* /deep/.v-calendar-weekly__week .v-calendar-weekly__day:first-child {
  background-color: #ffecef;
} */
/* last column in calendar monthly view */
/deep/.v-calendar-weekly__week .v-calendar-weekly__day:last-child {
  background-color: #ffecef;
}
/* first column in calendar monthly view */
/* /deep/.v-calendar-weekly__head .v-calendar-weekly__head-weekday:first-child {
  background-color: #ffecef;
} */
/* last column in calendar monthly view */
/deep/.v-calendar-weekly__head .v-calendar-weekly__head-weekday:last-child {
  background-color: #ffecef;
}
/deep/.v-calendar-daily__head .v-calendar-daily_head-day:nth-child(7) {
  background-color: #ffecef;
}
/* /deep/.v-calendar-daily__head
  > .v-calendar-daily_head-day:nth-child(2):nth-last-child(7) {
  background-color: #ffecef;
}
/deep/.v-calendar-daily__day-container
  > .v-calendar-daily__day:nth-child(2):nth-last-child(7) {
  background-color: #ffecef;
} */
/deep/.v-calendar-daily__day:nth-child(7) {
  background-color: #ffecef;
}
/deep/.v-calendar-daily_head-day-label {
}
/deep/.calendar-add-event {
  color: var(--v-primary-base);
  border: 2px solid var(--v-primary-base);
  border-radius: 5px;
  margin: 1rem;
}
/deep/.v-calendar-daily_head-day-label {
  cursor: default !important;
}
/deep/#drop-event-btn.drop-event-btn {
  color: var(--v-primary-base) !important;
}
/deep/#drop-event-btn.dragging-over-btn {
  color: white !important;
  background-color: var(--v-primary-base) !important;
}
/deep/.v-event {
  min-height: 50px !important;
  height: auto !important;
  width: 98% !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}
/* .calendar-container {
  height: 400px;
  overflow-y: auto;
} */

/* .v-calendar-weekly {
  display: table;
  table-layout: fixed;
}
.v-calendar-weekly__week {
  height: auto;
  display: table-row;
}
.v-calendar-weekly__day {
  display: table-cell;
  width: calc(100% / 7);
} */
</style>